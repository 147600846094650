import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { countryEditAction, countryUpdateAction } from '../../actions/countryActions'
import http from '../../api'
import { StyledDropZone } from 'react-drop-zone'
import 'react-drop-zone/dist/styles.css'

const CountryEdit = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [flag, setFlag] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [flagPath, setFlagPath] = useState("");
  const [flagFile, setFlagFile] = useState(null);
  const [error, setError] = useState(null);
  const countryEdit = useSelector((state) => state.countryEdit);
  const countryUpdate = useSelector((state) => state.countryUpdate);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    context.loading = true;
    setError(null);
    dispatch(countryEditAction(navigate, id));
  };

  useEffect(() => {
    if (countryEdit.result) {
      context.loading = false;
      setTitle(countryEdit.result["title"]);
      setCode(countryEdit.result["code"]);
      setFlag(countryEdit.result["flag"]);
      setFlagPath(countryEdit.result["flag_path"]);
      setIsActive(countryEdit.result['is_active']);
    }
  }, [countryEdit])

  const updateCountry = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('id', id);
    data.append('title', title);
    data.append('code', code);
    data.append('flag', flag);
    if (flagFile)
      data.append('flagFile', flagFile);
    if (isActive)
      data.append('is_active', true)
    else
      data.append('is_active', false)
    dispatch(countryUpdateAction(navigate, data));
  };

  useEffect(() => {
    if (countryUpdate.result) {
      context.loading = false;
      if (countryUpdate.result.status !== 200)
        setError(countryUpdate.result.data.message);
    }
  }, [countryUpdate])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else if (code === "") setError("Enter Code is required!");
    else if (flag === "") setError("Enter Flag is required!");
    else {
      updateCountry();
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Edit Country: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/countries">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Country Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country Code</Form.Label>
                <Form.Control
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter Code..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country Flag</Form.Label>
                <Form.Control
                  type="text"
                  value={flag}
                  onChange={(e) => setFlag(e.target.value)}
                  placeholder="Enter Flag..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <td>Flag Image: <Image src={http.assetUrl + flagPath} /></td>

              <StyledDropZone
                onDrop={(file, text) => setFlagFile(file)}
              />

              {flagFile ? (
                <td className="text-success">Selected File: {flagFile.name}</td>
              ) : (
                <td className="text-danger">No file selected yet</td>
              )}

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="warning"
                type="submit"
              >
                Update
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CountryEdit;
