import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const categories = async (navigate) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoriesApi(), {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const categoryCreate = async (navigate) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoriesApi() + "/create", {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const categoryStore = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getCategoriesApi() + "/store", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/categories");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const categoryEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoriesApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const categoryUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getCategoriesApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/categories");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const categoryDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoriesApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/categories");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}