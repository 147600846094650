import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const getTerm = async (navigate) => {
    try {
        const result = await http.axios.get(UrlServices.getTermApi() + "/get", {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const setTerm = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getTermApi() + "/set", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

