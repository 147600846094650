import { CategoryHeaderCreate, CategoryHeaderDelete, CategoryHeaderEdit, CategoryHeaders, CategoryHeaderStore, CategoryHeaderUpdate } from "./actionTypes";
import { categoryHeaderCreate, categoryHeaderDelete, categoryHeaderEdit, categoryHeaders, categoryHeaderStore, categoryHeaderUpdate } from '../api/categoryHeaders';

export const categoryHeadersAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryHeaders, payload: null });
        categoryHeaders(navigate).then(async (result) => {
            await dispatch({ type: CategoryHeaders, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryHeaders, payload: error });
        });
    };
};

export const categoryHeaderCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryHeaderCreate, payload: null });
        categoryHeaderCreate(navigate).then(async (result) => {
            await dispatch({ type: CategoryHeaderCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryHeaderCreate, payload: error });
        });
    };
};

export const categoryHeaderStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryHeaderStore, payload: null });
        categoryHeaderStore(navigate, data).then(async (result) => {
            await dispatch({ type: CategoryHeaderStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryHeaderStore, payload: error });
        });
    };
};

export const categoryHeaderEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryHeaderEdit, payload: null });
        categoryHeaderEdit(navigate, id).then(async (result) => {
            await dispatch({ type: CategoryHeaderEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryHeaderEdit, payload: error });
        });
    };
};

export const categoryHeaderUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryHeaderUpdate, payload: null });
        categoryHeaderUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: CategoryHeaderUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryHeaderUpdate, payload: error });
        });
    };
};

export const categoryHeaderDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryHeaderDelete, payload: null });
        categoryHeaderDelete(navigate, id).then(async (result) => {
            await dispatch({ type: CategoryHeaderDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryHeaderDelete, payload: error });
        });
    };
};