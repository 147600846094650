import axios from "axios";
import AuthHeader from "./AuthHeader";

const API_URL = AuthHeader.getApiUrl() + "auth/";

class AuthService {

    login(email, password) {
        return axios
            .post(API_URL + "login", {
                email,
                password,
            })
            .then((response) => {
                if (response.data.access_token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            })
            .catch(() => {
                return null;
            });
    }

    logout(navigate) {
        localStorage.removeItem("user");
        axios.post(API_URL + "logout", {
            headers: AuthHeader.getHeader(),
        });
        navigate('/');
        window.location.reload();
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem("user"));
    }
}

export default new AuthService();