import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { currencyUnitEditAction, currencyUnitDeleteAction } from '../../actions/currencyUnitActions'

const CurrencyUnitDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [symbol, setSymbol] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const currencyUnitEdit = useSelector((state) => state.currencyUnitEdit);
  const currencyUnitDelete = useSelector((state) => state.currencyUnitDelete);

  useEffect(() => {
    getUnit();
  }, []);

  const getUnit = () => {
    context.loading = true;
    setError(null);
    dispatch(currencyUnitEditAction(navigate, id));
  };

  useEffect(() => {
    if (currencyUnitEdit.result) {
      context.loading = false;
      setTitle(currencyUnitEdit.result["title"]);
      setSymbol(currencyUnitEdit.result["symbol"]);
      if (currencyUnitEdit.result["is_active"])
        setIsActive(true);
      else
        setIsActive(false);
    }
  }, [currencyUnitEdit])

  const deleteUnit = () => {
    context.loading = true;
    setError(null);
    dispatch(currencyUnitDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (currencyUnitDelete.result) {
      context.loading = false;
      if (currencyUnitDelete.result.status !== 200)
        setError(currencyUnitDelete.result.data.message);
    }
  }, [currencyUnitDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else if (symbol === "") setError("Enter Symbol is required!");
    else {
      deleteUnit();
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Currency Unit: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-danger">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/currencyUnits">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Currency Title</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Currency Symbol</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                  placeholder="Enter Symbol (USD)..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  disabled
                  className="form-control"
                  type="checkbox"
                  onChange={handleChangeIsActive}
                  label="Is Active"
                  checked={isActive}
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CurrencyUnitDelete;
