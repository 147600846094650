import { GetTerm, SetTerm } from "../actions/actionTypes";

export const getTermReducer = (state = {}, action) => {
    switch (action.type) {
        case GetTerm:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const setTermReducer = (state = {}, action) => {
    switch (action.type) {
        case SetTerm:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};
