import { UserChangePassword, UserCreate, UserDelete, UserEdit, Users, UserStore, UserUpdate } from "../actions/actionTypes";

export const usersReducer = (state = {}, action) => {
    switch (action.type) {
        case Users:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const userCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case UserCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const userStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case UserStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const userEditReducer = (state = {}, action) => {
    switch (action.type) {
        case UserEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const userUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case UserUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case UserDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const userChangePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case UserChangePassword:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};