import { ProvinceDelete, ProvinceEdit, Provinces, ProvinceStore, ProvinceUpdate } from "../actions/actionTypes";

export const provincesReducer = (state = {}, action) => {
    switch (action.type) {
        case Provinces:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const provinceStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case ProvinceStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const provinceEditReducer = (state = {}, action) => {
    switch (action.type) {
        case ProvinceEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const provinceUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case ProvinceUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const provinceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ProvinceDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};