import authHeader from "./AuthHeader";

class UrlServices {
    getDashboardApi() {
        return authHeader.getApiUrl() + "dashboard";
    }

    getGetReportApi() {
        return authHeader.getApiUrl() + "getReport";
    }

    getRolesApi() {
        return authHeader.getApiUrl() + "roles";
    }

    getUsersApi() {
        return authHeader.getApiUrl() + "users";
    }

    getTermApi() {
        return authHeader.getApiUrl() + "term";
    }

    getLanguagesApi() {
        return authHeader.getApiUrl() + "languages";
    }

    getChangePasswordApi() {
        return authHeader.getApiUrl() + "changePassword";
    }

    getCountriesApi() {
        return authHeader.getApiUrl() + "countries";
    }

    getProvincesApi() {
        return authHeader.getApiUrl() + "provinces";
    }

    getCitiesApi() {
        return authHeader.getApiUrl() + "cities";
    }

    getPlansApi() {
        return authHeader.getApiUrl() + "plans";
    }

    getPlanOptionsApi() {
        return authHeader.getApiUrl() + "planOptions";
    }

    getPostsApi() {
        return authHeader.getApiUrl() + "posts";
    }

    getAdsApi() {
        return authHeader.getApiUrl() + "ads";
    }

    getVipAdsApi() {
        return authHeader.getApiUrl() + "vipAds";
    }

    getAppUsersApi() {
        return authHeader.getApiUrl() + "appUsers";
    }

    getEventsApi() {
        return authHeader.getApiUrl() + "events";
    }

    getCategoriesApi() {
        return authHeader.getApiUrl() + "categories";
    }

    getCategoryHeadersApi() {
        return authHeader.getApiUrl() + "categoryHeaders";
    }

    getCategoryFiltersApi() {
        return authHeader.getApiUrl() + "categoryFilters";
    }

    getDashboardsApi() {
        return authHeader.getApiUrl() + "dashboard";
    }

    getReportedUsersApi() {
        return authHeader.getApiUrl() + "reportedUsers";
    }

    getReportedAdsApi() {
        return authHeader.getApiUrl() + "reportedAds";
    }

    getReportedPostsApi() {
        return authHeader.getApiUrl() + "reportedPosts";
    }

    getCurrencyUnitsApi() {
        return authHeader.getApiUrl() + "currencyUnits";
    }

    getBlockUsersApi() {
        return authHeader.getApiUrl() + "blockedUsers";
    }

}

export default new UrlServices();