import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { vipAdEditAction, vipAdUpdateAction } from '../../actions/vipAdActions'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { format } from "date-fns";

const VipAdEdit = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [expiredAt, setExpiredAt] = useState('');
  const [error, setError] = useState(null);
  const vipAdEdit = useSelector((state) => state.vipAdEdit);
  const vipAdUpdate = useSelector((state) => state.vipAdUpdate);

  useEffect(() => {
    getAd();
  }, []);

  const getAd = () => {
    context.loading = true;
    setError(null);
    dispatch(vipAdEditAction(navigate, id));
  };

  useEffect(() => {
    if (vipAdEdit.result) {

      setExpiredAt(new Date(vipAdEdit.result.vipAd.expired_at));
      setIsActive(vipAdEdit.result.vipAd.is_active);

      if (vipAdEdit.result.vipAd.ad) {
        setFullName(vipAdEdit.result.vipAd.ad.fullName);
        if (vipAdEdit.result.vipAd.ad.userName)
          setUserName(vipAdEdit.result.vipAd.ad.userName);
        else
          setUserName('');
        if (vipAdEdit.result.vipAd.ad.title)
          setTitle(vipAdEdit.result.vipAd.ad.title);
        else
          setTitle('');
        if (vipAdEdit.result.vipAd.ad.description)
          setDescription(vipAdEdit.result.vipAd.ad.description);
        else
          setDescription('');
      }

      context.loading = false;
    }
  }, [vipAdEdit])

  const UpdateAd = () => {
    context.loading = true;
    setError(null);
    var data = new FormData();
    data.append('id', id)
    if (isActive)
      data.append('is_active', true)
    else
      data.append('is_active', false)
    data.append('expired_at', format(expiredAt, "yyyy-MM-dd HH:mm:ss"));
    dispatch(vipAdUpdateAction(navigate, data));
  };

  useEffect(() => {
    if (vipAdUpdate.result) {
      context.loading = false;
      if (vipAdUpdate.result.status !== 200)
        setError(vipAdUpdate.result.data.message);
    }
  }, [vipAdUpdate])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (expiredAt === "") setError("Enter Expired At is required!");
    else {
      UpdateAd();
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Edit Vip Ad </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{title}</h4>
            &nbsp;
            &nbsp;
            <Link className="btn btn-info" to="/vipAds">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-2 col-lg-12">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-12">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="m-3 col-lg-4">
                  <Form.Check
                    style={{ border: 'none' }}
                    className="form-control"
                    type="checkbox"
                    onClick={handleChangeIsActive}
                    checked={isActive}
                    label="Is Active"
                  />
                </Form.Group>

                <Form.Label>Expired At</Form.Label>
                &nbsp;
                <DateTimePicker
                  className='form-control col-lg-8'
                  minDate={new Date()}
                  maxDetail="second"
                  format="y-MM-dd hh:mm:ss a"
                  onChange={setExpiredAt}
                  value={expiredAt}
                />
              </div>


              <Button
                onClick={handleSubmit}
                size="lg"
                variant="warning"
                type="submit"
              >
                Update
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default VipAdEdit;
