import { PostDetail, PostDelete, PostEdit, Posts, PostDeleteMedia, PostUpdate } from "./actionTypes";
import { postDetail, postDelete, postEdit, posts, postDeleteMedia, postUpdate } from '../api/posts';

export const postsAction = (navigate, page, search, status) => {
    return async (dispatch) => {
        await dispatch({ type: Posts, payload: null });
        posts(navigate, page, search, status).then(async (result) => {
            await dispatch({ type: Posts, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Posts, payload: error });
        });
    };
};

export const postDetailAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: PostDetail, payload: null });
        postDetail(navigate, id).then(async (result) => {
            await dispatch({ type: PostDetail, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PostDetail, payload: error });
        });
    };
};

export const postDeleteMediaAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: PostDeleteMedia, payload: null });
        postDeleteMedia(navigate, data).then(async (result) => {
            await dispatch({ type: PostDeleteMedia, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PostDeleteMedia, payload: error });
        });
    };
};

export const postEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: PostEdit, payload: null });
        postEdit(navigate, id).then(async (result) => {
            await dispatch({ type: PostEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PostEdit, payload: error });
        });
    };
};

export const postUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: PostUpdate, payload: null });
        postUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: PostUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PostUpdate, payload: error });
        });
    };
};

export const postDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: PostDelete, payload: null });
        postDelete(navigate, id).then(async (result) => {
            await dispatch({ type: PostDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PostDelete, payload: error });
        });
    };
};