import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { cityStoreAction } from '../../actions/cityActions'

const CityCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cId, pId } = useParams();
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const cityStore = useSelector((state) => state.cityStore);

  const addCity = () => {
    context.loading = true;
    setError(null);
    const data = {
      province_id: pId,
      title: title,
    };
    dispatch(cityStoreAction(navigate, data, pId,cId));
  };

  useEffect(() => {
    if (cityStore.result) {
      context.loading = false;
      if (cityStore.result.status !== 200)
        setError(cityStore.result.data.message);
    }
  }, [cityStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else {
      addCity();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New City</h4> &nbsp;
            <Link className="btn btn-info" to={`/cities/${pId}/${cId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CityCreate;
