import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const categoryFilters = async (navigate, categoryId) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoryFiltersApi() + "/get/" + categoryId, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const categoryFilterCreate = async (navigate) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoryFiltersApi() + "/create", {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const categoryFilterStore = async (navigate, data, cId) => {
    try {
        const result = await http.axios.post(UrlServices.getCategoryFiltersApi() + "/store", data, {
            headers: AuthHeader.getHeader(),
        });

        if (checkToken(result, navigate))
            navigate("/categoryFilters/" + cId);
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const categoryFilterEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoryFiltersApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const categoryFilterUpdate = async (navigate, data, cId) => {
    try {
        const result = await http.axios.post(UrlServices.getCategoryFiltersApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/categoryFilters/" + cId);
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const categoryFilterDelete = async (navigate, id, cId) => {
    try {
        const result = await http.axios.get(UrlServices.getCategoryFiltersApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/categoryFilters/" + cId);
    } catch (error) {
        if (error.response)
            return error.response;
    }
}