import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { countryStoreAction } from '../../actions/countryActions'
import { StyledDropZone } from 'react-drop-zone'
import 'react-drop-zone/dist/styles.css'

const CountryCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [flag, setFlag] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [flagFile, setFlagFile] = useState(null);
  const [error, setError] = useState(null);
  const countryStore = useSelector((state) => state.countryStore);

  const addCountry = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('title', title);
    data.append('code', code);
    data.append('flag', flag);
    data.append('flagFile', flagFile);
    if (isActive)
      data.append('is_active', true)
    else
      data.append('is_active', false)
    dispatch(countryStoreAction(navigate, data));
  };

  useEffect(() => {
    if (countryStore.result) {
      context.loading = false;
      if (countryStore.result.status !== 200)
        setError(countryStore.result.data.message);
    }
  }, [countryStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else if (code === "") setError("Enter Code is required!");
    else if (flag === "") setError("Enter Flag is required!");
    else if (!flagFile) setError("Select Flag Image is required!");
    else {
      addCountry();
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Country</h4> &nbsp;
            <Link className="btn btn-info" to="/countries">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter Code (1) ..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Flag</Form.Label>
                <Form.Control
                  type="text"
                  value={flag}
                  onChange={(e) => setFlag(e.target.value)}
                  placeholder="Enter Flag (ca) ..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <StyledDropZone
                onDrop={(file, text) => setFlagFile(file)}
              />

              {flagFile ? (
                <td className="text-success">Selected File: {flagFile.name}</td>
              ) : (
                <td className="text-danger">No file selected yet</td>
              )}

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CountryCreate;
