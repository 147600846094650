import { UserChangePassword, UserCreate, UserDelete, UserEdit, Users, UserStore, UserUpdate } from "./actionTypes";
import { changePassword, userCreate, userDelete, userEdit, users, userStore, userUpdate } from '../api/users';

export const usersAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: Users, payload: null });
        users(navigate, page).then(async (result) => {
            await dispatch({ type: Users, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Users, payload: error });
        });
    };
};

export const userCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: UserCreate, payload: null });
        userCreate(navigate).then(async (result) => {
            await dispatch({ type: UserCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: UserCreate, payload: error });
        });
    };
};

export const userStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: UserStore, payload: null });
        userStore(navigate, data).then(async (result) => {
            await dispatch({ type: UserStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: UserStore, payload: error });
        });
    };
};

export const userEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: UserEdit, payload: null });
        userEdit(navigate, id).then(async (result) => {
            await dispatch({ type: UserEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: UserEdit, payload: error });
        });
    };
};

export const userUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: UserUpdate, payload: null });
        userUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: UserUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: UserUpdate, payload: error });
        });
    };
};

export const userDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: UserDelete, payload: null });
        userDelete(navigate, id).then(async (result) => {
            await dispatch({ type: UserDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: UserDelete, payload: error });
        });
    };
};

export const userChangePasswordAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: UserChangePassword, payload: null });
        changePassword(navigate, data).then(async (result) => {
            await dispatch({ type: UserChangePassword, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: UserChangePassword, payload: error });
        });
    };
};