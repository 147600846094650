import React, { useContext } from "react";
import AuthService from "../Services/AuthService";
import { Navigate } from "react-router-dom";
import SimpleContext from "../Contexts/SimpleContext";
import PermissionService from "../Services/PermissionService";

function PrivateRoute({ children, permissionName, ...rest }) {
    const context = useContext(SimpleContext);

    const isLoggedIn = checkIsLogin();
    const hasPerm = checkHasPermission();

    function checkHasPermission() {
        const h = true;
        if (permissionName !== "no") {
            return PermissionService.hasPermission(
                permissionName,
                context.permissions
            );
        }
        return h;
    }

    function checkIsLogin() {
        let u = AuthService.getCurrentUser();
        if (u) {
            context.status = true;
            context.user = u["user"];
            context.roles = u["roles"];
            context.permissions = u["permissions"];
            context.token = u["access_token"];
        } else {
            context.status = false;
            context.user = null;
            context.roles = null;
            context.permissions = null;
            context.token = null;
        }
        return u;
    }

    return isLoggedIn ? (
        hasPerm ? (
            children
        ) : (
            <Navigate to="/PermissionDenied" />
        )
    ) : (
        <Navigate to="/login" />
    );
}

export default PrivateRoute;
