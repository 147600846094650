import { Provinces, ProvinceDelete, ProvinceEdit, ProvinceStore, ProvinceUpdate } from "./actionTypes";
import { getProvinces, provinceDelete, provinceEdit, provinceStore, provinceUpdate } from '../api/provinces';

export const provincesAction = (navigate, page, data) => {
    return async (dispatch) => {
        await dispatch({ type: Provinces, payload: null });
        getProvinces(navigate, page, data).then(async (result) => {
            await dispatch({ type: Provinces, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Provinces, payload: error });
        });
    };
};

export const provinceStoreAction = (navigate, data, cId) => {
    return async (dispatch) => {
        await dispatch({ type: ProvinceStore, payload: null });
        provinceStore(navigate, data, cId).then(async (result) => {
            await dispatch({ type: ProvinceStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ProvinceStore, payload: error });
        });
    };
};

export const provinceEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: ProvinceEdit, payload: null });
        provinceEdit(navigate, id).then(async (result) => {
            await dispatch({ type: ProvinceEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ProvinceEdit, payload: error });
        });
    };
};

export const provinceUpdateAction = (navigate, data, cId) => {
    return async (dispatch) => {
        await dispatch({ type: ProvinceUpdate, payload: null });
        provinceUpdate(navigate, data, cId).then(async (result) => {
            await dispatch({ type: ProvinceUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ProvinceUpdate, payload: error });
        });
    };
};

export const provinceDeleteAction = (navigate, id, cId) => {
    return async (dispatch) => {
        await dispatch({ type: ProvinceDelete, payload: null });
        provinceDelete(navigate, id, cId).then(async (result) => {
            await dispatch({ type: ProvinceDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ProvinceDelete, payload: error });
        });
    };
};