import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { countryEditAction, countryDeleteAction } from '../../actions/countryActions'
import http from '../../api'

const CountryDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [flag, setFlag] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [flagPath, setFlagPath] = useState("");
  const [error, setError] = useState(null);
  const countryEdit = useSelector((state) => state.countryEdit);
  const countryDelete = useSelector((state) => state.countryDelete);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    context.loading = true;
    setError(null);
    dispatch(countryEditAction(navigate, id));
  };

  useEffect(() => {
    if (countryEdit.result) {
      context.loading = false;
      setTitle(countryEdit.result["title"]);
      setCode(countryEdit.result["code"]);
      setFlag(countryEdit.result["flag"]);
      setFlagPath(countryEdit.result["flag_path"]);
      setIsActive(countryEdit.result['is_active']);
    }
  }, [countryEdit])

  const deleteCountry = () => {
    context.loading = true;
    setError(null);
    dispatch(countryDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (countryDelete.result) {
      context.loading = false;
      if (countryDelete.result.status !== 200)
        setError(countryDelete.result.data.message);
    }
  }, [countryDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteCountry();
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Country: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-danger">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/countries">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Country Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  disabled
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country Code</Form.Label>
                <Form.Control
                  type="text"
                  value={code}
                  disabled
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter Code..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country Flag</Form.Label>
                <Form.Control
                  type="text"
                  value={flag}
                  disabled
                  onChange={(e) => setFlag(e.target.value)}
                  placeholder="Enter Flag..."
                />
              </Form.Group>

              <td>Flag Image: <Image src={http.assetUrl + flagPath} /></td>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  disabled
                  label="Is Active"
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CountryDelete;
