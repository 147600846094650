import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { provinceStoreAction } from '../../actions/provinceActions'

const ProvinceCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cId } = useParams();
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const provinceStore = useSelector((state) => state.provinceStore);

  const addProvince = () => {
    context.loading = true;
    setError(null);
    const data = {
      country_id: cId,
      title: title,
    };
    dispatch(provinceStoreAction(navigate, data, cId));
  };

  useEffect(() => {
    if (provinceStore.result) {
      context.loading = false;
      if (provinceStore.result.status !== 200)
        setError(provinceStore.result.data.message);
    }
  }, [provinceStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else {
      addProvince();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Province</h4> &nbsp;
            <Link className="btn btn-info" to={`/provinces/${cId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default ProvinceCreate;
