import { EventDelete, EventEdit, Events, EventStore, EventUpdate } from "../actions/actionTypes";

export const eventsReducer = (state = {}, action) => {
    switch (action.type) {
        case Events:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const eventStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case EventStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const eventEditReducer = (state = {}, action) => {
    switch (action.type) {
        case EventEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const eventUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case EventUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const eventDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EventDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};