import { PlanOptionDelete, PlanOptionCreate, PlanOptionEdit, PlanOptions, PlanOptionStore, PlanOptionUpdate } from "../actions/actionTypes";

export const planOptionsReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanOptions:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planOptionCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanOptionCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planOptionStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanOptionStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planOptionEditReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanOptionEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planOptionUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanOptionUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planOptionDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanOptionDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};