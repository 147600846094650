import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Login";
import NotFound from "./Components/NotFound";
import PermissionDenied from "./Components/PermissionDenied";
import PrivateRoute from "./Components/PrivateRoute";
import Roles from "./Components/Roles/Roles";
import RoleCreate from "./Components/Roles/RoleCreate";
import RoleEdit from "./Components/Roles/RoleEdit";
import RoleDelete from "./Components/Roles/RoleDelete";
import ChangePassword from "./Components/Users/ChangePassword";
import Home from "./Components/Home";
import Users from "./Components/Users/Users";
import UserCreate from "./Components/Users/UserCreate";
import UserEdit from "./Components/Users/UserEdit";
import UserDelete from "./Components/Users/UserDelete";
import Languages from "./Components/Languages/Languages";
import LanguageCreate from "./Components/Languages/LanguageCreate";
import LanguageEdit from "./Components/Languages/LanguageEdit";
import LanguageDelete from "./Components/Languages/LanguageDelete";
import Countries from "./Components/Countries/Countries";
import CountryCreate from "./Components/Countries/CountryCreate";
import CountryEdit from "./Components/Countries/CountryEdit";
import CountryDelete from "./Components/Countries/CountryDelete";
import Provinces from "./Components/Provinces/Provinces";
import ProvinceCreate from "./Components/Provinces/ProvinceCreate";
import ProvinceEdit from "./Components/Provinces/ProvinceEdit";
import ProvinceDelete from "./Components/Provinces/ProvinceDelete";
import Cities from "./Components/Cities/Cities";
import CityCreate from "./Components/Cities/CityCreate";
import CityEdit from "./Components/Cities/CityEdit";
import CityDelete from "./Components/Cities/CityDelete";
import Plans from "./Components/Plans/Plans";
import PlanCreate from "./Components/Plans/PlanCreate";
import PlanEdit from "./Components/Plans/PlanEdit";
import PlanDelete from "./Components/Plans/PlanDelete";
import PlanOptions from "./Components/PlanOptions/PlanOptions";
import PlanOptionCreate from "./Components/PlanOptions/PlanOptionCreate";
import PlanOptionEdit from "./Components/PlanOptions/PlanOptionEdit";
import PlanOptionDelete from "./Components/PlanOptions/PlanOptionDelete";
import Posts from "./Components/Posts/Posts";
import PostDetail from "./Components/Posts/PostDetail";
import PostEdit from "./Components/Posts/PostEdit";
import PostDelete from "./Components/Posts/PostDelete";
import Ads from "./Components/Ads/Ads";
import AdDetail from "./Components/Ads/AdDetail";
import AdEdit from "./Components/Ads/AdEdit";
import AdDelete from "./Components/Ads/AdDelete";
import VipAds from "./Components/VipAds/VipAds";
import VipAdEdit from "./Components/VipAds/VipAdEdit";
import VipAdDelete from "./Components/VipAds/VipAdDelete";
import AppUsers from "./Components/AppUsers/AppUsers";
import AppUserEdit from "./Components/AppUsers/AppUserEdit";
import AppUserDelete from "./Components/AppUsers/AppUserDelete";
import AppUserChangePlan from "./Components/AppUsers/AppUserChangePlan";
import AppUserBlock from "./Components/AppUsers/AppUserBlock";
import AppUserNotification from "./Components/AppUsers/AppUserNotification";
import NotificationAll from "./Components/AppUsers/NotificationAll";
import Events from "./Components/Events/Events";
import EventCreate from "./Components/Events/EventCreate";
import EventEdit from "./Components/Events/EventEdit";
import EventDelete from "./Components/Events/EventDelete";
import Term from "./Components/Term/Term";
import Categories from "./Components/Categories/Categories";
import CategoryCreate from "./Components/Categories/CategoryCreate";
import CategoryEdit from "./Components/Categories/CategoryEdit";
import CategoryDelete from "./Components/Categories/CategoryDelete";
import CategoryHeaders from "./Components/CategoryHeaders/CategoryHeaders";
import CategoryHeaderCreate from "./Components/CategoryHeaders/CategoryHeaderCreate";
import CategoryHeaderEdit from "./Components/CategoryHeaders/CategoryHeaderEdit";
import CategoryHeaderDelete from "./Components/CategoryHeaders/CategoryHeaderDelete";
import CategoryFilters from "./Components/CategoryFilters/CategoryFilters";
import CategoryFilterCreate from "./Components/CategoryFilters/CategoryFilterCreate";
import CategoryFilterEdit from "./Components/CategoryFilters/CategoryFilterEdit";
import CategoryFilterDelete from "./Components/CategoryFilters/CategoryFilterDelete";
import ReportedUsers from "./Components/Reports/ReportedUsers";
import ReportedAds from "./Components/Reports/ReportedAds";
import ReportedPosts from "./Components/Reports/ReportedPosts";
import CurrencyUnits from "./Components/CurrencyUnits/CurrencyUnits";
import CurrencyUnitCreate from "./Components/CurrencyUnits/CurrencyUnitCreate";
import CurrencyUnitEdit from "./Components/CurrencyUnits/CurrencyUnitEdit";
import CurrencyUnitDelete from "./Components/CurrencyUnits/CurrencyUnitDelete";
import BlockUsers from "./Components/BlockUsers/BlockUsers";
import BlockUserDelete from "./Components/BlockUsers/BlockUserDelete";

function AppRoutes() {
    return (
        <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/permissionDenied" element={<PermissionDenied />} />
            <Route
                path="/"
                element={
                    <PrivateRoute permissionName="no">
                        <Home />
                    </PrivateRoute>
                }
            />

            <Route
                path="/roles"
                element={
                    <PrivateRoute permissionName="role-list">
                        <Roles />
                    </PrivateRoute>
                }
            />
            <Route
                path="/role-create"
                element={
                    <PrivateRoute permissionName="role-create">
                        <RoleCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/role-edit/:id"
                element={
                    <PrivateRoute permissionName="role-edit">
                        <RoleEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/role-delete/:id"
                element={
                    <PrivateRoute permissionName="role-delete">
                        <RoleDelete />
                    </PrivateRoute>
                }
            />
            <Route
                path="/users"
                element={
                    <PrivateRoute permissionName="user-list">
                        <Users />
                    </PrivateRoute>
                }
            />
            <Route
                path="/user-create"
                element={
                    <PrivateRoute permissionName="user-create">
                        <UserCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/user-edit/:id"
                element={
                    <PrivateRoute permissionName="user-edit">
                        <UserEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/user-delete/:id"
                element={
                    <PrivateRoute permissionName="user-delete">
                        <UserDelete />
                    </PrivateRoute>
                }
            />
            <Route
                path="/changePassword"
                element={
                    <PrivateRoute permissionName="no">
                        <ChangePassword />
                    </PrivateRoute>
                }
            />

            <Route
                path="/term"
                element={
                    <PrivateRoute permissionName="term-getTerm">
                        <Term />
                    </PrivateRoute>
                }
            />

            <Route
                path="/languages"
                element={
                    <PrivateRoute permissionName="language-list">
                        <Languages />
                    </PrivateRoute>
                }
            />
            <Route
                path="/language-create"
                element={
                    <PrivateRoute permissionName="language-create">
                        <LanguageCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/language-edit/:id"
                element={
                    <PrivateRoute permissionName="language-edit">
                        <LanguageEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/language-delete/:id"
                element={
                    <PrivateRoute permissionName="language-delete">
                        <LanguageDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/countries"
                element={
                    <PrivateRoute permissionName="country-list">
                        <Countries />
                    </PrivateRoute>
                }
            />
            <Route
                path="/country-create"
                element={
                    <PrivateRoute permissionName="country-create">
                        <CountryCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/country-edit/:id"
                element={
                    <PrivateRoute permissionName="country-edit">
                        <CountryEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/country-delete/:id"
                element={
                    <PrivateRoute permissionName="country-delete">
                        <CountryDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/provinces/:countryId"
                element={
                    <PrivateRoute permissionName="province-list">
                        <Provinces />
                    </PrivateRoute>
                }
            />
            <Route
                path="/province-create/:cId"
                element={
                    <PrivateRoute permissionName="province-create">
                        <ProvinceCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/province-edit/:id/:cId"
                element={
                    <PrivateRoute permissionName="province-edit">
                        <ProvinceEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/province-delete/:id/:cId"
                element={
                    <PrivateRoute permissionName="province-delete">
                        <ProvinceDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/cities/:provinceId/:countryId"
                element={
                    <PrivateRoute permissionName="city-list">
                        <Cities />
                    </PrivateRoute>
                }
            />
            <Route
                path="/city-create/:pId/:cId"
                element={
                    <PrivateRoute permissionName="city-create">
                        <CityCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/city-edit/:id/:pId/:cId"
                element={
                    <PrivateRoute permissionName="city-edit">
                        <CityEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/city-delete/:id/:pId/:cId"
                element={
                    <PrivateRoute permissionName="city-delete">
                        <CityDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/plans"
                element={
                    <PrivateRoute permissionName="plan-list">
                        <Plans />
                    </PrivateRoute>
                }
            />
            <Route
                path="/plan-create"
                element={
                    <PrivateRoute permissionName="plan-create">
                        <PlanCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/plan-edit/:id"
                element={
                    <PrivateRoute permissionName="plan-edit">
                        <PlanEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/plan-delete/:id"
                element={
                    <PrivateRoute permissionName="plan-delete">
                        <PlanDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/planOptions/:planId"
                element={
                    <PrivateRoute permissionName="plan-list">
                        <PlanOptions />
                    </PrivateRoute>
                }
            />
            <Route
                path="/planOption-create/:pId"
                element={
                    <PrivateRoute permissionName="plan-create">
                        <PlanOptionCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/planOption-edit/:id/:pId"
                element={
                    <PrivateRoute permissionName="plan-edit">
                        <PlanOptionEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/planOption-delete/:id/:pId"
                element={
                    <PrivateRoute permissionName="plan-delete">
                        <PlanOptionDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/posts"
                element={
                    <PrivateRoute permissionName="post-list">
                        <Posts />
                    </PrivateRoute>
                }
            />
            <Route
                path="/post-detail/:id"
                element={
                    <PrivateRoute permissionName="post-detail">
                        <PostDetail />
                    </PrivateRoute>
                }
            />
            <Route
                path="/post-edit/:id"
                element={
                    <PrivateRoute permissionName="post-edit">
                        <PostEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/post-delete/:id"
                element={
                    <PrivateRoute permissionName="post-delete">
                        <PostDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/ads"
                element={
                    <PrivateRoute permissionName="ad-list">
                        <Ads />
                    </PrivateRoute>
                }
            />
            <Route
                path="/ad-detail/:id"
                element={
                    <PrivateRoute permissionName="ad-detail">
                        <AdDetail />
                    </PrivateRoute>
                }
            />
            <Route
                path="/ad-edit/:id"
                element={
                    <PrivateRoute permissionName="ad-edit">
                        <AdEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/ad-delete/:id"
                element={
                    <PrivateRoute permissionName="ad-delete">
                        <AdDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/vipAds"
                element={
                    <PrivateRoute permissionName="vipAd-list">
                        <VipAds />
                    </PrivateRoute>
                }
            />
            <Route
                path="/vipAd-edit/:id"
                element={
                    <PrivateRoute permissionName="vipAd-edit">
                        <VipAdEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/vipAd-delete/:id"
                element={
                    <PrivateRoute permissionName="vipAd-delete">
                        <VipAdDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/reportedUsers"
                element={
                    <PrivateRoute permissionName="reportedUser-list">
                        <ReportedUsers />
                    </PrivateRoute>
                }
            />

            <Route
                path="/reportedAds"
                element={
                    <PrivateRoute permissionName="reportedAd-list">
                        <ReportedAds />
                    </PrivateRoute>
                }
            />

            <Route
                path="/reportedPosts"
                element={
                    <PrivateRoute permissionName="reportedPost-list">
                        <ReportedPosts />
                    </PrivateRoute>
                }
            />

            <Route
                path="/appUsers"
                element={
                    <PrivateRoute permissionName="app-user-list">
                        <AppUsers />
                    </PrivateRoute>
                }
            />
            <Route
                path="/appUser-edit/:id"
                element={
                    <PrivateRoute permissionName="app-user-edit">
                        <AppUserEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/appUser-delete/:id"
                element={
                    <PrivateRoute permissionName="app-user-delete">
                        <AppUserDelete />
                    </PrivateRoute>
                }
            />
            <Route
                path="/appUser-changePlan/:id/:pId"
                element={
                    <PrivateRoute permissionName="app-user-changePlan">
                        <AppUserChangePlan />
                    </PrivateRoute>
                }
            />
            <Route
                path="/appUser-block/:id/:blocked/:reason"
                element={
                    <PrivateRoute permissionName="app-user-block">
                        <AppUserBlock />
                    </PrivateRoute>
                }
            />
            <Route
                path="/appUser-notification/:id"
                element={
                    <PrivateRoute permissionName="app-user-notification">
                        <AppUserNotification />
                    </PrivateRoute>
                }
            />
            <Route
                path="/notificationAll"
                element={
                    <PrivateRoute permissionName="app-user-notification-all-users">
                        <NotificationAll />
                    </PrivateRoute>
                }
            />

            <Route
                path="/events"
                element={
                    <PrivateRoute permissionName="event-list">
                        <Events />
                    </PrivateRoute>
                }
            />
            <Route
                path="/event-create"
                element={
                    <PrivateRoute permissionName="event-create">
                        <EventCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/event-edit/:id"
                element={
                    <PrivateRoute permissionName="event-edit">
                        <EventEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/event-delete/:id"
                element={
                    <PrivateRoute permissionName="event-delete">
                        <EventDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/categories"
                element={
                    <PrivateRoute permissionName="category-list">
                        <Categories />
                    </PrivateRoute>
                }
            />
            <Route
                path="/category-create/:id/:name"
                element={
                    <PrivateRoute permissionName="category-create">
                        <CategoryCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/category-edit/:id"
                element={
                    <PrivateRoute permissionName="category-edit">
                        <CategoryEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/category-delete/:id"
                element={
                    <PrivateRoute permissionName="category-delete">
                        <CategoryDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/categoryHeaders"
                element={
                    <PrivateRoute permissionName="categoryHeader-list">
                        <CategoryHeaders />
                    </PrivateRoute>
                }
            />
            <Route
                path="/categoryHeader-create"
                element={
                    <PrivateRoute permissionName="categoryHeader-create">
                        <CategoryHeaderCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/categoryHeader-edit/:id"
                element={
                    <PrivateRoute permissionName="categoryHeader-edit">
                        <CategoryHeaderEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/categoryHeader-delete/:id"
                element={
                    <PrivateRoute permissionName="categoryHeader-delete">
                        <CategoryHeaderDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/categoryFilters/:categoryId"
                element={
                    <PrivateRoute permissionName="categoryFilter-list">
                        <CategoryFilters />
                    </PrivateRoute>
                }
            />
            <Route
                path="/categoryFilter-create/:cId"
                element={
                    <PrivateRoute permissionName="categoryFilter-create">
                        <CategoryFilterCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/categoryFilter-edit/:id/:cId"
                element={
                    <PrivateRoute permissionName="categoryFilter-edit">
                        <CategoryFilterEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/categoryFilter-delete/:id/:cId"
                element={
                    <PrivateRoute permissionName="categoryFilter-delete">
                        <CategoryFilterDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/currencyUnits"
                element={
                    <PrivateRoute permissionName="currencyUnit-list">
                        <CurrencyUnits />
                    </PrivateRoute>
                }
            />
            <Route
                path="/currencyUnit-create"
                element={
                    <PrivateRoute permissionName="currencyUnit-create">
                        <CurrencyUnitCreate />
                    </PrivateRoute>
                }
            />
            <Route
                path="/currencyUnit-edit/:id"
                element={
                    <PrivateRoute permissionName="currencyUnit-edit">
                        <CurrencyUnitEdit />
                    </PrivateRoute>
                }
            />
            <Route
                path="/currencyUnit-delete/:id"
                element={
                    <PrivateRoute permissionName="currencyUnit-delete">
                        <CurrencyUnitDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="/blockUsers"
                element={
                    <PrivateRoute permissionName="blockedUser-list">
                        <BlockUsers />
                    </PrivateRoute>
                }
            />
            <Route
                path="/blockUser-delete/:id"
                element={
                    <PrivateRoute permissionName="blockedUser-delete">
                        <BlockUserDelete />
                    </PrivateRoute>
                }
            />

            <Route
                path="*"
                element={
                    <PrivateRoute permissionName="no">
                        <NotFound />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
}

export default AppRoutes;
