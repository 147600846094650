import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Badge, Card, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { usersAction } from '../../actions/userActions';
import { Pagination } from "@mui/material";

const Users = () => {
  const context = useContext(SimpleContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);

  const users = useSelector((state) => state.users);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (users.result) {
      context.loading = false;
      setPageCount(users.result["last_page"]);
      if (users.result["data"]) setData(users.result["data"]);
    }
  }, [users])

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(usersAction(navigate, page));
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Admin Users</h4> &nbsp;
            {(PermissionService.hasPermission('user-create', context.permissions)) ? (
              <Link className="btn btn-info" to="/user-create">
                Create User
              </Link>
            ) : ('')}
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Roles</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>
                      {item["roles"].map((r, i) => (
                        <Badge key={r.id}>{r.name}</Badge>
                      ))}
                    </td>
                    {item.is_active ? (
                      <td className="text-success">Active</td>
                    ) : (
                      <td className="text-danger">Not Active</td>
                    )}
                    <td>
                      {(PermissionService.hasPermission('user-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/user-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('user-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/user-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default Users;
