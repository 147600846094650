import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { postEditAction, postDeleteAction } from '../../actions/postActions'

const PostDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null);
  const postEdit = useSelector((state) => state.postEdit);
  const postDelete = useSelector((state) => state.postDelete);

  useEffect(() => {
    getPost();
  }, []);

  const getPost = () => {
    context.loading = true;
    setError(null);
    dispatch(postEditAction(navigate, id));
  };

  useEffect(() => {
    if (postEdit.result) {
      context.loading = false;
      setTitle(postEdit.result.title);
      setFullName(postEdit.result.fullName);
      if (postEdit.result.userName)
        setUserName(postEdit.result.userName);
      else
        setUserName('');
      setStatus(postEdit.result.status);
    }
  }, [postEdit])

  const DeletePost = () => {
    context.loading = true;
    setError(null);
    dispatch(postDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (postDelete.result) {
      context.loading = false;
      if (postDelete.result.status !== 200)
        setError(postDelete.result.data.message);
    }
  }, [postDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Title is required!");
    else {
      DeletePost();
    }
  };

  const changeStatus = (value) => {
    setStatus(value);
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Post </h4>
            &nbsp;
            &nbsp;
            <Link className="btn btn-info" to="/posts">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  disabled
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="input-group justify-content-around">
                <Form.Check
                  inline
                  label="Waiting"
                  type='radio'
                  disabled
                  style={{ color: 'orange' }}
                  checked={status === 'waiting' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='waiting'
                />
                <Form.Check
                  inline
                  label="Accept"
                  type='radio'
                  disabled
                  style={{ color: 'green' }}
                  checked={status === 'accept' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='accept'
                />
                <Form.Check
                  inline
                  label="Reject"
                  type='radio'
                  disabled
                  style={{ color: 'red' }}
                  checked={status === 'reject' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='reject'
                />
                <Form.Check
                  inline
                  label="Remove"
                  type='radio'
                  disabled
                  style={{ color: 'blue' }}
                  checked={status === 'remove' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='remove'
                />
              </div>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default PostDelete;
