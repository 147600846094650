import { ReportedUsers, ReportedUserDelete, ReportedAds, ReportedAdDelete, ReportedPosts, ReportedPostDelete } from "./actionTypes";
import { reportedUsers, reportedUserDelete, reportedAds, reportedAdDelete, reportedPosts, reportedPostDelete } from '../api/reports';

export const reportedUsersAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: ReportedUsers, payload: null });
        reportedUsers(navigate, page).then(async (result) => {
            await dispatch({ type: ReportedUsers, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ReportedUsers, payload: error });
        });
    };
};

export const reportedUserDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: ReportedUserDelete, payload: null });
        reportedUserDelete(navigate, id).then(async (result) => {
            await dispatch({ type: ReportedUserDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ReportedUserDelete, payload: error });
        });
    };
};

export const reportedAdsAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: ReportedAds, payload: null });
        reportedAds(navigate, page).then(async (result) => {
            await dispatch({ type: ReportedAds, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ReportedAds, payload: error });
        });
    };
};

export const reportedAdDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: ReportedAdDelete, payload: null });
        reportedAdDelete(navigate, id).then(async (result) => {
            await dispatch({ type: ReportedAdDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ReportedAdDelete, payload: error });
        });
    };
};

export const reportedPostsAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: ReportedPosts, payload: null });
        reportedPosts(navigate, page).then(async (result) => {
            await dispatch({ type: ReportedPosts, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ReportedPosts, payload: error });
        });
    };
};

export const reportedPostDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: ReportedPostDelete, payload: null });
        reportedPostDelete(navigate, id).then(async (result) => {
            await dispatch({ type: ReportedPostDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: ReportedPostDelete, payload: error });
        });
    };
};