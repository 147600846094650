import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { planOptionsAction } from '../../actions/planOptionActions';
import { Pagination } from "@mui/material";

const PlanOptions = () => {
  const context = useContext(SimpleContext);
  const { planId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [langs, setLangs] = useState([]);
  const [plan, setPlan] = useState('');
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const planOptions = useSelector((state) => state.planOptions);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    const data = {
      plan_id: planId
    };
    dispatch(planOptionsAction(navigate, page, data));
  };

  useEffect(() => {
    if (planOptions.result) {
      context.loading = false;
      setPlan(planOptions.result.plan);
      setLangs(planOptions.result.langs);
      setPageCount(planOptions.result.options["last_page"]);
      if (planOptions.result.options["data"]) setData(planOptions.result.options["data"]);
    }
  }, [planOptions])

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Options of </h4> &nbsp;&nbsp;&nbsp; <h4 className="text-warning">{plan ? plan.title : ''}</h4>
            &nbsp;&nbsp;&nbsp;
            {(PermissionService.hasPermission('plan-create', context.permissions)) ? (
              <Link className="btn btn-info" to={`/planOption-create/${planId}`}>
                New Option
              </Link>
            ) : ('')}
            &nbsp;&nbsp;&nbsp;
            <Link className="btn btn-primary" to="/plans">
              Back to Plans
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Text</th>
                  {langs.map((item) => (
                    <th key={item.id}>{`Text (${item.title})`}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.text}</td>
                    {item.translations.map((t, i) => (
                      <td key={i} > {t.text}</td>
                    ))}
                    <td>
                      {(PermissionService.hasPermission('plan-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/planOption-edit/${item.id}/${planId}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('plan-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/planOption-delete/${item.id}/${planId}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default PlanOptions;
