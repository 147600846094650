import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, SubMenu, Sidebar, useProSidebar, } from "react-pro-sidebar";
import { FaTachometerAlt, FaUser, FaUsers, FaRegSun, FaLanguage, FaGlobeAmericas, FaAlignJustify, FaCameraRetro, FaBell, FaPlay, FaScroll, FaClipboardList, FaLayerGroup, FaAd, FaFileInvoiceDollar, FaListAlt, FaDollarSign, FaBan } from "react-icons/fa";
import sidebarBg from "../../assets/bg1.jpg";
import { useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import AuthService from "../../Services/AuthService";
import PermissionService from "../../Services/PermissionService";
import useScreenType from "react-screentype-hook";

const Aside = ({ toggled, setToggled }) => {
  const navigate = useNavigate();
  const context = useContext(SimpleContext);
  const screenType = useScreenType();


  const handleLogout = () => {
    AuthService.logout(navigate);
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Sidebar
        image={sidebarBg}
        collapsed={toggled}
        toggled={toggled}
        breakPoint="sm"
      >

        <div style={{ width: '100%', textAlign: 'center', marginTop: 15 }}>
          <a style={{ fontSize: 20 }} onClick={() => setToggled(!toggled)}><FaAlignJustify /></a>
        </div>

        <div
          style={{
            textAlign: "center",
            padding: "10px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {screenType.isMobile ? (
            <>
              <h6>Can-Do</h6>
              <h6>Admin Panel</h6>
            </>
          ) : (
            <>
              <h2>Can-Do</h2>
              <h5>Admin Panel</h5>
            </>
          )}

        </div>
        <div
          style={{
            textAlign: "center",
            padding: "14px",
            paddingTop: 0,
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 8,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <button
            className="btn btn-success"
            onClick={() => {
              navigate("/changePassword");
            }}
            style={{ fontSize: 10 }}
          >
            {`${context.user["name"]}`}
          </button>

        </div>
        <div
          style={{
            textAlign: "center",
            padding: "24px",
            paddingTop: 0,
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >

          <input
            style={{ fontSize: 10 }}
            type="button"
            onClick={handleLogout}
            className="btn btn-danger"
            value="Log Off"
          />
        </div>

        <Menu iconShape="circle">
          <MenuItem
            onClick={() => {
              navigate("/");
            }}
            icon={<FaTachometerAlt />}
          >
            Dashboard
          </MenuItem>

          {PermissionService.hasPermission("role-list", context.permissions) ||
            PermissionService.hasPermission("user-list", context.permissions) ? (
            <SubMenu label="Admin Users Manager" icon={<FaUsers />}>
              {PermissionService.hasPermission(
                "role-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/roles");
                  }}
                  icon={<FaUsers />}
                >
                  Admin Roles
                </MenuItem>
              ) : (
                ""
              )}
              {PermissionService.hasPermission(
                "user-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/users");
                  }}
                  icon={<FaUser />}
                >
                  Admin Users
                </MenuItem>
              ) : (
                ""
              )}
            </SubMenu>
          ) : (
            ""
          )}

          <SubMenu label="Constant Settings" icon={<FaRegSun />}>
            {PermissionService.hasPermission(
              "language-list",
              context.permissions
            ) ? (
              <MenuItem
                onClick={() => {
                  navigate("/languages");
                }}
                icon={<FaLanguage />}
              >
                Languages
              </MenuItem>
            ) : (
              ""
            )}

            {PermissionService.hasPermission(
              "country-list",
              context.permissions
            ) ? (
              <MenuItem
                onClick={() => {
                  navigate("/countries");
                }}
                icon={<FaGlobeAmericas />}
              >
                Countries
              </MenuItem>
            ) : (
              ""
            )}

            {PermissionService.hasPermission(
              "term-getTerm",
              context.permissions
            ) ? (
              <MenuItem
                onClick={() => {
                  navigate("/term");
                }}
                icon={<FaScroll />}
              >
                Application Term
              </MenuItem>
            ) : (
              ""
            )}

            {PermissionService.hasPermission(
              "currencyUnit-list",
              context.permissions
            ) ? (
              <MenuItem
                onClick={() => {
                  navigate("/currencyUnits");
                }}
                icon={<FaDollarSign />}
              >
                Currency Units
              </MenuItem>
            ) : (
              ""
            )}

          </SubMenu>

          {PermissionService.hasPermission("plan-list", context.permissions) ? (
            <SubMenu label="Plans Manager" icon={<FaAlignJustify />}>
              {PermissionService.hasPermission(
                "plan-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/plans");
                  }}
                  icon={<FaAlignJustify />}
                >
                  Plans
                </MenuItem>
              ) : (
                ""
              )}
            </SubMenu>
          ) : (
            ""
          )}

          {PermissionService.hasPermission("category-list", context.permissions) ? (
            <SubMenu label="Categories Manager" icon={<FaClipboardList />}>
              {PermissionService.hasPermission(
                "category-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/categories");
                  }}
                  icon={<FaClipboardList />}
                >
                  Categories
                </MenuItem>
              ) : (
                ""
              )}

              {PermissionService.hasPermission(
                "categoryHeader-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/categoryHeaders");
                  }}
                  icon={<FaLayerGroup />}
                >
                  Headers (Groups)
                </MenuItem>
              ) : (
                ""
              )}

            </SubMenu>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "event-list",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/events");
              }}
              icon={<FaPlay />}
            >
              Events
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "post-list",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/posts");
              }}
              icon={<FaCameraRetro />}
            >
              Posts
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "ad-list",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/ads");
              }}
              icon={<FaAd />}
            >
              Ads
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "vipAd-list",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/vipAds");
              }}
              icon={<FaFileInvoiceDollar />}
            >
              VIP Ads
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "app-user-list",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/appUsers");
              }}
              icon={<FaUsers />}
            >
              Application Users
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "blockedUser-list",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/blockUsers");
              }}
              icon={<FaBan />}
            >
              Blocked Users
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission(
            "app-user-notification-all-users",
            context.permissions
          ) ? (
            <MenuItem
              onClick={() => {
                navigate("/notificationAll");
              }}
              icon={<FaBell />}
            >
              Notification To All Users
            </MenuItem>
          ) : (
            ""
          )}

          {PermissionService.hasPermission("reportedUser-list", context.permissions) ||
            PermissionService.hasPermission("reportedAd-list", context.permissions) ||
            PermissionService.hasPermission("reportedPost-list", context.permissions) ? (
            <SubMenu label="Reports" icon={<FaListAlt />}>

              {PermissionService.hasPermission(
                "reportedUser-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/reportedUsers");
                  }}
                  icon={<FaUsers />}
                >
                  Reported Users
                </MenuItem>
              ) : (
                ""
              )}

              {PermissionService.hasPermission(
                "reportedAd-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/reportedAds");
                  }}
                  icon={<FaAd />}
                >
                  Reported Ads
                </MenuItem>
              ) : (
                ""
              )}

              {PermissionService.hasPermission(
                "reportedPost-list",
                context.permissions
              ) ? (
                <MenuItem
                  onClick={() => {
                    navigate("/reportedPosts");
                  }}
                  icon={<FaCameraRetro />}
                >
                  Reported Posts
                </MenuItem>
              ) : (
                ""
              )}

            </SubMenu>
          ) : (
            ""
          )}

        </Menu>

        <div style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              Can-Do Technical Team
            </span>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default Aside;
