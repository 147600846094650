import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Col, Image, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { appUsersAction } from '../../actions/appUserActions';
import http from '../../api'
import { Pagination } from "@mui/material";

const AppUsers = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const appUsers = useSelector((state) => state.appUsers);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(appUsersAction(navigate, page, search));
  };

  useEffect(() => {
    if (appUsers.result) {
      context.loading = false;
      setPageCount(appUsers.result["last_page"]);
      if (appUsers.result.data) {
        setData(appUsers.result.data);
      }
    }
  }, [appUsers])

  useEffect(() => {
    fetchData()
  }, [search])

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <Row className="p-3">
            <Col md={8}>
              <div className="input-group">
                <h4>Application Users</h4> &nbsp;
              </div>
            </Col>
            <Col md={4}>
              <input
                className="form-control"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
              />
            </Col>
          </Row>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Mobile</th>
                  <th>Full Name</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>OTP</th>
                  <th>Plan</th>
                  <th>City</th>
                  <th>Address</th>
                  <th>Postal Code</th>
                  <th>Credit</th>
                  <th>Avatar</th>
                  <th>Is Active</th>
                  <th>Is Blocked</th>
                  <th>Blocked Reason</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.code}</td>
                    <td>{item.mobile}</td>
                    <td>{`${item.first_name} ${item.last_name}`}</td>
                    <td>{item.user_name}</td>
                    <td>{item.email}</td>
                    <td>{item.otp}</td>
                    {item.plan ? (
                      <td className="text-success">{item.plan.title}</td>
                    ) : (
                      <td className="text-danger">Nothing</td>
                    )}
                    <td>{item.city ? item.city.title : ''}</td>
                    <td>{item.address}</td>
                    <td>{item.postal_code}</td>
                    <td>{item.credit}</td>
                    {item.avatar_path ? (
                      <td><Image style={{ width: 85, height: 'auto' }} src={http.assetUrl + '/' + item.avatar_path} /></td>
                    ) : (
                      <td>No Avatar</td>
                    )}
                    {item.is_active ? (
                      <td className="text-success">Active</td>
                    ) : (
                      <td className="text-danger">Not Active</td>
                    )}
                    {item.is_blocked ? (
                      <td className="text-danger">Blocked</td>
                    ) : (
                      <td className="text-success">No</td>
                    )}
                    <td>{item.blocked_reason}</td>

                    <td>
                      {(PermissionService.hasPermission('app-user-changePlan', context.permissions)) ? (
                        <Link
                          className="btn btn-sm btn-success"
                          to={item.plan ? `/appUser-changePlan/${item.id}/${item.plan.id}` : `/appUser-changePlan/${item.id}/null`}
                        >
                          Plan
                        </Link>
                      ) : ('')}
                      {(PermissionService.hasPermission('app-user-block', context.permissions)) ? (
                        <Link
                          className="btn btn-sm btn-primary"
                          to={`/appUser-block/${item.id}/${item.is_blocked}/${item.blocked_reason}`}
                        >
                          Block
                        </Link>
                      ) : ('')}
                      {(PermissionService.hasPermission('app-user-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-sm btn-warning"
                          to={`/appUser-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      {(PermissionService.hasPermission('app-user-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-sm btn-danger"
                          to={`/appUser-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                      {(PermissionService.hasPermission('app-user-notification', context.permissions)) ? (
                        <Link
                          className="btn btn-sm btn-info"
                          to={`/appUser-notification/${item.id}`}
                        >
                          Notification
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout >
  );
};

export default AppUsers;
