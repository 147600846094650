import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { adEditAction, adDeleteAction } from '../../actions/adActions'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

const AdDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [error, setError] = useState(null);
  const adEdit = useSelector((state) => state.adEdit);
  const adDelete = useSelector((state) => state.adDelete);

  useEffect(() => {
    getAd();
  }, []);

  const getAd = () => {
    context.loading = true;
    setError(null);
    dispatch(adEditAction(navigate, id));
  };

  useEffect(() => {
    if (adEdit.result) {
      setCategories(adEdit.result.categories);
      if (adEdit.result.ad.category) {
        const sc = adEdit.result.categories.find((item) => item.id == adEdit.result.ad.category.id)
        setSelectCategory([sc])
      }
      else
        setSelectCategory([]);
      setFullName(adEdit.result.ad.fullName);
      if (adEdit.result.ad.userName)
        setUserName(adEdit.result.ad.userName);
      else
        setUserName('');
      if (adEdit.result.ad.title)
        setTitle(adEdit.result.ad.title);
      else
        setTitle('');
      if (adEdit.result.ad.description)
        setDescription(adEdit.result.ad.description);
      else
        setDescription('');
      setStatus(adEdit.result.ad.status);
      context.loading = false;
    }
  }, [adEdit])

  const UpdateAd = () => {
    context.loading = true;
    setError(null);
    dispatch(adDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (adDelete.result) {
      context.loading = false;
      if (adDelete.result.status !== 200)
        setError(adDelete.result.data.message);
    }
  }, [adDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectCategory || selectCategory.length == 0) setError("Category is required!");
    else {
      UpdateAd();
    }
  };

  const changeStatus = (value) => {
    setStatus(value);
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Ad </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{title}</h4>
            &nbsp;
            &nbsp;
            <Link className="btn btn-info" to="/ads">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="title"
                  disabled
                  onChange={setSelectCategory}
                  options={categories}
                  placeholder="Choose a Category..."
                  selected={selectCategory}
                />
              </Form.Group>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-2 col-lg-12">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-12">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="input-group justify-content-around">
                <Form.Check
                  inline
                  label="Waiting"
                  disabled
                  type='radio'
                  style={{ color: 'orange' }}
                  checked={status === 'waiting' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='waiting'
                />
                <Form.Check
                  inline
                  label="Accept"
                  type='radio'
                  disabled
                  style={{ color: 'green' }}
                  checked={status === 'accept' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='accept'
                />
                <Form.Check
                  inline
                  label="Reject"
                  type='radio'
                  disabled
                  style={{ color: 'red' }}
                  checked={status === 'reject' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='reject'
                />
                <Form.Check
                  inline
                  label="Remove"
                  type='radio'
                  disabled
                  style={{ color: 'blue' }}
                  checked={status === 'remove' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='remove'
                />
              </div>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default AdDelete;
