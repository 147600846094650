import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import SimpleContext from "../../Contexts/SimpleContext";
import AuthService from "../../Services/AuthService";
import Layout from "../Shared/Layout";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { userChangePasswordAction } from "../../actions/userActions";

const ChangePassword = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id] = useState(context.user["id"]);
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const userChangePassword = useSelector((state) => state.userChangePassword);

  const changePassword = () => {
    context.loading = true;
    setError(null);
    const data = {
      id: id,
      current_password: current_password,
      new_password: new_password,
      confirm_password: confirm_password,
    };
    dispatch(userChangePasswordAction(navigate, data));
  };

  useEffect(() => {
    if (userChangePassword.result) {
      context.loading = false;
      if (userChangePassword.result.error)
        setError(userChangePassword.result.error);
      else
        AuthService.logout(navigate);
    }
  }, [userChangePassword])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (current_password === "") setError("Enter Current Password is required!");
    else if (new_password === "") setError("Enter New Password is required!");
    else if (confirm_password === "")
      setError("Enter Confirm New Password is required!");
    else if (new_password !== confirm_password)
      setError("New Password and Confirm New Password does not match!");
    else {
      changePassword();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Change Password</h4> &nbsp;
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Enter Current Password..."
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter New Password..."
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Enter Confirm New Password..."
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Change Password
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default ChangePassword;
