import AuthService from "../Services/AuthService";

export function checkToken(result, navigate) {
    if (result.data["error"]) {
        if (result.data["error"] === "Token is Expired") {
            AuthService.logout(navigate);
        }
        else if (result.data["error"] === "Token is Invalid") {
            AuthService.logout(navigate);
        }
        else if (result.data["error"] === "Authorization Token not found") {
            AuthService.logout(navigate);
        }
        else
            return true;
    } else
        return true;
}