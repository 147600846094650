import { CityDelete, CityEdit, Cities, CityStore, CityUpdate } from "../actions/actionTypes";

export const citiesReducer = (state = {}, action) => {
    switch (action.type) {
        case Cities:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const cityStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CityStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const cityEditReducer = (state = {}, action) => {
    switch (action.type) {
        case CityEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const cityUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CityUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const cityDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CityDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};