export const Dashboard = "DASHBOARD";
export const DashboardGetByDate = "DASHBOARD_GET_BY_DATE";

export const Users = "USERS";
export const UserCreate = "USER_CREATE";
export const UserStore = "USER_STORE";
export const UserEdit = "USER_EDIT";
export const UserUpdate = "USER_UPDATE";
export const UserDelete = "USER_DELETE";

export const UserChangePassword = "USER_CHANGE_PASSWORD";

export const Roles = "ROLES";
export const RoleCreate = "ROLE_CREATE";
export const RoleStore = "ROLE_STORE";
export const RoleEdit = "ROLE_EDIT";
export const RoleUpdate = "ROLE_UPDATE";
export const RoleDelete = "ROLE_DELETE";

export const GetTerm = "GET_TERM";
export const SetTerm = "SET_TERM";

export const Languages = "LANGUAGES";
export const LanguageStore = "LANGUAGE_STORE";
export const LanguageEdit = "LANGUAGE_EDIT";
export const LanguageUpdate = "LANGUAGE_UPDATE";
export const LanguageDelete = "LANGUAGE_DELETE";

export const CurrencyUnits = "CURRENCY_UNITS";
export const CurrencyUnitStore = "CURRENCY_UNIT_STORE";
export const CurrencyUnitEdit = "CURRENCY_UNIT_EDIT";
export const CurrencyUnitUpdate = "CURRENCY_UNIT_UPDATE";
export const CurrencyUnitDelete = "CURRENCY_UNIT_DELETE";

export const BlockUsers = "BLOCK_USERS";
export const BlockUserEdit = "BLOCK_USER_EDIT";
export const BlockUserDelete = "BLOCK_USER_DELETE";

export const Countries = "COUNTRIES";
export const CountryStore = "COUNTRY_STORE";
export const CountryEdit = "COUNTRY_EDIT";
export const CountryUpdate = "COUNTRY_UPDATE";
export const CountryDelete = "COUNTRY_DELETE";

export const Provinces = "PROVINCES";
export const ProvinceStore = "PROVINCE_STORE";
export const ProvinceEdit = "PROVINCE_EDIT";
export const ProvinceUpdate = "PROVINCE_UPDATE";
export const ProvinceDelete = "PROVINCE_DELETE";

export const Cities = "CITIES";
export const CityStore = "CITY_STORE";
export const CityEdit = "CITY_EDIT";
export const CityUpdate = "CITY_UPDATE";
export const CityDelete = "CITY_DELETE";

export const Plans = "PLANS";
export const PlanCreate = "PLANS_CREATE";
export const PlanStore = "PLANS_STORE";
export const PlanEdit = "PLANS_EDIT";
export const PlanUpdate = "PLANS_UPDATE";
export const PlanDelete = "PLANS_DELETE";

export const PlanOptions = "PLAN_OPTIONS";
export const PlanOptionCreate = "PLAN_OPTIONS_CREATE";
export const PlanOptionStore = "PLAN_OPTIONS_STORE";
export const PlanOptionEdit = "PLAN_OPTIONS_EDIT";
export const PlanOptionUpdate = "PLAN_OPTIONS_UPDATE";
export const PlanOptionDelete = "PLAN_OPTIONS_DELETE";

export const Posts = "POSTS";
export const PostEdit = "POST_EDIT";
export const PostDetail = "POST_DETAIL";
export const PostUpdate = "POST_UPDATE";
export const PostDelete = "POST_DELETE";
export const PostDeleteMedia = "POST_DELETE_MEDIA";

export const Ads = "ADS";
export const AdEdit = "AD_EDIT";
export const AdDetail = "AD_DETAIL";
export const AdUpdate = "AD_UPDATE";
export const AdDelete = "AD_DELETE";
export const AdDeleteMedia = "AD_DELETE_MEDIA";

export const VipAds = "VIP_ADS";
export const VipAdEdit = "VIP_AD_EDIT";
export const VipAdUpdate = "VIP_AD_UPDATE";
export const VipAdDelete = "VIP_AD_DELETE";

export const ReportedUsers = "REPORTED_USERS";
export const ReportedUserDelete = "REPORTED_USER_DELETE";

export const ReportedAds = "REPORTED_ADS";
export const ReportedAdDelete = "REPORTED_Ad_DELETE";

export const ReportedPosts = "REPORTED_POSTS";
export const ReportedPostDelete = "REPORTED_POST_DELETE";

export const AppUsers = "APP_USERS";
export const AppUserCreate = "APP_USER_CREATE";
export const AppUserEdit = "APP_USER_EDIT";
export const AppUserUpdate = "APP_USER_UPDATE";
export const AppUserDelete = "APP_USER_DELETE";
export const AppUserChangePlan = "APP_USER_CHANGE_PLAN";
export const AppUserBlock = "APP_USER_BLOCK";
export const AppUserNotification = "APP_USER_NOTIFICATION";
export const AppUserNotificationAll = "APP_USER_NOTIFICATION_ALL";

export const Events = "EVENTS";
export const EventStore = "EVENTS_STORE";
export const EventEdit = "EVENTS_EDIT";
export const EventUpdate = "EVENTS_UPDATE";
export const EventDelete = "EVENTS_DELETE";

export const Categories = "CATEGORIES";
export const CategoryCreate = "CATEGORIES_CREATE";
export const CategoryStore = "CATEGORIES_STORE";
export const CategoryEdit = "CATEGORIES_EDIT";
export const CategoryUpdate = "CATEGORIES_UPDATE";
export const CategoryDelete = "CATEGORIES_DELETE";

export const CategoryFilters = "CATEGORY_FILTERS";
export const CategoryFilterCreate = "CATEGORY_FILTERS_CREATE";
export const CategoryFilterStore = "CATEGORY_FILTERS_STORE";
export const CategoryFilterEdit = "CATEGORY_FILTERS_EDIT";
export const CategoryFilterUpdate = "CATEGORY_FILTERS_UPDATE";
export const CategoryFilterDelete = "CATEGORY_FILTERS_DELETE";

export const CategoryHeaders = "CATEGORY_HEADERS";
export const CategoryHeaderCreate = "CATEGORY_HEADERS_CREATE";
export const CategoryHeaderStore = "CATEGORY_HEADERS_STORE";
export const CategoryHeaderEdit = "CATEGORY_HEADERS_EDIT";
export const CategoryHeaderUpdate = "CATEGORY_HEADERS_UPDATE";
export const CategoryHeaderDelete = "CATEGORY_HEADERS_DELETE";