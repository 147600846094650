import $ from "jquery";
$("document").ready(function () {
	"use strict";
	$(".login-box").animate({
		top: "10%"
	}, 400, function () {
		$(".login-box").animate({
			width: "400px"
		}, 400, function () {
			$(".login-box").animate({
				height: "400px"
			}, 400, function () {
				$(".login-box").animate({
					borderRadius: "10px"
				}, 400, function () {
					$(".login-img").fadeIn(400, function () {
						$(".login-h3").slideDown(400, function () {
							$(".login-form-control").slideDown(400, function () {
								$(".login-btn").slideDown(400, function () {
									$(".login-a").fadeIn(function () {
										$(".login-a").animate({
											right: "0px"
										}, 400);
									});
								});
							});
						});
					});
				});
			});
		});
	});
});