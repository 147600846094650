import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import http from "./";
import { checkToken } from "./checkToken";

export const users = async (navigate, page) => {
    try {
        const result = await http.axios.get(UrlServices.getUsersApi() + "?page=" + page, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const userCreate = async (navigate) => {
    try {
        const result = await http.axios.get(UrlServices.getUsersApi() + "/create", {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const userStore = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getUsersApi() + "/store", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/users");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const userEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getUsersApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const userUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getUsersApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/users");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const userDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getUsersApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/users");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const changePassword = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getChangePasswordApi(), data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
        if (error.response)
            return error.response;
    }
}