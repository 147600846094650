import { BlockUsers, BlockUserDelete, BlockUserEdit } from "./actionTypes";
import { getBlockUsers, blockUserDelete, blockUserEdit } from '../api/blockUsers';

export const blockUsersAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: BlockUsers, payload: null });
        getBlockUsers(navigate, page).then(async (result) => {
            await dispatch({ type: BlockUsers, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: BlockUsers, payload: error });
        });
    };
};

export const blockUserEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: BlockUserEdit, payload: null });
        blockUserEdit(navigate, id).then(async (result) => {
            await dispatch({ type: BlockUserEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: BlockUserEdit, payload: error });
        });
    };
};

export const blockUserDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: BlockUserDelete, payload: null });
        blockUserDelete(navigate, id).then(async (result) => {
            await dispatch({ type: BlockUserDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: BlockUserDelete, payload: error });
        });
    };
};