import { RoleCreate, RoleDelete, RoleEdit, Roles, RoleStore, RoleUpdate } from "./actionTypes";
import { roleCreate, roleDelete, roleEdit, roles, roleStore, roleUpdate } from '../api/roles';

export const rolesAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: Roles, payload: null });
        roles(navigate, page).then(async (result) => {
            await dispatch({ type: Roles, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Roles, payload: error });
        });
    };
};

export const roleCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: RoleCreate, payload: null });
        roleCreate(navigate).then(async (result) => {
            await dispatch({ type: RoleCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: RoleCreate, payload: error });
        });
    };
};

export const roleStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: RoleStore, payload: null });
        roleStore(navigate, data).then(async (result) => {
            await dispatch({ type: RoleStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: RoleStore, payload: error });
        });
    };
};

export const roleEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: RoleEdit, payload: null });
        roleEdit(navigate, id).then(async (result) => {
            await dispatch({ type: RoleEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: RoleEdit, payload: error });
        });
    };
};

export const roleUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: RoleUpdate, payload: null });
        roleUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: RoleUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: RoleUpdate, payload: error });
        });
    };
};

export const roleDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: RoleDelete, payload: null });
        roleDelete(navigate, id).then(async (result) => {
            await dispatch({ type: RoleDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: RoleDelete, payload: error });
        });
    };
};