import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { planOptionEditAction, planOptionDeleteAction } from '../../actions/planOptionActions'

const PlanOptionDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, pId } = useParams();
  const [langs, setLangs] = useState([]);
  const [text, setText] = useState("");
  const [otherTitle, setOtherTitle] = useState([]);
  const [error, setError] = useState(null);
  const planOptionEdit = useSelector((state) => state.planOptionEdit);
  const planOptionDelete = useSelector((state) => state.planOptionDelete);

  useEffect(() => {
    getLangs();
  }, []);

  const getLangs = () => {
    context.loading = true;
    setError(null);
    dispatch(planOptionEditAction(navigate, id));
  };


  useEffect(() => {
    if (planOptionEdit.result) {
      context.loading = false;
      setLangs(planOptionEdit.result.langs);
      const plan = planOptionEdit.result.option;
      setText(plan.text);

      langs.map((item) => {
        const l = plan['translations[' + item.code + ']'];
        if (l.text) {
          const ot = { code: item.code, value: l.text }
          var lastOtherTitle = otherTitle.find((i) => i.code === item.code);
          if (!lastOtherTitle)
            setOtherTitle((prev) => [...prev, ot]);
        }
      })
    }
  }, [planOptionEdit])

  const deletePlanOption = () => {
    context.loading = true;
    setError(null);
    dispatch(planOptionDeleteAction(navigate, id, pId));
  };

  useEffect(() => {
    if (planOptionDelete.result) {
      context.loading = false;
      if (planOptionDelete.result.status !== 200)
        setError(planOptionDelete.result.data.message);
    }
  }, [planOptionDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (text === "") setError("Enter Text in English is required!");
    else {
      deletePlanOption();
    }
  };

  const changeOtherTitle = (code, value) => {
    var lastOtherTitle = otherTitle.find((i) => i.code === code);
    if (lastOtherTitle) {
      let copyOtherTitle = otherTitle.filter((i) => i.code !== code);
      if (value)
        copyOtherTitle.push({ code: code, value: value });
      setOtherTitle(copyOtherTitle);
    }
    else {
      setOtherTitle((prev) => ([...prev, { code: code, value: value }]));
    }
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Edit Plan Option: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{text}</h4>
            &nbsp;
            <Link className="btn btn-info" to={`/planOptions/${pId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Text in English</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              {langs.map((item, index) => (
                <Form.Group className="mb-3" key={item.id}>
                  <Form.Label>Text ({item.title})</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={otherTitle.find((i) => i.code === item.code) ?
                      otherTitle.find((i) => i.code === item.code)['value'] : ''
                    }
                    onChange={(e) => changeOtherTitle(item.code, e.target.value)}
                    placeholder={`Enter Text in ${item.title} ...`}
                  />
                </Form.Group>
              ))}

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default PlanOptionDelete;
