import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { appUserEditAction, appUserUpdateAction } from '../../actions/appUserActions'
import { Typeahead } from 'react-bootstrap-typeahead';

const AppUserEdit = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [code, setCode] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [credit, setCredit] = useState(0.0);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const appUserEdit = useSelector((state) => state.appUserEdit);
  const appUserUpdate = useSelector((state) => state.appUserUpdate);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    context.loading = true;
    setError(null);
    dispatch(appUserEditAction(navigate, id));
  };


  useEffect(() => {
    if (appUserEdit.result) {
      setCountries(appUserEdit.result.countries);
      setCities(appUserEdit.result.cities);
      const u = appUserEdit.result.user;
      if (u.first_name)
        setFirstName(u.first_name);
      else
        setFirstName('');
      if (u.last_name)
        setLastName(u.last_name);
      else
        setLastName('');
      if (u.user_name)
        setUserName(u.user_name);
      else
        setUserName('');
      if (u.email)
        setEmail(u.email);
      else
        setEmail('');
      setMobile(u.mobile);
      if (u.address)
        setAddress(u.address);
      else
        setAddress('');
      if (u.postal_code)
        setPostalCode(u.postal_code);
      else
        setPostalCode('');
      if (u.credit)
        setCredit(u.credit);
      else
        setCredit(0);
      if (u.code) {
        const cc = appUserEdit.result.countries.find((i) => i.code === u.code.replace('+', ''));
        setCode([cc]);
      }
      else
        setCode('');
      if (u.city_id) {
        const ct = appUserEdit.result.cities.find((i) => i.id === u.city_id);
        setCity([ct]);
      }
      else
        setCity('');
      setIsActive(u.is_active);

      context.loading = false;
    }
  }, [appUserEdit])

  const updateUser = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('id', id);
    data.append('code', `+${code[0].code}`)
    data.append('mobile', mobile)
    data.append('first_name', firstName)
    data.append('last_name', lastName)
    data.append('user_name', userName)
    data.append('email', email)
    data.append('city_id', city[0].id)
    data.append('address', address)
    data.append('postal_code', postalCode)
    data.append('credit', credit)
    if (isActive)
      data.append('is_active', true)
    else
      data.append('is_active', false)
    dispatch(appUserUpdateAction(navigate, data));
  };

  useEffect(() => {
    if (appUserUpdate.result) {
      context.loading = false;
      if (appUserUpdate.result.status !== 200)
        setError(appUserUpdate.result.data.message);
    }
  }, [appUserUpdate])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (code === "") setError("Choose Code is required!");
    else if (mobile === "") setError("Enter Mobile is required!");
    else if (firstName === "") setError("Enter First Name in English is required!");
    else if (lastName === "") setError("Enter Last Name is required!");
    else if (email === "") setError("Enter Email is required!");
    else if (city === "") setError("Choose City is required!");
    else {
      updateUser();
    }
  };


  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Edit User: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{`${firstName} ${lastName}`}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/appUsers">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="title"
                  onChange={setCode}
                  options={countries}
                  placeholder="Choose a Country..."
                  selected={code}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Enter Mobile..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter First Name..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter Last Name..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Enter User Name..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email..."
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>City</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="title"
                  onChange={setCity}
                  options={cities}
                  placeholder="Choose a City..."
                  selected={city}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter Address..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder="Enter Postal Code..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Credit</Form.Label>
                <Form.Control
                  type="number"
                  value={credit}
                  onChange={(e) => setCredit(e.target.value)}
                  placeholder="Enter Credit..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="warning"
                type="submit"
              >
                Update
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default AppUserEdit;
