import Aside from "./Aside";
import "../../Style/App.scss";
import Loading from "../Loading";
import { useContext, useState } from "react";
import SimpleContext from "../../Contexts/SimpleContext";
import { Button } from "react-bootstrap";
import useScreenType from "react-screentype-hook";

const Layout = ({ children }) => {
  const context = useContext(SimpleContext);
  const [toggled, setToggled] = useState(false)
  const screenType = useScreenType();

  return (
    <div className="app">
      <Aside toggled={toggled} setToggled={setToggled} />
      <main style={{ backgroundColor: "#e8cb99" }}>
        {screenType.isMobile &&
          <Button className="btn btn-sm btn-primary" onClick={() => setToggled(!toggled)}>Open Menu</Button>}
        <Loading active={context.loading}>{children}</Loading>
      </main>
    </div>
  );
};

export default Layout;
