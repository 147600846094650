import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userCreateAction, userStoreAction } from "../../actions/userActions";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";

const UserCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [selectRoles, setSelectRoles] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [is_active, setIsActive] = useState(true);
  const [error, setError] = useState(null);
  const userCreate = useSelector((state) => state.userCreate);
  const userStore = useSelector((state) => state.userStore);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    context.loading = true;
    setError(null);
    dispatch(userCreateAction(navigate));
  };

  useEffect(() => {
    if (userCreate.result) {
      context.loading = false;
      setRoles(userCreate.result);
    }
  }, [userCreate])

  const handleChangeRoles = (e) => {
    if (e.target.checked) {
      const p = selectRoles;
      p.push(e.target.value);
      setSelectRoles(p);
    } else {
      setSelectRoles(selectRoles.filter((m) => m != e.target.value));
    }
  };

  const addUser = () => {
    context.loading = true;
    setError(null);
    const data = {
      name: name,
      email: email,
      password: password,
      confirm_password: confirm_password,
      roles: selectRoles,
      is_active: is_active,
    };
    dispatch(userStoreAction(navigate, data));
  };

  useEffect(() => {
    if (userStore.result) {
      context.loading = false;
      if (userStore.result.status !== 200)
        setError(userStore.result.data.message);
    }
  }, [userStore])

  const handleChangeIsActive = () => {
    setIsActive(!is_active);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === "") setError("FullName is required!");
    else if (email === "") setError("Email is required!");
    else if (password === "") setError("Password is required!");
    else if (confirm_password === "") setError("Confirm password is required!");
    else if (password !== confirm_password)
      setError("Password and Confirm Password does not match!");
    else {
      addUser();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New User</h4> &nbsp;
            <Link className="btn btn-info" to="/users">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>FullName</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Full Name..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Enter Confirm Password..."
                />
              </Form.Group>

              <div className="input-group justify-content-center">
                <Form.Group className="m-3">
                  <Form.Check
                    className="form-control"
                    type="checkbox"
                    onClick={handleChangeIsActive}
                    checked={is_active}
                    label="Active"
                  />
                </Form.Group>
                <div className=" m-3">
                  <div className="form-control">
                    <Form.Label>Roles: </Form.Label>
                    <Form.Group className="mb-3">
                      {roles.map((item, i) => (
                        <Form.Check
                          type="checkbox"
                          onClick={handleChangeRoles}
                          key={item.id}
                          value={item.id}
                          label={item.name}
                        />
                      ))}
                    </Form.Group>
                  </div>
                </div>
              </div>
              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default UserCreate;
