import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { categoryHeadersAction } from '../../actions/categoryHeaderActions';

const CategoryHeaders = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [langs, setLangs] = useState([]);
  const [error, setError] = useState(null);
  const categoryHeaders = useSelector((state) => state.categoryHeaders);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(categoryHeadersAction(navigate));
  };

  useEffect(() => {
    if (categoryHeaders.result) {
      context.loading = false;
      if (categoryHeaders.result.headers) {
        setData(categoryHeaders.result.headers);
        setLangs(categoryHeaders.result.langs);
      }
    }
  }, [categoryHeaders])

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Category Headers (Group)</h4> &nbsp;
            {(PermissionService.hasPermission('categoryHeader-create', context.permissions)) ? (
              <Link className="btn btn-info" to="/categoryHeader-create">
                New Category Header (Group)
              </Link>
            ) : ('')}
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  {langs.map((item) => (
                    <th key={item.id}>{`Title (${item.title})`}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    {item.translations.map((t, i) => (
                      <td key={i} > {t.title}</td>
                    ))}
                    <td>
                      {(PermissionService.hasPermission('categoryHeader-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/categoryHeader-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('categoryHeader-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/categoryHeader-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </Layout >
  );
};

export default CategoryHeaders;
