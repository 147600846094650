import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { eventStoreAction } from '../../actions/eventActions'
import { StyledDropZone } from 'react-drop-zone'
import 'react-drop-zone/dist/styles.css'
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { format } from "date-fns";

const EventCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  const [expiredAt, setExpiredAt] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [banner, setBanner] = useState(null);
  const [error, setError] = useState(null);
  const eventStore = useSelector((state) => state.eventStore);

  const addEvent = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('expired_at', format(expiredAt, "yyyy-MM-dd HH:mm:ss"));
    data.append('link', link);
    data.append('file', banner);

    if (isActive)
      data.append('is_active', true)
    else
      data.append('is_active', false)
    dispatch(eventStoreAction(navigate, data));
  };

  useEffect(() => {
    if (eventStore.result) {
      context.loading = false;
      if (eventStore.result.status !== 200)
        setError(eventStore.result.data.message);
    }
  }, [eventStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (link === "") setError("Enter Link is required!");
    else if (expiredAt === "") setError("Enter Expired At is required!");
    else if (banner === null) setError("Upload Banner is required!");
    else {
      addEvent();
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Event</h4> &nbsp;
            <Link className="btn btn-info" to="/events">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              <StyledDropZone
                onDrop={(file, text) => setBanner(file)}
              />

              {banner ? (
                <td className="text-success">Selected File: {banner.name}</td>
              ) : (
                <td className="text-danger">No file selected yet</td>
              )}

              <br />

              <Form.Group className="mb-3">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  type="text"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  placeholder="Enter Link..."
                />
              </Form.Group>

              <Form.Label>Expired At</Form.Label>
              <DateTimePicker
                className='form-control'
                minDate={new Date()}
                maxDetail="second"
                format="y-MM-dd hh:mm:ss a"
                onChange={setExpiredAt}
                value={expiredAt}
              />

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default EventCreate;
