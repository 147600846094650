import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { postDetailAction, postDeleteMediaAction } from '../../actions/postActions'
import http from '../../api'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";
import PermissionService from "../../Services/PermissionService";


const PostDetail = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [status, setStatus] = useState('');
  const [rank, setRank] = useState('');
  const [viewCount, setViewCount] = useState('');
  const [img, setImg] = useState([]);
  const [error, setError] = useState(null);
  const postDetail = useSelector((state) => state.postDetail);
  const postDeleteMedia = useSelector((state) => state.postDeleteMedia);

  useEffect(() => {
    getPost();
  }, []);

  const getPost = () => {
    context.loading = true;
    setError(null);
    setImg([]);
    dispatch(postDetailAction(navigate, id));
  };

  useEffect(() => {
    if (postDetail.result) {
      setTitle(postDetail.result.title);
      setFullName(postDetail.result.fullName);
      if (postDetail.result.userName)
        setUserName(postDetail.result.userName);
      else
        setUserName('');
      if (postDetail.result.rank)
        setRank(postDetail.result.rank);
      else
        setRank('');
      if (postDetail.result.viewCount)
        setViewCount(postDetail.result.viewCount);
      else
        setViewCount('');
      setImg(postDetail.result.img);
      setStatus(postDetail.result.status);
      context.loading = false;
    }
  }, [postDetail])

  const deleteMedia = (id) => {
    context.loading = true;
    setError(null);
    console.log(id);
    const data = {
      file_id: id,
    };
    dispatch(postDeleteMediaAction(navigate, data));
  };

  useEffect(() => {
    if (postDeleteMedia.result) {
      context.loading = false;
      getPost();
    }
  }, [postDeleteMedia])

  const handleDeleteMedia = (event, id) => {
    event.preventDefault();
    Swal.fire({
      title: "Delete Media",
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "gray",
      confirmButtonText: "YES, DELETE!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMedia(id);
      }
    });
  };

  const changeStatus = (value) => {
    setStatus(value);
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Post Details</h4> &nbsp;
            <Link className="btn btn-info" to="/posts">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  disabled
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>View Count</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={viewCount}
                    onChange={(e) => setViewCount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Rank</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={rank}
                    onChange={(e) => setRank(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="input-group justify-content-around">
                <Form.Check
                  inline
                  label="Waiting"
                  type='radio'
                  disabled
                  style={{ color: 'orange' }}
                  checked={status === 'waiting' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='waiting'
                />
                <Form.Check
                  inline
                  label="Accept"
                  type='radio'
                  disabled
                  style={{ color: 'green' }}
                  checked={status === 'accept' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='accept'
                />
                <Form.Check
                  inline
                  label="Reject"
                  type='radio'
                  disabled
                  style={{ color: 'red' }}
                  checked={status === 'reject' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='reject'
                />
                <Form.Check
                  inline
                  label="Remove"
                  type='radio'
                  disabled
                  style={{ color: 'blue' }}
                  checked={status === 'remove' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='remove'
                />
              </div>

              <div style={{ textAlign: '-webkit-center' }}>
                <Carousel
                  centerMode
                  width={'50%'}
                >
                  {img.map((item) => (
                    <div>
                      <Image
                        style={{
                          width: '100%',
                          height: 'fit-content',
                          padding: 10
                        }}
                        src={http.assetUrl + '/' + item.name}
                      />
                      {(PermissionService.hasPermission('post-delete-media', context.permissions)) ? (
                        <Button
                          className="legend"
                          onClick={(e) => handleDeleteMedia(e, item.id)}
                          size="lg"
                          variant="danger"
                          type="submit"
                        >
                          Delete
                        </Button>
                      ) : ('')}
                    </div>
                  ))}
                </Carousel>
              </div>

              {(PermissionService.hasPermission('post-edit', context.permissions)) ? (
                <Link
                  className="btn btn-warning"
                  to={`/post-edit/${id}`}
                >
                  Go To Edit
                </Link>
              ) : ('')}
              &nbsp;
              {(PermissionService.hasPermission('post-delete', context.permissions)) ? (
                <Link
                  className="btn btn-danger"
                  to={`/post-delete/${id}`}
                >
                  Go To Delete
                </Link>
              ) : ('')}

            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default PostDetail;
