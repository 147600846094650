import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const posts = async (navigate, page, search, status) => {
    try {
        var result = null;
        if (search && !status) {
            result = await http.axios.get(UrlServices.getPostsApi() + '/filter/' + search + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else if (!search && status) {
            result = await http.axios.get(UrlServices.getPostsApi() + '/filter/null/' + status + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else if (search && status) {
            result = await http.axios.get(UrlServices.getPostsApi() + '/filter/' + search + '/' + status + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else {
            result = await http.axios.get(UrlServices.getPostsApi() + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const postDetail = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getPostsApi() + "/detail/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const postDeleteMedia = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getPostsApi() + "/deleteMedia", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const postEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getPostsApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const postUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getPostsApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/posts");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const postDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getPostsApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/posts");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}