import { LanguageDelete, LanguageEdit, Languages, LanguageStore, LanguageUpdate } from "../actions/actionTypes";

export const languagesReducer = (state = {}, action) => {
    switch (action.type) {
        case Languages:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const languageStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case LanguageStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const languageEditReducer = (state = {}, action) => {
    switch (action.type) {
        case LanguageEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const languageUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case LanguageUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const languageDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case LanguageDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};