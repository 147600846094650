import { VipAdDelete, VipAdEdit, VipAds, VipAdUpdate } from "../actions/actionTypes";

export const vipAdsReducer = (state = {}, action) => {
    switch (action.type) {
        case VipAds:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const vipAdEditReducer = (state = {}, action) => {
    switch (action.type) {
        case VipAdEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const vipAdUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case VipAdUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const vipAdDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case VipAdDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};