import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { appUserBlockAction } from '../../actions/appUserActions'

const AppUserBlock = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, blocked, reason } = useParams();
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockedReason, setBlockedReason] = useState('');
  const [error, setError] = useState(null);
  const appUserBlock = useSelector((state) => state.appUserBlock);

  useEffect(() => {
    if (blocked == 0)
      setIsBlocked(false);
    else
      setIsBlocked(true);
    if (reason != 'null')
      setBlockedReason(reason);
    else
      setBlockedReason('');
  }, [])

  const changeBlock = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('user_id', id);
    data.append('is_blocked', isBlocked);
    data.append('blocked_reason', blockedReason);
    dispatch(appUserBlockAction(navigate, data));
  };

  useEffect(() => {
    if (appUserBlock.result) {
      context.loading = false;
      if (appUserBlock.result.status !== 200)
        setError(appUserBlock.result.data.message);
    }
  }, [appUserBlock])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isBlocked && blockedReason === '') setError("Blocked Reason is required!");
    else
      changeBlock();
  };

  const handleChangeIsBlocked = () => {
    setIsBlocked(!isBlocked);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Change User Block</h4> &nbsp;
            <Link className="btn btn-info" to="/appUsers">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              <Form.Group className="m-3">
                <Form.Check
                  className="form-control"
                  type="checkbox"
                  style={{ border: 'none' }}
                  onChange={handleChangeIsBlocked}
                  label="Blocked"
                  checked={isBlocked}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Blocked Reason</Form.Label>
                <Form.Control
                  type="text"
                  value={blockedReason}
                  onChange={(e) => setBlockedReason(e.target.value)}
                  placeholder="Enter Reason..."
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default AppUserBlock;
