import { CategoryCreate, CategoryDelete, CategoryEdit, Categories, CategoryStore, CategoryUpdate } from "../actions/actionTypes";

export const categoriesReducer = (state = {}, action) => {
    switch (action.type) {
        case Categories:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryEditReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};