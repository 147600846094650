import { ReportedUsers, ReportedUserDelete, ReportedAds, ReportedAdDelete, ReportedPosts, ReportedPostDelete } from "../actions/actionTypes";

export const reportedUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case ReportedUsers:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const reportedUserDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ReportedUserDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const reportedAdsReducer = (state = {}, action) => {
    switch (action.type) {
        case ReportedAds:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const reportedAdDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ReportedAdDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const reportedPostsReducer = (state = {}, action) => {
    switch (action.type) {
        case ReportedPosts:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const reportedPostDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ReportedPostDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};