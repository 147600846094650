import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { categoryHeaderCreateAction, categoryHeaderStoreAction } from '../../actions/categoryHeaderActions'

const CategoryHeaderCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [langs, setLangs] = useState([]);
  const [title, setTitle] = useState("");
  const [otherTitle, setOtherTitle] = useState([]);
  const [error, setError] = useState(null);
  const categoryHeaderCreate = useSelector((state) => state.categoryHeaderCreate);
  const categoryHeaderStore = useSelector((state) => state.categoryHeaderStore);

  useEffect(() => {
    getLangs();
  }, []);

  const getLangs = () => {
    context.loading = true;
    setError(null);
    dispatch(categoryHeaderCreateAction(navigate));
  };


  useEffect(() => {
    if (categoryHeaderCreate.result) {
      context.loading = false;
      setLangs(categoryHeaderCreate.result);
    }
  }, [categoryHeaderCreate])

  const addHeader = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('title', title);
    otherTitle.map((item) => {
      data.append('other_title[' + item.code + ']', item.value);
    });

    dispatch(categoryHeaderStoreAction(navigate, data));
  };

  useEffect(() => {
    if (categoryHeaderStore.result) {
      context.loading = false;
      if (categoryHeaderStore.result.status !== 200)
        setError(categoryHeaderStore.result.data.message);
    }
  }, [categoryHeaderStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title in English is required!");
    else {
      addHeader();
    }
  };

  const changeOtherTitle = (code, value) => {
    var lastOtherTitle = otherTitle.find((i) => i.code === code);
    if (lastOtherTitle) {
      let copyOtherTitle = otherTitle.filter((i) => i.code !== code);
      if (value)
        copyOtherTitle.push({ code: code, value: value });
      setOtherTitle(copyOtherTitle);
    }
    else {
      setOtherTitle((prev) => ([...prev, { code: code, value: value }]));
    }
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Header (Group)</h4> &nbsp;
            <Link className="btn btn-info" to="/categoryHeaders">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title in English</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title in English..."
                />
              </Form.Group>

              {langs.map((item) => (
                <Form.Group className="mb-3" key={item.id}>
                  <Form.Label>Title ({item.title})</Form.Label>
                  <Form.Control
                    type="text"
                    value={otherTitle.find((i) => i.code === item.code) ?
                      otherTitle.find((i) => i.code === item.code)['value'] : ''
                    }
                    onChange={(e) => changeOtherTitle(item.code, e.target.value)}
                    placeholder={`Enter Title in ${item.title} ...`}
                  />
                </Form.Group>
              ))}

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CategoryHeaderCreate;
