import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { planCreateAction, planStoreAction } from '../../actions/planActions'
import { HexColorPicker } from "react-colorful";

const PlanCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [langs, setLangs] = useState([]);
  const [title, setTitle] = useState("");
  const [otherTitle, setOtherTitle] = useState([]);
  const [color, setColor] = useState('');
  const [period, setPeriod] = useState(0);
  const [price, setPrice] = useState(0.0);
  const [validPostCount, setValidPostCount] = useState(null);
  const [validAdCount, setValidAdCount] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState(null);
  const planCreate = useSelector((state) => state.planCreate);
  const planStore = useSelector((state) => state.planStore);

  useEffect(() => {
    getLangs();
  }, []);

  const getLangs = () => {
    context.loading = true;
    setError(null);
    dispatch(planCreateAction(navigate));
  };


  useEffect(() => {
    if (planCreate.result) {
      context.loading = false;
      setLangs(planCreate.result);
    }
  }, [planCreate])

  const addPlan = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('title', title);
    data.append('color', color);
    data.append('period', period);
    data.append('price', price);
    otherTitle.map((item) => {
      data.append('other_title[' + item.code + ']', item.value);
    });
    if (validPostCount || validPostCount == 0)
      data.append('valid_post_count', validPostCount);
    if (validAdCount || validAdCount == 0)
      data.append('valid_ad_count', validAdCount);

    if (isActive)
      data.append('is_active', true)
    else
      data.append('is_active', false)
    dispatch(planStoreAction(navigate, data));
  };

  useEffect(() => {
    if (planStore.result) {
      context.loading = false;
      if (planStore.result.status !== 200)
        setError(planStore.result.data.message);
    }
  }, [planStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title in English is required!");
    else if (color === "") setError("Enter Color is required!");
    else if (period === "") setError("Enter Period is required!");
    else if (price === "") setError("Enter Price is required!");
    else {
      addPlan();
    }
  };

  const changeOtherTitle = (code, value) => {
    var lastOtherTitle = otherTitle.find((i) => i.code === code);
    if (lastOtherTitle) {
      let copyOtherTitle = otherTitle.filter((i) => i.code !== code);
      if (value)
        copyOtherTitle.push({ code: code, value: value });
      setOtherTitle(copyOtherTitle);
    }
    else {
      setOtherTitle((prev) => ([...prev, { code: code, value: value }]));
    }
  }

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Plan</h4> &nbsp;
            <Link className="btn btn-info" to="/plans">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title in English</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title in English..."
                />
              </Form.Group>

              {langs.map((item) => (
                <Form.Group className="mb-3" key={item.id}>
                  <Form.Label>Title ({item.title})</Form.Label>
                  <Form.Control
                    type="text"
                    value={otherTitle.find((i) => i.code === item.code) ?
                      otherTitle.find((i) => i.code === item.code)['value'] : ''
                    }
                    onChange={(e) => changeOtherTitle(item.code, e.target.value)}
                    placeholder={`Enter Title in ${item.title} ...`}
                  />
                </Form.Group>
              ))}

              <Form.Group className="mb-3">
                <Form.Label>Color</Form.Label>
                <HexColorPicker color={color} onChange={setColor} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Period (Days)</Form.Label>
                <Form.Control
                  type="number"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  placeholder="Enter Period in Days..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder="Enter Price in Dollars..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Post Count (Empty meaning Unlimited)</Form.Label>
                <Form.Control
                  type="text"
                  value={validPostCount}
                  onChange={(e) => setValidPostCount(e.target.value)}
                  placeholder="Enter Post Count..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ad Count (Empty meaning Unlimited)</Form.Label>
                <Form.Control
                  type="text"
                  value={validAdCount}
                  onChange={(e) => setValidAdCount(e.target.value)}
                  placeholder="Enter Ad Count..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default PlanCreate;
