import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { languageEditAction, languageDeleteAction } from '../../actions/languageActions'

const LanguageDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [isRtl, setIsRtl] = useState(false);
  const [error, setError] = useState(null);
  const languageEdit = useSelector((state) => state.languageEdit);
  const languageDelete = useSelector((state) => state.languageDelete);

  useEffect(() => {
    getLanguage();
  }, []);

  const getLanguage = () => {
    context.loading = true;
    setError(null);
    dispatch(languageEditAction(navigate, id));
  };

  useEffect(() => {
    if (languageEdit.result) {
      context.loading = false;
      setTitle(languageEdit.result["title"]);
      setCode(languageEdit.result["code"]);
      if (languageEdit.result["is_rtl"])
        setIsRtl(true);
      else
        setIsRtl(false);
    }
  }, [languageEdit])

  const deleteLanguage = () => {
    context.loading = true;
    setError(null);
    dispatch(languageDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (languageDelete.result) {
      context.loading = false;
      if (languageDelete.result.status !== 200)
        setError(languageDelete.result.data.message);
    }
  }, [languageDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else if (code === "") setError("Enter Code is required!");
    else {
      deleteLanguage();
    }
  };

  const handleChangeIsRtl = () => {
    setIsRtl(!isRtl);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Language: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-danger">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/languages">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Language Title</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Language Code</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter Code..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  disabled
                  className="form-control"
                  type="checkbox"
                  onChange={handleChangeIsRtl}
                  label="RTL"
                  checked={isRtl}
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default LanguageDelete;
