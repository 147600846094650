import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { appUserNotificationAction } from '../../actions/appUserActions'

const AppUserNotification = () => {
    const context = useContext(SimpleContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const appUserNotification = useSelector((state) => state.appUserNotification);

    useEffect(() => {
        setTitle('');
        setText('');
    }, [])

    const sendNotification = () => {
        context.loading = true;
        setError(null);
        const data = new FormData();
        data.append('user_id', id);
        data.append('title', title);
        data.append('text', text);
        dispatch(appUserNotificationAction(navigate, data));
    };

    useEffect(() => {
        if (appUserNotification.result) {
            context.loading = false;
            if (appUserNotification.result.status !== 200)
                setError(appUserNotification.result.data.message);
        }
    }, [appUserNotification])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (title === '') setError("Title is required!");
        else if (text === '') setError("Text is required!");
        else
            sendNotification();
    };

    return (
        <Layout>
            <div>
                <Card>
                    <div className="input-group p-3">
                        <h4>Send Notification To User</h4> &nbsp;
                        <Link className="btn btn-info" to="/appUsers">
                            Back
                        </Link>
                    </div>
                    {error ? (
                        <div onClick={() => setError(null)}>
                            <div className="alert alert-danger m-3">{error}</div>
                        </div>
                    ) : (
                        ""
                    )}
                    <Card.Body>
                        <Form>

                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter Title..."
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    placeholder="Enter Text..."
                                />
                            </Form.Group>

                            <Button
                                onClick={handleSubmit}
                                size="lg"
                                variant="success"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    );
};

export default AppUserNotification;
