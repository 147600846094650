class AuthHeader {
  getApiUrl() {
    return process.env.REACT_APP_FINAL_API_URL;
  }

  getAssetsUrl() {
    return process.env.REACT_APP_FINAL_ASSETS_URL;
  }

  getHeader() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      return { Authorization: "Bearer " + user.access_token };
    } else {
      return {};
    }
  }

  getMultiPartHeader() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.access_token) {
      return {
        Authorization: "Bearer " + user.access_token,
        "Content-type": "multipart/form-data",
      };
    } else {
      return {};
    }
  }
}

export default new AuthHeader();
