import { CurrencyUnitDelete, CurrencyUnitEdit, CurrencyUnits, CurrencyUnitStore, CurrencyUnitUpdate } from "../actions/actionTypes";

export const currencyUnitsReducer = (state = {}, action) => {
    switch (action.type) {
        case CurrencyUnits:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const currencyUnitStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CurrencyUnitStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const currencyUnitEditReducer = (state = {}, action) => {
    switch (action.type) {
        case CurrencyUnitEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const currencyUnitUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CurrencyUnitUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const currencyUnitDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CurrencyUnitDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};