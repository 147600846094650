import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { adDetailAction, adDeleteMediaAction } from '../../actions/adActions'
import http from '../../api'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";
import PermissionService from "../../Services/PermissionService";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { format } from "date-fns";


const AdDetail = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [fullName, setFullName] = useState('');
  const [userName, setUserName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [rank, setRank] = useState('');
  const [viewCount, setViewCount] = useState('');
  const [img, setImg] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);
  const adDetail = useSelector((state) => state.adDetail);
  const adDeleteMedia = useSelector((state) => state.adDeleteMedia);

  useEffect(() => {
    getAd();
  }, []);

  const getAd = () => {
    context.loading = true;
    setError(null);
    setImg([]);
    dispatch(adDetailAction(navigate, id));
  };

  useEffect(() => {
    if (adDetail.result) {
      setCategories(adDetail.result.ad.categories);
      setSelectCategory(adDetail.result.categories.filter((item) => item.id == adDetail.result.ad.category.id))
      setFullName(adDetail.result.ad.fullName);
      if (adDetail.result.ad.userName)
        setUserName(adDetail.result.ad.userName);
      else
        setUserName('');
      if (adDetail.result.ad.title)
        setTitle(adDetail.result.ad.title);
      else
        setTitle('');
      if (adDetail.result.ad.description)
        setDescription(adDetail.result.ad.description);
      else
        setDescription('');
      if (adDetail.result.ad.rank)
        setRank(adDetail.result.ad.rank);
      else
        setRank('');
      if (adDetail.result.ad.viewCount)
        setViewCount(adDetail.result.ad.viewCount);
      else
        setViewCount('');
      setImg(adDetail.result.ad.img);
      setStatus(adDetail.result.ad.status);
      setFilters(adDetail.result.ad.filters);
      context.loading = false;
    }
  }, [adDetail])

  const deleteMedia = (id) => {
    context.loading = true;
    setError(null);
    const data = {
      file_id: id,
    };
    dispatch(adDeleteMediaAction(navigate, data));
  };

  useEffect(() => {
    if (adDeleteMedia.result) {
      context.loading = false;
      getAd();
    }
  }, [adDeleteMedia])

  const handleDeleteMedia = (event, id) => {
    event.preventDefault();
    Swal.fire({
      title: "Delete Media",
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "gray",
      confirmButtonText: "YES, DELETE!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMedia(id);
      }
    });
  };

  const changeStatus = (value) => {
    setStatus(value);
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Ad Details</h4> 
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{title}</h4>
            &nbsp;
            &nbsp;
            <Link className="btn btn-info" to="/ads">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="title"
                  disabled
                  onChange={setSelectCategory}
                  options={categories}
                  placeholder="Choose a Category..."
                  selected={selectCategory}
                />
              </Form.Group>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-2 col-lg-12">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-12">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </div>

              <div className="input-group justify-content-around">
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>View Count</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={viewCount}
                    onChange={(e) => setViewCount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-5 col-lg-5">
                  <Form.Label>Rank</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={rank}
                    onChange={(e) => setRank(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="input-group justify-content-around">
                <Form.Check
                  inline
                  label="Waiting"
                  type='radio'
                  disabled
                  style={{ color: 'orange' }}
                  checked={status === 'waiting' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='waiting'
                />
                <Form.Check
                  inline
                  label="Accept"
                  type='radio'
                  disabled
                  style={{ color: 'green' }}
                  checked={status === 'accept' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='accept'
                />
                <Form.Check
                  inline
                  label="Reject"
                  type='radio'
                  disabled
                  style={{ color: 'red' }}
                  checked={status === 'reject' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='reject'
                />
                <Form.Check
                  inline
                  label="Remove"
                  type='radio'
                  disabled
                  style={{ color: 'blue' }}
                  checked={status === 'remove' ? true : false}
                  onChange={e => changeStatus(e.target.id)}
                  id='remove'
                />
              </div>

              <hr />
              <div>
                <h3 className="m-3">Filters:</h3>
                {filters.map((item) => (
                  <div key={item.id}>
                    <Form.Group className="mb-2 col-lg-12">
                      <Form.Label>{item.type.title != 'bool' ? item.title : ''}</Form.Label>
                      {item.type.title == 'select' ? (
                        <Form.Control
                          type="text"
                          disabled
                          value={item.value}
                        />
                      ) : ('')}
                      {item.type.title == 'date' ? (
                        <Form.Control
                          type="text"
                          disabled
                          value={format(new Date(item.value), "yyyy-MM-dd")}
                        />
                      ) : ('')}
                      {item.type.title == 'dateTime' ? (
                        <Form.Control
                          type="text"
                          disabled
                          value={format(new Date(item.value), "yyyy-MM-dd HH:mm:ss")}
                        />
                      ) : ('')}
                      {item.type.title == 'text' ? (
                        <Form.Control
                          type="text"
                          disabled
                          value={item.value}
                        />
                      ) : ('')}
                      {item.type.title == 'number' ? (
                        <Form.Control
                          type="text"
                          disabled
                          value={item.value}
                        />
                      ) : ('')}
                      {item.type.title == 'bool' ? (
                        <Form.Check
                          type="checkbox"
                          key={item.value}
                          disabled
                          checked
                          value={item.value}
                          label={item.title}
                        />
                      ) : ('')}
                    </Form.Group>
                  </div>
                ))}
              </div>
              <hr />
              <div style={{ textAlign: '-webkit-center' }}>
                <Carousel
                  centerMode
                  width={'50%'}
                >
                  {img.map((item) => (
                    <div>
                      <Image
                        style={{
                          width: '100%',
                          height: 'fit-content',
                          padding: 10
                        }}
                        src={http.assetUrl + '/' + item.name}
                      />
                      {(PermissionService.hasPermission('ad-delete-media', context.permissions)) ? (
                        <Button
                          className="legend"
                          onClick={(e) => handleDeleteMedia(e, item.id)}
                          size="lg"
                          variant="danger"
                          type="submit"
                        >
                          Delete
                        </Button>
                      ) : ('')}
                    </div>
                  ))}
                </Carousel>
              </div>

              {(PermissionService.hasPermission('ad-edit', context.permissions)) ? (
                <Link
                  className="btn btn-warning"
                  to={`/ad-edit/${id}`}
                >
                  Go To Edit
                </Link>
              ) : ('')}
              &nbsp;
              {(PermissionService.hasPermission('ad-delete', context.permissions)) ? (
                <Link
                  className="btn btn-danger"
                  to={`/ad-delete/${id}`}
                >
                  Go To Delete
                </Link>
              ) : ('')}

            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default AdDetail;
