import { AppUserCreate, AppUserDelete, AppUserEdit, AppUsers, AppUserUpdate, AppUserChangePlan, AppUserBlock, AppUserNotification, AppUserNotificationAll } from "../actions/actionTypes";

export const appUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUsers:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserEditReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserChangePlanReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserChangePlan:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserBlockReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserBlock:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserNotificationReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserNotification:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const appUserNotificationAllReducer = (state = {}, action) => {
    switch (action.type) {
        case AppUserNotificationAll:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};