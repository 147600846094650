import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { roleCreateAction, roleStoreAction } from '../../actions/roleActions'

const RoleCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState([]);
  const [selectPermissions, setSelectPermissions] = useState([]);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const roleCreate = useSelector((state) => state.roleCreate);
  const roleStore = useSelector((state) => state.roleStore);

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    context.loading = true;
    setError(null);
    dispatch(roleCreateAction(navigate));
  };


  useEffect(() => {
    if (roleCreate.result) {
      context.loading = false;
      setPermissions(roleCreate.result);
    }
  }, [roleCreate])

  const handleChangePermissions = (e) => {
    if (e.target.checked) {
      const p = selectPermissions;
      p.push(e.target.value);
      setSelectPermissions(p);
    } else {
      setSelectPermissions(
        selectPermissions.filter((m) => m != e.target.value)
      );
    }
  };

  const addRole = () => {
    context.loading = true;
    setError(null);
    const data = {
      name: name,
      permission: selectPermissions,
    };
    dispatch(roleStoreAction(navigate, data));
  };

  useEffect(() => {
    if (roleStore.result) {
      context.loading = false;
      if (roleStore.result.status !== 200)
        setError(roleStore.result.data.message);
    }
  }, [roleStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === "") setError("Enter Role Name is required!");
    else {
      addRole();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Role</h4> &nbsp;
            <Link className="btn btn-info" to="/roles">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Role Name..."
                />
              </Form.Group>

              <div className="" style={{ textAlignLast: "left" }}>
                <Form.Group className="mb-3">
                  {permissions.map((item, i) => (
                    <Form.Check
                      type="checkbox"
                      onClick={handleChangePermissions}
                      key={item.id}
                      value={item.id}
                      label={item.name}
                    />
                  ))}
                </Form.Group>
              </div>
              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default RoleCreate;
