import { createContext } from "react";

const SimpleContext = createContext({
    token: "",
    user: "",
    roles: "",
    permissions: "",
    status: false,
    loading: false,
});

export default SimpleContext;