import { CategoryFilterCreate, CategoryFilterDelete, CategoryFilterEdit, CategoryFilters, CategoryFilterStore, CategoryFilterUpdate } from "../actions/actionTypes";

export const categoryFiltersReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryFilters:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryFilterCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryFilterCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryFilterStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryFilterStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryFilterEditReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryFilterEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryFilterUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryFilterUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryFilterDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryFilterDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};