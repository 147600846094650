import axios from "axios";

var url = "";
var assetUrl = "";

url = process.env.REACT_APP_FINAL_API_URL;
assetUrl = process.env.REACT_APP_FINAL_ASSETS_URL;

axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.headers.get["Content-Type"] = "multipart/form-data";
axios.defaults.headers.get["Accept"] = "application/json";

axios.defaults.timeout = 600000;

export default {
    get: axios.get,
    post: axios.post,
    url,
    assetUrl,
    axios,
};