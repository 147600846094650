import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Image, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { eventsAction } from '../../actions/eventActions';
import { Pagination } from "@mui/material";
import http from '../../api'

const Events = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const events = useSelector((state) => state.events);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(eventsAction(navigate, page));
  };

  useEffect(() => {
    if (events.result) {
      context.loading = false;
      setPageCount(events.result["last_page"]);
      if (events.result.data) {
        setData(events.result.data);
      }
    }
  }, [events])

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Events</h4> &nbsp;
            {(PermissionService.hasPermission('event-create', context.permissions)) ? (
              <Link className="btn btn-info" to="/event-create">
                New Event
              </Link>
            ) : ('')}
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Banner</th>
                  <th>Expired At</th>
                  <th>Link</th>
                  <th>Is Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <Image style={{ width: 350 }} src={http.assetUrl + '/' + item.banner_path} />
                    </td>
                    <td>{item.expired_at}</td>
                    <td>{item.link}</td>
                    {item.is_active ? (
                      <td className="text-success">Active</td>
                    ) : (
                      <td className="text-danger">Not Active</td>
                    )}
                    <td>
                      {(PermissionService.hasPermission('event-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/event-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('event-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/event-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                      &nbsp;
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout >
  );
};

export default Events;
