import { CountryDelete, CountryEdit, Countries, CountryStore, CountryUpdate } from "../actions/actionTypes";

export const countriesReducer = (state = {}, action) => {
    switch (action.type) {
        case Countries:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const countryStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CountryStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const countryEditReducer = (state = {}, action) => {
    switch (action.type) {
        case CountryEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const countryUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CountryUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const countryDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CountryDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};