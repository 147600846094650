import { EventDelete, EventEdit, Events, EventStore, EventUpdate } from "./actionTypes";
import { eventDelete, eventEdit, events, eventStore, eventUpdate } from '../api/events';

export const eventsAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: Events, payload: null });
        events(navigate, page).then(async (result) => {
            await dispatch({ type: Events, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Events, payload: error });
        });
    };
};

export const eventStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: EventStore, payload: null });
        eventStore(navigate, data).then(async (result) => {
            await dispatch({ type: EventStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: EventStore, payload: error });
        });
    };
};

export const eventEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: EventEdit, payload: null });
        eventEdit(navigate, id).then(async (result) => {
            await dispatch({ type: EventEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: EventEdit, payload: error });
        });
    };
};

export const eventUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: EventUpdate, payload: null });
        eventUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: EventUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: EventUpdate, payload: error });
        });
    };
};

export const eventDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: EventDelete, payload: null });
        eventDelete(navigate, id).then(async (result) => {
            await dispatch({ type: EventDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: EventDelete, payload: error });
        });
    };
};