import { RoleCreate, RoleDelete, RoleEdit, Roles, RoleStore, RoleUpdate } from "../actions/actionTypes";

export const rolesReducer = (state = {}, action) => {
    switch (action.type) {
        case Roles:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const roleCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case RoleCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const roleStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case RoleStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const roleEditReducer = (state = {}, action) => {
    switch (action.type) {
        case RoleEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const roleUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case RoleUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const roleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case RoleDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};