import React, { useContext, useEffect, useState } from "react";
import Layout from "./Shared/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import SimpleContext from "../Contexts/SimpleContext";
import PermissionService from '../Services/PermissionService';
import { dashboardsAction, dashboardByDateAction } from '../actions/dashboardActions'
import { useNavigate } from "react-router-dom";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { format } from "date-fns";
import { FaUsers, FaCameraRetro, FaAd, FaDollarSign, FaHouseUser, FaHospitalUser } from "react-icons/fa";


const Home = () => {

  const [data, setData] = useState(null);
  const [dateSelect, setDateSelect] = useState('Today');
  const [period, setPeriod] = useState([new Date(), new Date()]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const dispatch = useDispatch();
  const context = useContext(SimpleContext);
  const navigate = useNavigate();

  const dashboards = useSelector((state) => state.dashboards);
  const dashboardByDate = useSelector((state) => state.dashboardByDate);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    context.loading = true;
    dispatch(dashboardsAction(navigate));
  }

  useEffect(() => {
    if (dashboards.result) {
      setData(dashboards.result.data);
      setDateSelect('Today');
      setFromDate(null);
      setToDate(null);
      context.loading = false;
    }
  }, [dashboards])

  useEffect(() => {
    if (period) {
      const startDate = format(period[0], "yyyy-MM-dd HH:mm:ss");
      const endDate = format(period[1], "yyyy-MM-dd HH:mm:ss");
      setFromDate(startDate);
      setToDate(endDate);
    }
  }, [period])

  useEffect(() => {
    if (fromDate && toDate) {
      context.loading = true;
      var formData = new FormData();
      formData.append('fromDate', fromDate);
      formData.append('toDate', toDate);
      dispatch(dashboardByDateAction(navigate, formData));
    }
  }, [fromDate, toDate])

  useEffect(() => {
    if (dashboardByDate.result) {
      setData(dashboardByDate.result.data);
      if (period)
        setDateSelect(format(period[0], "yyyy-MM-dd") + ' to ' + format(period[1], "yyyy-MM-dd"));
      context.loading = false;
    }
  }, [dashboardByDate])

  return (
    <Layout>
      <Card>
        <Row style={{ marginTop: 15 }}>
          <Col md={8}><h1 style={{ padding: 10 }} className="text-center text-info">Welcome to Can-Do admin panel</h1></Col>
          <Col md={2}><Image style={{ padding: 10 }} src="./logo.png" alt="Can-Do" width='100%' /></Col>
        </Row>
      </Card>
      {data && PermissionService.hasPermission("dashboard-report", context.permissions) ? (
        <>
          <Card style={{ marginTop: 15 }}>
            <Row style={{ padding: 10 }}>
              <Col md={4}>
                <DateRangePicker
                  className='form-control'
                  onChange={setPeriod}
                  value={period}
                  format="y-MM-dd"
                />
              </Col>
              <Col md={5} className="text-center"><h5>Report for: <h5 className="text-success">{dateSelect}</h5></h5> </Col>
              <Col md={3} className="text-center"><Button className="btn btn-lg btn-info" onClick={fetchData}>Today</Button></Col>
            </Row>
          </Card>
          <Row style={{ marginTop: 15 }}>
            <Col md={4}><Card style={{ padding: 10 }}><div className="input-group justify-content-around"><FaUsers size={75} /><div className="text-center"><h3 className="text-info">{data.usersCount} </h3><h3>Users</h3></div></div></Card></Col>
            <Col md={4}><Card style={{ padding: 10 }}><div className="input-group justify-content-around"><FaCameraRetro size={75} /><div className="text-center"><h3 className="text-info">{data.postsCount} </h3><h3>Posts</h3></div></div></Card></Col>
            <Col md={4}><Card style={{ padding: 10 }}><div className="input-group justify-content-around"><FaAd size={75} /><div className="text-center"><h3 className="text-info">{data.adsCount} </h3><h3>Ads</h3></div></div></Card></Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col md={4}><Card style={{ padding: 10 }}><div className="input-group justify-content-around"><FaHouseUser size={75} /><div className="text-center"><h3 className="text-info">{data.premiumUsersCount} </h3><h3>Premium Users</h3></div></div></Card></Col>
            <Col md={4}><Card style={{ padding: 10 }}><div className="input-group justify-content-around"><FaHospitalUser size={75} /><div className="text-center"><h3 className="text-info">{data.businessUsersCount} </h3><h3>Business Users</h3></div></div></Card></Col>
            <Col md={4}><Card style={{ padding: 10 }}><div className="input-group justify-content-around"><FaDollarSign size={75} /><div className="text-center"><h3 className="text-info">{data.salary} </h3><h3>Salary</h3></div></div></Card></Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col md={12}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 view Posts</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>View Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostViewPosts.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.viewCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col md={12}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 view Ads</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>View Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostViewAds.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.viewCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col md={6}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 view Profiles</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>View Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostViewProfiles.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.first_name + ' ' + item.last_name}</td>
                        <td>{item.viewCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 view Categories</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostViewCategories.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col md={12}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 active Users</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Posts Count</th>
                      <th>Ads Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostActiveUsers.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.first_name + ' ' + item.last_name}</td>
                        <td>{item.postCount}</td>
                        <td>{item.adCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col md={4}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 Posts Location</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostLocationPosts.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 Ads Location</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostLocationAds.map((item, index) => (
                      <tr key={index + 100}>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Form.Label><h5 className="text-center" style={{ padding: 10 }}>Top 5 Application Install</h5></Form.Label>
                <Table className="text-center" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.mostLocationInstall.map((item, index) => (
                      <tr key={index + 100000}>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>

        </>
      ) : ('')}
    </Layout>
  );
}

export default Home;
