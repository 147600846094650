import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { plansAction } from '../../actions/planActions';
import { Pagination } from "@mui/material";

const Plans = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [langs, setLangs] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const plans = useSelector((state) => state.plans);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(plansAction(navigate, page));
  };

  useEffect(() => {
    if (plans.result) {
      context.loading = false;
      setPageCount(plans.result["last_page"]);
      if (plans.result.plans.data) {
        setData(plans.result.plans.data);
        setLangs(plans.result.langs);
      }
    }
  }, [plans])

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Plans</h4> &nbsp;
            {(PermissionService.hasPermission('plan-create', context.permissions)) ? (
              <Link className="btn btn-info" to="/plan-create">
                New Plan
              </Link>
            ) : ('')}
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  {langs.map((item) => (
                    <th key={item.id}>{`Title (${item.title})`}</th>
                  ))}
                  <th>Color</th>
                  <th>Period (days)</th>
                  <th>price ($)</th>
                  <th>Post Count</th>
                  <th>Ad Count</th>
                  <th>Is Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    {item.translations.map((t, i) => (
                      <td key={i} > {t.title}</td>
                    ))}
                    <td style={{ backgroundColor: item.color }}></td>
                    <td>{item.period} Days</td>
                    <td>{item.price} $</td>
                    {item.valid_post_count != null ? (
                      <td>{item.valid_post_count}</td>
                    ) : (
                      <td className="text-danger">Unlimited</td>
                    )}
                    {item.valid_ad_count != null ? (
                      <td>{item.valid_ad_count}</td>
                    ) : (
                      <td className="text-danger">Unlimited</td>
                    )}
                    {item.is_active ? (
                      <td className="text-success">Active</td>
                    ) : (
                      <td className="text-danger">Not Avtive</td>
                    )}

                    <td>
                      {(PermissionService.hasPermission('plan-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/plan-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('plan-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/plan-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('plan-list', context.permissions)) ? (
                        <Link
                          className="btn btn-success"
                          to={`/planOptions/${item.id}`}
                        >
                          Options
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout >
  );
};

export default Plans;
