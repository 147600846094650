import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { planOptionCreateAction, planOptionStoreAction } from '../../actions/planOptionActions'

const PlanOptionCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pId } = useParams();
  const [langs, setLangs] = useState([]);
  const [text, setText] = useState("");
  const [otherTitle, setOtherTitle] = useState([]);
  const [error, setError] = useState(null);
  const planOptionCreate = useSelector((state) => state.planOptionCreate);
  const planOptionStore = useSelector((state) => state.planOptionStore);

  useEffect(() => {
    getLangs();
  }, []);

  const getLangs = () => {
    context.loading = true;
    setError(null);
    dispatch(planOptionCreateAction(navigate));
  };


  useEffect(() => {
    if (planOptionCreate.result) {
      context.loading = false;
      setLangs(planOptionCreate.result);
    }
  }, [planOptionCreate])

  const addPlanOption = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('plan_id', pId);
    data.append('text', text);
    otherTitle.map((item) => {
      data.append('other_title[' + item.code + ']', item.value);
    });
    dispatch(planOptionStoreAction(navigate, data, pId));
  };

  useEffect(() => {
    if (planOptionStore.result) {
      context.loading = false;
      if (planOptionStore.result.status !== 200)
        setError(planOptionStore.result.data.message);
    }
  }, [planOptionStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (text === "") setError("Enter Text in English is required!");
    else {
      addPlanOption();
    }
  };

  const changeOtherTitle = (code, value) => {
    var lastOtherTitle = otherTitle.find((i) => i.code === code);
    if (lastOtherTitle) {
      let copyOtherTitle = otherTitle.filter((i) => i.code !== code);
      if (value)
        copyOtherTitle.push({ code: code, value: value });
      setOtherTitle(copyOtherTitle);
    }
    else {
      setOtherTitle((prev) => ([...prev, { code: code, value: value }]));
    }
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Option</h4> &nbsp;
            <Link className="btn btn-info" to={`/planOptions/${pId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Text in English</Form.Label>
                <Form.Control
                  type="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Enter Title in English..."
                />
              </Form.Group>

              {langs.map((item) => (
                <Form.Group className="mb-3" key={item.id}>
                  <Form.Label>Text ({item.title})</Form.Label>
                  <Form.Control
                    type="text"
                    value={otherTitle.find((i) => i.code === item.code) ?
                      otherTitle.find((i) => i.code === item.code)['value'] : ''
                    }
                    onChange={(e) => changeOtherTitle(item.code, e.target.value)}
                    placeholder={`Enter Text in ${item.title} ...`}
                  />
                </Form.Group>
              ))}

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default PlanOptionCreate;
