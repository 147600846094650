import { CurrencyUnits, CurrencyUnitDelete, CurrencyUnitEdit, CurrencyUnitStore, CurrencyUnitUpdate } from "./actionTypes";
import { getCurrencyUnits, currencyUnitDelete, currencyUnitEdit, currencyUnitStore, currencyUnitUpdate } from '../api/currencyUnits';

export const currencyUnitsAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: CurrencyUnits, payload: null });
        getCurrencyUnits(navigate, page).then(async (result) => {
            await dispatch({ type: CurrencyUnits, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CurrencyUnits, payload: error });
        });
    };
};

export const currencyUnitStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CurrencyUnitStore, payload: null });
        currencyUnitStore(navigate, data).then(async (result) => {
            await dispatch({ type: CurrencyUnitStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CurrencyUnitStore, payload: error });
        });
    };
};

export const currencyUnitEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CurrencyUnitEdit, payload: null });
        currencyUnitEdit(navigate, id).then(async (result) => {
            await dispatch({ type: CurrencyUnitEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CurrencyUnitEdit, payload: error });
        });
    };
};

export const currencyUnitUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CurrencyUnitUpdate, payload: null });
        currencyUnitUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: CurrencyUnitUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CurrencyUnitUpdate, payload: error });
        });
    };
};

export const currencyUnitDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CurrencyUnitDelete, payload: null });
        currencyUnitDelete(navigate, id).then(async (result) => {
            await dispatch({ type: CurrencyUnitDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CurrencyUnitDelete, payload: error });
        });
    };
};