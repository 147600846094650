import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { provinceEditAction, provinceUpdateAction } from '../../actions/provinceActions'

const ProvinceEdit = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, cId } = useParams();
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const provinceEdit = useSelector((state) => state.provinceEdit);
  const provinceUpdate = useSelector((state) => state.provinceUpdate);

  useEffect(() => {
    getProvince();
  }, []);

  const getProvince = () => {
    context.loading = true;
    setError(null);
    dispatch(provinceEditAction(navigate, id));
  };

  useEffect(() => {
    if (provinceEdit.result) {
      context.loading = false;
      setTitle(provinceEdit.result["title"]);
    }
  }, [provinceEdit])

  const updateProvince = () => {
    context.loading = true;
    setError(null);
    const data = {
      id: id,
      title: title,
    };
    dispatch(provinceUpdateAction(navigate, data, cId));
  };

  useEffect(() => {
    if (provinceUpdate.result) {
      context.loading = false;
      if (provinceUpdate.result.status !== 200)
        setError(provinceUpdate.result.data.message);
    }
  }, [provinceUpdate])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else {
      updateProvince();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Edit Province: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to={`/provinces/${cId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Province Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="warning"
                type="submit"
              >
                Update
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default ProvinceEdit;
