import { PlanCreate, PlanDelete, PlanEdit, Plans, PlanStore, PlanUpdate } from "./actionTypes";
import { planCreate, planDelete, planEdit, plans, planStore, planUpdate } from '../api/plans';

export const plansAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: Plans, payload: null });
        plans(navigate, page).then(async (result) => {
            await dispatch({ type: Plans, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Plans, payload: error });
        });
    };
};

export const planCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: PlanCreate, payload: null });
        planCreate(navigate).then(async (result) => {
            await dispatch({ type: PlanCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanCreate, payload: error });
        });
    };
};

export const planStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: PlanStore, payload: null });
        planStore(navigate, data).then(async (result) => {
            await dispatch({ type: PlanStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanStore, payload: error });
        });
    };
};

export const planEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: PlanEdit, payload: null });
        planEdit(navigate, id).then(async (result) => {
            await dispatch({ type: PlanEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanEdit, payload: error });
        });
    };
};

export const planUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: PlanUpdate, payload: null });
        planUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: PlanUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanUpdate, payload: error });
        });
    };
};

export const planDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: PlanDelete, payload: null });
        planDelete(navigate, id).then(async (result) => {
            await dispatch({ type: PlanDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanDelete, payload: error });
        });
    };
};