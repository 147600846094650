class PermissionService {
    hasPermission(permissionName, permissions) {
        let has = false;
        permissions.map((item) => {
            if (item.name === permissionName) has = true;
        });
        return has;
    }
}

export default new PermissionService();