import { Languages, LanguageDelete, LanguageEdit, LanguageStore, LanguageUpdate } from "./actionTypes";
import { getLanguages, languageDelete, languageEdit, languageStore, languageUpdate } from '../api/languages';

export const languagesAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: Languages, payload: null });
        getLanguages(navigate, page).then(async (result) => {
            await dispatch({ type: Languages, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Languages, payload: error });
        });
    };
};

export const languageStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: LanguageStore, payload: null });
        languageStore(navigate, data).then(async (result) => {
            await dispatch({ type: LanguageStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: LanguageStore, payload: error });
        });
    };
};

export const languageEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: LanguageEdit, payload: null });
        languageEdit(navigate, id).then(async (result) => {
            await dispatch({ type: LanguageEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: LanguageEdit, payload: error });
        });
    };
};

export const languageUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: LanguageUpdate, payload: null });
        languageUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: LanguageUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: LanguageUpdate, payload: error });
        });
    };
};

export const languageDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: LanguageDelete, payload: null });
        languageDelete(navigate, id).then(async (result) => {
            await dispatch({ type: LanguageDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: LanguageDelete, payload: error });
        });
    };
};