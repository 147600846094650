import React, { useContext } from "react";
import SimpleContext from "./Contexts/SimpleContext";
import AppRoutes from "./AppRoutes";
import "./Style/addFont.css";
import { Provider } from "react-redux";
import { store } from '../src/store'

const App = () => {
    const context = useContext(SimpleContext);
    return (
        <Provider store={store}>
            <SimpleContext.Provider value={
                {
                    token: context.token,
                    user: context.user,
                    roles: context.roles,
                    permissions: context.permissions,
                    status: context.status,
                    loading: context.loading,
                }
            } >
                <AppRoutes />
            </SimpleContext.Provider>
        </Provider>
    );
};

export default App;