import { AdDetail, AdDelete, AdEdit, Ads, AdDeleteMedia, AdUpdate } from "./actionTypes";
import { adDetail, adDelete, adEdit, ads, adDeleteMedia, adUpdate } from '../api/ads';

export const adsAction = (navigate, page, search, status) => {
    return async (dispatch) => {
        await dispatch({ type: Ads, payload: null });
        ads(navigate, page, search, status).then(async (result) => {
            await dispatch({ type: Ads, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Ads, payload: error });
        });
    };
};

export const adDetailAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: AdDetail, payload: null });
        adDetail(navigate, id).then(async (result) => {
            await dispatch({ type: AdDetail, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AdDetail, payload: error });
        });
    };
};

export const adDeleteMediaAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AdDeleteMedia, payload: null });
        adDeleteMedia(navigate, data).then(async (result) => {
            await dispatch({ type: AdDeleteMedia, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AdDeleteMedia, payload: error });
        });
    };
};

export const adEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: AdEdit, payload: null });
        adEdit(navigate, id).then(async (result) => {
            await dispatch({ type: AdEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AdEdit, payload: error });
        });
    };
};

export const adUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AdUpdate, payload: null });
        adUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: AdUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AdUpdate, payload: error });
        });
    };
};

export const adDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: AdDelete, payload: null });
        adDelete(navigate, id).then(async (result) => {
            await dispatch({ type: AdDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AdDelete, payload: error });
        });
    };
};