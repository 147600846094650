import { VipAdDelete, VipAdEdit, VipAds, VipAdUpdate } from "./actionTypes";
import { vipAdDelete, vipAdEdit, vipAds, vipAdUpdate } from '../api/vipAds';

export const vipAdsAction = (navigate, page) => {
    return async (dispatch) => {
        await dispatch({ type: VipAds, payload: null });
        vipAds(navigate, page).then(async (result) => {
            await dispatch({ type: VipAds, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: VipAds, payload: error });
        });
    };
};

export const vipAdEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: VipAdEdit, payload: null });
        vipAdEdit(navigate, id).then(async (result) => {
            await dispatch({ type: VipAdEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: VipAdEdit, payload: error });
        });
    };
};

export const vipAdUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: VipAdUpdate, payload: null });
        vipAdUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: VipAdUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: VipAdUpdate, payload: error });
        });
    };
};

export const vipAdDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: VipAdDelete, payload: null });
        vipAdDelete(navigate, id).then(async (result) => {
            await dispatch({ type: VipAdDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: VipAdDelete, payload: error });
        });
    };
};