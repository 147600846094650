import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import '../../Style/tree.css';

const SubCategories = ({ data, context, level }) => {

    const [collapse, setCollapse] = useState([]);
    const [trShow, setTrShow] = useState([]);

    useEffect(() => {
        data.forEach(element => {
            setCollapse(oldArray => [...oldArray, false]);
            setTrShow(oldArray => [...oldArray, false]);
        });
    })

    const toggle = (index) => {
        let cItems = [...collapse];
        let cItem = { ...cItems[index] };
        cItem = !cItems[index];
        cItems[index] = cItem;
        setCollapse(cItems)

        let tItems = [...trShow];
        let tItem = { ...tItems[index] };
        tItem = !tItems[index];
        tItems[index] = tItem;
        setTrShow(tItems);
    }

    return (
        <React.Fragment>
            {data.map((item, index) => (
                <>
                    <tr key={item.id}>
                        {item.children.length > 0 ? (<th style={{ paddingLeft: level }} scope="row" onClick={() => toggle(index)}>
                            <i className={trShow[index] ? "arrow down" : "arrow right"} />
                        </th>) : (<th style={{ paddingLeft: level }} />)}

                        <td style={{ paddingLeft: level }}>{item.title}</td>
                        {item.translations.map((t, i) => (
                            <td key={i} style={{ paddingLeft: level }} > {t.title}</td>
                        ))}

                        <td>
                            {(PermissionService.hasPermission('category-create', context.permissions)) ? (
                                <Link
                                    className="btn btn-info"
                                    to={`/category-create/${item.id}/${item.title}`}
                                >
                                    +
                                </Link>
                            ) : ('')}
                            &nbsp;
                            {(PermissionService.hasPermission('category-edit', context.permissions)) ? (
                                <Link
                                    className="btn btn-warning"
                                    to={`/category-edit/${item.id}`}
                                >
                                    Edit
                                </Link>
                            ) : ('')}
                            &nbsp;
                            {(PermissionService.hasPermission('category-delete', context.permissions)) ? (
                                <Link
                                    className="btn btn-danger"
                                    to={`/category-delete/${item.id}`}
                                >
                                    Delete
                                </Link>
                            ) : ('')}
                        </td>
                    </tr>

                    {item.children.length > 0 && trShow[index] && (
                        <SubCategories data={item.children} context={context} level={level + 20}
                        />)}

                </>
            ))}
        </React.Fragment>
    )
}

export default SubCategories;
