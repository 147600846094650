import { PostDetail, PostDelete, PostEdit, Posts, PostDeleteMedia, PostUpdate } from "../actions/actionTypes";

export const postsReducer = (state = {}, action) => {
    switch (action.type) {
        case Posts:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const postDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case PostDetail:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const postDeleteMediaReducer = (state = {}, action) => {
    switch (action.type) {
        case PostDeleteMedia:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const postEditReducer = (state = {}, action) => {
    switch (action.type) {
        case PostEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const postUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PostUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const postDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PostDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};