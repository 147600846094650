import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { citiesAction } from '../../actions/cityActions';
import { Pagination } from "@mui/material";

const Cities = () => {
  const context = useContext(SimpleContext);
  const { provinceId, countryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [province, setProvince] = useState('');
  const [pId, setPId] = useState('');
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const cities = useSelector((state) => state.cities);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    var formData = new FormData();
    formData.append('province_id', provinceId)
    if (search)
      formData.append('search', search);
    dispatch(citiesAction(navigate, page, formData));
  };

  useEffect(() => {
    if (cities.result) {
      context.loading = false;
      setProvince(cities.result.province);
      setPId(cities.result.province.id);
      setPageCount(cities.result.cities["last_page"]);
      if (cities.result.cities["data"]) setData(cities.result.cities["data"]);
    }
  }, [cities])

  useEffect(() => {
    fetchData()
  }, [search])

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <Row className="p-3">
            <Col md={8}>
              <div className="input-group">
                <h4>Cities of </h4> &nbsp;&nbsp;&nbsp; <h4 className="text-warning">{province ? province.title : ''}</h4>
                &nbsp;&nbsp;&nbsp;
                {(PermissionService.hasPermission('city-create', context.permissions)) ? (
                  <Link className="btn btn-info" to={`/city-create/${pId}/${countryId}`}>
                    New City
                  </Link>
                ) : ('')}
                &nbsp;&nbsp;&nbsp;
                <Link className="btn btn-primary" to={`/provinces/${countryId}`}>
                  Back to Provinces
                </Link>
              </div>
            </Col>
            <Col md={4}>
              <input
                className="form-control"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
              />
            </Col>
          </Row>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td>
                      {(PermissionService.hasPermission('city-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/city-edit/${item.id}/${pId}/${countryId}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('city-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/city-delete/${item.id}/${pId}/${countryId}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default Cities;
