import { Countries, CountryDelete, CountryEdit, CountryStore, CountryUpdate } from "./actionTypes";
import { getCountries, countryDelete, countryEdit, countryStore, countryUpdate } from '../api/countries';

export const countriesAction = (navigate, page, search) => {
    return async (dispatch) => {
        await dispatch({ type: Countries, payload: null });
        getCountries(navigate, page, search).then(async (result) => {
            await dispatch({ type: Countries, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Countries, payload: error });
        });
    };
};

export const countryStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CountryStore, payload: null });
        countryStore(navigate, data).then(async (result) => {
            await dispatch({ type: CountryStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CountryStore, payload: error });
        });
    };
};

export const countryEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CountryEdit, payload: null });
        countryEdit(navigate, id).then(async (result) => {
            await dispatch({ type: CountryEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CountryEdit, payload: error });
        });
    };
};

export const countryUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CountryUpdate, payload: null });
        countryUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: CountryUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CountryUpdate, payload: error });
        });
    };
};

export const countryDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CountryDelete, payload: null });
        countryDelete(navigate, id).then(async (result) => {
            await dispatch({ type: CountryDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CountryDelete, payload: error });
        });
    };
};