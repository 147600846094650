import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userDeleteAction, userEditAction } from "../../actions/userActions";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";

const UserDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [defCheck, setDefCheck] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectRoles, setSelectRoles] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [is_active, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const userEdit = useSelector((state) => state.userEdit);
  const userDelete = useSelector((state) => state.userDelete);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    context.loading = true;
    setError(null);
    dispatch(userEditAction(navigate, id));
  };

  useEffect(() => {
    if (userEdit.result) {
      context.loading = false;
      setName(userEdit.result["user"]["name"]);
      setEmail(userEdit.result["user"]["email"]);
      if (userEdit.result["user"]["is_active"]) setIsActive(true);
      else setIsActive(false);
      setRoles(userEdit.result["roles"]);
      var ur = [];
      userEdit.result["user"]["roles"].map((item) => {
        ur.push(item["id"].toString());
      });
      setSelectRoles(ur);
      var cur = [];
      userEdit.result["roles"].map((item) => {
        if (ur.includes(item.id.toString())) cur.push(true);
        else cur.push(false);
      });
      setDefCheck(cur);
    }
  }, [userEdit])

  useEffect(() => {
    var crp = [];
    roles.map((item) => {
      if (selectRoles.includes(item.id.toString())) crp.push(true);
      else crp.push(false);
    });
    setDefCheck(crp);
  }, [selectRoles])

  const handleChangeRoles = (e) => {
    const arr = selectRoles;
    const newId = e.target.value;

    if (!arr.includes(newId)) {
      arr.push(newId);
    } else {
      arr.splice(arr.indexOf(newId), 1);
    }
    setSelectRoles(arr);
  };

  const deleteUser = () => {
    context.loading = true;
    setError(null);
    dispatch(userDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (userDelete.result)
      context.loading = false;
  }, [userDelete])

  const handleChangeIsActive = () => {
    setIsActive(!is_active);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteUser();
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h2>Delete User:  </h2>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{name}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/users">
              Back
            </Link>
          </div>
          <h3 className="text-danger text-center">
            Are you sure to delete this User?
          </h3>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  disabled
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <div className="input-group justify-content-center">
                <Form.Group className="m-3">
                  <Form.Check
                    className="form-control"
                    type="checkbox"
                    onChange={handleChangeIsActive}
                    label="Active"
                    disabled
                    checked={is_active}
                  />
                </Form.Group>
                <div className=" m-3">
                  <div className="form-control">
                    <Form.Label>Roles: </Form.Label>
                    <Form.Group className="mb-3">
                      {roles.map((item, i) => (
                        <Form.Check
                          type="checkbox"
                          onChange={handleChangeRoles}
                          key={item.id}
                          value={item.id}
                          disabled
                          label={item.name}
                          defaultChecked={defCheck[i]}
                        />
                      ))}
                    </Form.Group>
                  </div>
                </div>
              </div>
              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default UserDelete;
