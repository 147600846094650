import { Cities, CityDelete, CityEdit, CityStore, CityUpdate } from "./actionTypes";
import { getCities, cityDelete, cityEdit, cityStore, cityUpdate } from '../api/cities';

export const citiesAction = (navigate, page, data) => {
    return async (dispatch) => {
        await dispatch({ type: Cities, payload: null });
        getCities(navigate, page, data).then(async (result) => {
            await dispatch({ type: Cities, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Cities, payload: error });
        });
    };
};

export const cityStoreAction = (navigate, data, pId, cId) => {
    return async (dispatch) => {
        await dispatch({ type: CityStore, payload: null });
        cityStore(navigate, data, pId, cId).then(async (result) => {
            await dispatch({ type: CityStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CityStore, payload: error });
        });
    };
};

export const cityEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CityEdit, payload: null });
        cityEdit(navigate, id).then(async (result) => {
            await dispatch({ type: CityEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CityEdit, payload: error });
        });
    };
};

export const cityUpdateAction = (navigate, data, pId, cId) => {
    return async (dispatch) => {
        await dispatch({ type: CityUpdate, payload: null });
        cityUpdate(navigate, data, pId, cId).then(async (result) => {
            await dispatch({ type: CityUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CityUpdate, payload: error });
        });
    };
};

export const cityDeleteAction = (navigate, id, pId, cId) => {
    return async (dispatch) => {
        await dispatch({ type: CityDelete, payload: null });
        cityDelete(navigate, id, pId, cId).then(async (result) => {
            await dispatch({ type: CityDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CityDelete, payload: error });
        });
    };
};