import { CategoryHeaderCreate, CategoryHeaderDelete, CategoryHeaderEdit, CategoryHeaders, CategoryHeaderStore, CategoryHeaderUpdate } from "../actions/actionTypes";

export const categoryHeadersReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryHeaders:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryHeaderCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryHeaderCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryHeaderStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryHeaderStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryHeaderEditReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryHeaderEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryHeaderUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryHeaderUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const categoryHeaderDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CategoryHeaderDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};