import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { blockUserEditAction, blockUserDeleteAction } from '../../actions/blockUserActions'

const BlockUserDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [blockerFullName, setBlockerFullName] = useState("");
  const [blockerUserName, setBlockerUserName] = useState("");
  const [blockedFullName, setBlockedFullName] = useState("");
  const [blockedUserName, setBlockedUserName] = useState("");
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);
  const blockUserEdit = useSelector((state) => state.blockUserEdit);
  const blockUserDelete = useSelector((state) => state.blockUserDelete);

  useEffect(() => {
    getBlock();
  }, []);

  const getBlock = () => {
    context.loading = true;
    setError(null);
    dispatch(blockUserEditAction(navigate, id));
  };

  useEffect(() => {
    if (blockUserEdit.result) {
      context.loading = false;
      setBlockerFullName(blockUserEdit.result["blocker"]['fullName']);
      if (blockUserEdit.result["blocker"]['user_name'])
        setBlockerUserName(blockUserEdit.result["blocker"]['user_name']);
      else
        setBlockerUserName('');
      setBlockedFullName(blockUserEdit.result["blocked"]['fullName']);
      if (blockUserEdit.result["blocked"]['user_name'])
        setBlockedUserName(blockUserEdit.result["blocked"]['user_name']);
      else
        setBlockedUserName('');
      if (blockUserEdit.result["reason"])
        setReason(blockUserEdit.result["reason"]);
      else
        setReason('');
    }
  }, [blockUserEdit])

  const deleteBlock = () => {
    context.loading = true;
    setError(null);
    dispatch(blockUserDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (blockUserDelete.result) {
      context.loading = false;
      if (blockUserDelete.result.status !== 200)
        setError(blockUserDelete.result.data.message);
    }
  }, [blockUserDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteBlock();
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Blocked User </h4>
            &nbsp;
            &nbsp;
            <Link className="btn btn-info" to="/blockUsers">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Blocker FullName</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={blockerFullName}
                  onChange={(e) => setBlockerFullName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Blocker UserName</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={blockerUserName}
                  onChange={(e) => setBlockerUserName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Blocked FullName</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={blockedFullName}
                  onChange={(e) => setBlockedFullName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Blocked UserName</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={blockedUserName}
                  onChange={(e) => setBlockedUserName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default BlockUserDelete;
