import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { cityEditAction, cityDeleteAction } from '../../actions/cityActions'

const CityDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, cId, pId } = useParams();
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);
  const cityEdit = useSelector((state) => state.cityEdit);
  const cityDelete = useSelector((state) => state.cityDelete);

  useEffect(() => {
    getCity();
  }, []);

  const getCity = () => {
    context.loading = true;
    setError(null);
    dispatch(cityEditAction(navigate, id));
  };

  useEffect(() => {
    if (cityEdit.result) {
      context.loading = false;
      setTitle(cityEdit.result["title"]);
    }
  }, [cityEdit])

  const deleteCity = () => {
    context.loading = true;
    setError(null);
    dispatch(cityDeleteAction(navigate, id, pId, cId));
  };

  useEffect(() => {
    if (cityDelete.result) {
      context.loading = false;
      if (cityDelete.result.status !== 200)
        setError(cityDelete.result.data.message);
    }
  }, [cityDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteCity();
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete City: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-danger">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to={`/cities/${pId}/${cId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Province Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  disabled
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CityDelete;
