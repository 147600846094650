import { AdDetail, AdDelete, AdEdit, Ads, AdDeleteMedia, AdUpdate } from "../actions/actionTypes";

export const adsReducer = (state = {}, action) => {
    switch (action.type) {
        case Ads:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const adDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case AdDetail:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const adDeleteMediaReducer = (state = {}, action) => {
    switch (action.type) {
        case AdDeleteMedia:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const adEditReducer = (state = {}, action) => {
    switch (action.type) {
        case AdEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const adUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case AdUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const adDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case AdDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};