import { BlockUserDelete, BlockUsers, BlockUserEdit } from "../actions/actionTypes";

export const blockUsersReducer = (state = {}, action) => {
    switch (action.type) {
        case BlockUsers:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const blockUserEditReducer = (state = {}, action) => {
    switch (action.type) {
        case BlockUserEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const blockUserDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case BlockUserDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};