import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Layout from "./Shared/Layout";

const PermissionDenied = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "85px", paddingTop: "10%" }}>403</h1>
        <h1 style={{ fontSize: "45px" }}>Sorry! You are not allowed here!</h1>
        <Button variant="info" onClick={() => navigate('/')} >Go to Dashboard</Button>
      </div>
    </Layout>
  );
};
export default PermissionDenied;
