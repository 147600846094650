import { GetTerm, SetTerm } from "./actionTypes";
import { getTerm, setTerm } from '../api/terms';

export const getTermAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: GetTerm, payload: null });
        getTerm(navigate).then(async (result) => {
            await dispatch({ type: GetTerm, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: GetTerm, payload: error });
        });
    };
};

export const setTermAction = (navigate, data) => {
    return async (dispatch) => {
        if (data) {
            await dispatch({ type: SetTerm, payload: null });
            setTerm(navigate, data).then(async (result) => {
                await dispatch({ type: SetTerm, payload: result });
            }).catch(async (error) => {
                await dispatch({ type: SetTerm, payload: error });
            });
        }
        else
            await dispatch({ type: SetTerm, payload: null });
    };
};
