import { Dashboard, DashboardGetByDate } from "../actions/actionTypes";

export const dashboardsReducer = (state = {}, action) => {
    switch (action.type) {
        case Dashboard:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const dashboardByDateReducer = (state = {}, action) => {
    switch (action.type) {
        case DashboardGetByDate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};