import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Layout from "./Shared/Layout";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "85px", paddingTop: "10%" }}>404</h1>
        <h1 style={{ fontSize: "45px" }}>Page not found!</h1>
        <Button variant="info" onClick={() => navigate('/')} >Go to Dashboard</Button>
      </div>
    </Layout>
  );
};
export default NotFound;
