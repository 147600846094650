import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const vipAds = async (navigate, page) => {
    try {
        var result = await http.axios.get(UrlServices.getVipAdsApi() + "?page=" + page, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const vipAdEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getVipAdsApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const vipAdUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getVipAdsApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/vipAds");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const vipAdDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getVipAdsApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/vipAds");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}