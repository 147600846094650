import React from "react";
import BounceLoader from "react-spinners/BounceLoader";
import LoadingOverlay from "react-loading-overlay-ts";

const Loading = ({ active, children }) => (
  <LoadingOverlay
    active={active}
    spinner={<BounceLoader />}
    text="Please wait..."
    styles={{
      overlay: {
        position: "fixed",
        height: "100%",
        width: "100%",
        top: "0px",
        left: "0px",
        display: "flex",
        textAlign: "center",
        fontSize: "1.2em",
        color: "#FFF",
        background: "rgba(0,0,0,0.7)",
        zIndex: "800",
        transition: "opacity 500ms ease-in",
        opacity: "1",
      },
    }}
  >
    <div style={{ padding: 15 }}>{children}</div>
  </LoadingOverlay>
);

export default Loading;
