import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { appUserCreateAction, appUserChangePlanAction } from '../../actions/appUserActions'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

const AppUserChangePlan = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, pId } = useParams();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [error, setError] = useState(null);
  const appUserCreate = useSelector((state) => state.appUserCreate);
  const appUserChangePlan = useSelector((state) => state.appUserChangePlan);

  useEffect(() => {
    getLangs();
  }, []);

  const getLangs = () => {
    context.loading = true;
    setError(null);
    dispatch(appUserCreateAction(navigate));
  };


  useEffect(() => {
    if (appUserCreate.result) {
      context.loading = false;
      setPlans(appUserCreate.result.plans);
    }
  }, [appUserCreate])

  useEffect(() => {
    if (plans) {
      if (pId) {
        const lastPlan = plans.find((i) => i.id == pId);
        if (lastPlan)
          setSelectedPlan([lastPlan]);
      }
    }
  }, [plans])

  const addPlan = () => {
    context.loading = true;
    setError(null);
    const data = new FormData();
    data.append('user_id', id);
    data.append('plan_id', selectedPlan[0].id);
    dispatch(appUserChangePlanAction(navigate, data));
  };

  useEffect(() => {
    if (appUserChangePlan.result) {
      context.loading = false;
      if (appUserChangePlan.result.status !== 200)
        setError(appUserChangePlan.result.data.message);
    }
  }, [appUserChangePlan])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedPlan === "") setError("Choose Plan is required!");
    else {
      addPlan();
    }
  };


  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Change User Plan</h4> &nbsp;
            <Link className="btn btn-info" to="/appUsers">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group>
                <Form.Label>Plan</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="title"
                  onChange={setSelectedPlan}
                  options={plans}
                  placeholder="Choose a Plan..."
                  selected={selectedPlan}
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default AppUserChangePlan;
