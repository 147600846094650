import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Col, Form, Image, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { countriesAction } from '../../actions/countryActions';
import http from '../../api'
import { Pagination } from "@mui/material";

const Countries = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const countries = useSelector((state) => state.countries);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(countriesAction(navigate, page, search));
  };

  useEffect(() => {
    if (countries.result) {
      context.loading = false;
      setPageCount(countries.result["last_page"]);
      if (countries.result["data"]) setData(countries.result["data"]);
    }
  }, [countries])

  useEffect(() => {
    fetchData()
  }, [search])

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <Row className="p-3">
            <Col md={8}>
              <div className="input-group">
                <h4>Countries</h4> &nbsp;
                {(PermissionService.hasPermission('country-create', context.permissions)) ? (
                  <Link className="btn btn-info" to="/country-create">
                    New Country
                  </Link>
                ) : ('')}
              </div>
            </Col>
            <Col md={4}>
              <input
                className="form-control"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
              />
            </Col>
          </Row>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Code </th>
                  <th>Flag</th>
                  <th>Flag Image</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td>{item.code}</td>
                    <td>{item.flag}</td>
                    <td><Image src={http.assetUrl + item.flag_path} /></td>
                    {item.is_active ? (
                      <td className="text-success">Active</td>
                    ) : (
                      <td className="text-danger">Not Active</td>
                    )}
                    <td>
                      {(PermissionService.hasPermission('country-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/country-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('country-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/country-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('province-list', context.permissions)) ? (
                        <Link
                          className="btn btn-info"
                          to={`/provinces/${item.id}`}
                        >
                          Provinces
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default Countries;
