import { AppUserCreate, AppUserDelete, AppUserEdit, AppUsers, AppUserUpdate, AppUserChangePlan, AppUserBlock, AppUserNotification, AppUserNotificationAll } from "./actionTypes";
import { appUserCreate, appUserDelete, appUserEdit, appUsers, appUserUpdate, appUserChangePlan, appUserBlock, appUserNotification, appUserNotificationAll } from '../api/appUsers';

export const appUsersAction = (navigate, page, search) => {
    return async (dispatch) => {
        await dispatch({ type: AppUsers, payload: null });
        appUsers(navigate, page, search).then(async (result) => {
            await dispatch({ type: AppUsers, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUsers, payload: error });
        });
    };
};

export const appUserCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserCreate, payload: null });
        appUserCreate(navigate).then(async (result) => {
            await dispatch({ type: AppUserCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserCreate, payload: error });
        });
    };
};

export const appUserEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserEdit, payload: null });
        appUserEdit(navigate, id).then(async (result) => {
            await dispatch({ type: AppUserEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserEdit, payload: error });
        });
    };
};

export const appUserUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserUpdate, payload: null });
        appUserUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: AppUserUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserUpdate, payload: error });
        });
    };
};

export const appUserDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserDelete, payload: null });
        appUserDelete(navigate, id).then(async (result) => {
            await dispatch({ type: AppUserDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserDelete, payload: error });
        });
    };
};

export const appUserChangePlanAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserChangePlan, payload: null });
        appUserChangePlan(navigate, data).then(async (result) => {
            await dispatch({ type: AppUserChangePlan, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserChangePlan, payload: error });
        });
    };
};

export const appUserBlockAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserBlock, payload: null });
        appUserBlock(navigate, data).then(async (result) => {
            await dispatch({ type: AppUserBlock, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserBlock, payload: error });
        });
    };
};

export const appUserNotificationAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserNotification, payload: null });
        appUserNotification(navigate, data).then(async (result) => {
            await dispatch({ type: AppUserNotification, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserNotification, payload: error });
        });
    };
};

export const appUserNotificationAllAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: AppUserNotificationAll, payload: null });
        appUserNotificationAll(navigate, data).then(async (result) => {
            await dispatch({ type: AppUserNotificationAll, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: AppUserNotificationAll, payload: error });
        });
    };
};