import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { currencyUnitStoreAction } from '../../actions/currencyUnitActions'

const CurrencyUnitCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [symbol, setSymbol] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const currencyUnitStore = useSelector((state) => state.currencyUnitStore);

  const addUnit = () => {
    context.loading = true;
    setError(null);
    const data = {
      title: title,
      symbol: symbol,
      is_active: isActive,
    };
    dispatch(currencyUnitStoreAction(navigate, data));
  };

  useEffect(() => {
    if (currencyUnitStore.result) {
      context.loading = false;
      if (currencyUnitStore.result.status !== 200)
        setError(currencyUnitStore.result.data.message);
    }
  }, [currencyUnitStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else if (symbol === "") setError("Enter Symbol is required!");
    else {
      addUnit();
    }
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Currency Unit</h4> &nbsp;
            <Link className="btn btn-info" to="/currencyUnits">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Symbol</Form.Label>
                <Form.Control
                  type="text"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                  placeholder="Enter Symbol (USD) ..."
                />
              </Form.Group>

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CurrencyUnitCreate;
