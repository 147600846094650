import { PlanCreate, PlanDelete, PlanEdit, Plans, PlanStore, PlanUpdate } from "../actions/actionTypes";

export const plansReducer = (state = {}, action) => {
    switch (action.type) {
        case Plans:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanCreate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planStoreReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanStore:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planEditReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanEdit:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanUpdate:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};

export const planDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PlanDelete:
            return { ...state, result: action.payload };
        default:
            return state;
    }
};