import { combineReducers } from 'redux'
import { userChangePasswordReducer, userCreateReducer, userDeleteReducer, userEditReducer, usersReducer, userStoreReducer, userUpdateReducer } from './usersReducer'
import { roleCreateReducer, roleDeleteReducer, roleEditReducer, rolesReducer, roleStoreReducer, roleUpdateReducer } from './rolesReducer'
import { languageDeleteReducer, languageEditReducer, languagesReducer, languageStoreReducer, languageUpdateReducer } from './languagesReducer'
import { countryDeleteReducer, countryEditReducer, countriesReducer, countryStoreReducer, countryUpdateReducer } from './countriesReducer'
import { provinceDeleteReducer, provinceEditReducer, provincesReducer, provinceStoreReducer, provinceUpdateReducer } from './provincesReducer'
import { cityDeleteReducer, cityEditReducer, citiesReducer, cityStoreReducer, cityUpdateReducer } from './citiesReducer'
import { planCreateReducer, planDeleteReducer, planEditReducer, plansReducer, planStoreReducer, planUpdateReducer } from './plansReducer'
import { planOptionCreateReducer, planOptionDeleteReducer, planOptionEditReducer, planOptionsReducer, planOptionStoreReducer, planOptionUpdateReducer } from './planOptionsReducer'
import { postDetailReducer, postDeleteReducer, postEditReducer, postsReducer, postDeleteMediaReducer, postUpdateReducer } from './postsReducer'
import { adDetailReducer, adDeleteReducer, adEditReducer, adsReducer, adDeleteMediaReducer, adUpdateReducer } from './adsReducer'
import { vipAdDeleteReducer, vipAdEditReducer, vipAdsReducer, vipAdUpdateReducer } from './vipAdsReducer'
import { appUserCreateReducer, appUserDeleteReducer, appUserEditReducer, appUsersReducer, appUserUpdateReducer, appUserChangePlanReducer, appUserBlockReducer, appUserNotificationReducer, appUserNotificationAllReducer } from './appUsersReducer'
import { eventDeleteReducer, eventEditReducer, eventsReducer, eventStoreReducer, eventUpdateReducer } from './eventsReducer'
import { getTermReducer, setTermReducer } from './termReducer'
import { dashboardsReducer, dashboardByDateReducer } from './dashboardsReducer'
import { categoryCreateReducer, categoryDeleteReducer, categoryEditReducer, categoriesReducer, categoryStoreReducer, categoryUpdateReducer } from './categoriesReducer'
import { categoryHeaderCreateReducer, categoryHeaderDeleteReducer, categoryHeaderEditReducer, categoryHeadersReducer, categoryHeaderStoreReducer, categoryHeaderUpdateReducer } from './categoryHeadersReducer'
import { categoryFilterCreateReducer, categoryFilterDeleteReducer, categoryFilterEditReducer, categoryFiltersReducer, categoryFilterStoreReducer, categoryFilterUpdateReducer } from './categoryFiltersReducer'
import { reportedUsersReducer, reportedUserDeleteReducer, reportedAdsReducer, reportedAdDeleteReducer, reportedPostsReducer, reportedPostDeleteReducer } from './reportsReducer'
import { currencyUnitDeleteReducer, currencyUnitEditReducer, currencyUnitsReducer, currencyUnitStoreReducer, currencyUnitUpdateReducer } from './currencyUnitsReducer'
import { blockUserDeleteReducer, blockUserEditReducer, blockUsersReducer } from './blockUsersReducer'

export default combineReducers({
    dashboards: dashboardsReducer,
    dashboardByDate: dashboardByDateReducer,

    users: usersReducer,
    userCreate: userCreateReducer,
    userStore: userStoreReducer,
    userEdit: userEditReducer,
    userUpdate: userUpdateReducer,
    userDelete: userDeleteReducer,
    userChangePassword: userChangePasswordReducer,

    roles: rolesReducer,
    roleCreate: roleCreateReducer,
    roleStore: roleStoreReducer,
    roleEdit: roleEditReducer,
    roleUpdate: roleUpdateReducer,
    roleDelete: roleDeleteReducer,

    getTerm: getTermReducer,
    setTerm: setTermReducer,

    languages: languagesReducer,
    languageStore: languageStoreReducer,
    languageEdit: languageEditReducer,
    languageUpdate: languageUpdateReducer,
    languageDelete: languageDeleteReducer,

    countries: countriesReducer,
    countryStore: countryStoreReducer,
    countryEdit: countryEditReducer,
    countryUpdate: countryUpdateReducer,
    countryDelete: countryDeleteReducer,

    provinces: provincesReducer,
    provinceStore: provinceStoreReducer,
    provinceEdit: provinceEditReducer,
    provinceUpdate: provinceUpdateReducer,
    provinceDelete: provinceDeleteReducer,

    cities: citiesReducer,
    cityStore: cityStoreReducer,
    cityEdit: cityEditReducer,
    cityUpdate: cityUpdateReducer,
    cityDelete: cityDeleteReducer,

    plans: plansReducer,
    planCreate: planCreateReducer,
    planStore: planStoreReducer,
    planEdit: planEditReducer,
    planUpdate: planUpdateReducer,
    planDelete: planDeleteReducer,

    planOptions: planOptionsReducer,
    planOptionCreate: planOptionCreateReducer,
    planOptionStore: planOptionStoreReducer,
    planOptionEdit: planOptionEditReducer,
    planOptionUpdate: planOptionUpdateReducer,
    planOptionDelete: planOptionDeleteReducer,

    posts: postsReducer,
    postDetail: postDetailReducer,
    postDeleteMedia: postDeleteMediaReducer,
    postEdit: postEditReducer,
    postUpdate: postUpdateReducer,
    postDelete: postDeleteReducer,

    ads: adsReducer,
    adDetail: adDetailReducer,
    adDeleteMedia: adDeleteMediaReducer,
    adEdit: adEditReducer,
    adUpdate: adUpdateReducer,
    adDelete: adDeleteReducer,

    vipAds: vipAdsReducer,
    vipAdEdit: vipAdEditReducer,
    vipAdUpdate: vipAdUpdateReducer,
    vipAdDelete: vipAdDeleteReducer,

    appUsers: appUsersReducer,
    appUserCreate: appUserCreateReducer,
    appUserEdit: appUserEditReducer,
    appUserUpdate: appUserUpdateReducer,
    appUserDelete: appUserDeleteReducer,
    appUserChangePlan: appUserChangePlanReducer,
    appUserBlock: appUserBlockReducer,
    appUserNotification: appUserNotificationReducer,
    appUserNotificationAll: appUserNotificationAllReducer,

    events: eventsReducer,
    eventStore: eventStoreReducer,
    eventEdit: eventEditReducer,
    eventUpdate: eventUpdateReducer,
    eventDelete: eventDeleteReducer,

    categories: categoriesReducer,
    categoryCreate: categoryCreateReducer,
    categoryStore: categoryStoreReducer,
    categoryEdit: categoryEditReducer,
    categoryUpdate: categoryUpdateReducer,
    categoryDelete: categoryDeleteReducer,

    categoryHeaders: categoryHeadersReducer,
    categoryHeaderCreate: categoryHeaderCreateReducer,
    categoryHeaderStore: categoryHeaderStoreReducer,
    categoryHeaderEdit: categoryHeaderEditReducer,
    categoryHeaderUpdate: categoryHeaderUpdateReducer,
    categoryHeaderDelete: categoryHeaderDeleteReducer,

    categoryFilters: categoryFiltersReducer,
    categoryFilterCreate: categoryFilterCreateReducer,
    categoryFilterStore: categoryFilterStoreReducer,
    categoryFilterEdit: categoryFilterEditReducer,
    categoryFilterUpdate: categoryFilterUpdateReducer,
    categoryFilterDelete: categoryFilterDeleteReducer,

    reportedUsers: reportedUsersReducer,
    reportedUserDelete: reportedUserDeleteReducer,
    reportedAds: reportedAdsReducer,
    reportedAdDelete: reportedAdDeleteReducer,
    reportedPosts: reportedPostsReducer,
    reportedPostDelete: reportedPostDeleteReducer,

    currencyUnits: currencyUnitsReducer,
    currencyUnitStore: currencyUnitStoreReducer,
    currencyUnitEdit: currencyUnitEditReducer,
    currencyUnitUpdate: currencyUnitUpdateReducer,
    currencyUnitDelete: currencyUnitDeleteReducer,

    blockUsers: blockUsersReducer,
    blockUserEdit: blockUserEditReducer,
    blockUserDelete: blockUserDeleteReducer,
})
