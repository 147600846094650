import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { reportedAdsAction, reportedAdDeleteAction } from '../../actions/reportActions';
import { Pagination } from "@mui/material";
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";

const ReportedAds = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const reportedAds = useSelector((state) => state.reportedAds);
  const reportedAdDelete = useSelector((state) => state.reportedAdDelete);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(reportedAdsAction(navigate, page));
  };

  useEffect(() => {
    if (reportedAds.result) {
      context.loading = false;
      setPageCount(reportedAds.result["last_page"]);
      if (reportedAds.result["data"]) setData(reportedAds.result["data"]);
    }
  }, [reportedAds])


  const handleChange = (event, value) => {
    setPage(value);
  };

  const deleteReport = (id) => {
    context.loading = true;
    setError(null);
    dispatch(reportedAdDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (reportedAdDelete.result) {
      context.loading = false;
      fetchData();
    }
  }, [reportedAdDelete])

  const handleDeleteReport = (event, id) => {
    event.preventDefault();
    Swal.fire({
      title: "Delete Report",
      text: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      cancelButtonColor: "gray",
      confirmButtonText: "YES, DELETE!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReport(id);
      }
    });
  };

  return (
    <Layout>
      <div>
        <Card>
          <Row className="p-3">
            <Col md={12}>
              <div className="input-group">
                <h4>Reported Ads</h4> &nbsp;
              </div>
            </Col>
          </Row>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Reporter Full Name</th>
                  <th>Reporter User Name</th>
                  <th>Reported Ad Title</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.reporter ? item.reporter.fullName : ''}</td>
                    <td>{item.reporter ? item.reporter.userName : ''}</td>
                    <td>{item.ad ? item.ad.title : ''}</td>
                    <td>{item.description}</td>
                    <td>
                      {item.ad && (PermissionService.hasPermission('ad-detail', context.permissions)) ? (
                        <Link
                          className="btn btn-lg btn-info"
                          to={`/ad-detail/${item.ad.id}`}
                        >
                          Ad Details
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('reportedAd-delete', context.permissions)) ? (
                        <Button
                          className="legend"
                          onClick={(e) => handleDeleteReport(e, item.id)}
                          size="lg"
                          variant="danger"
                          type="submit"
                        >
                          Delete
                        </Button>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default ReportedAds;
