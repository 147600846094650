import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userEditAction, userUpdateAction } from "../../actions/userActions";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";

const UserEdit = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [defCheck, setDefCheck] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectRoles, setSelectRoles] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [is_active, setIsActive] = useState(false);
  const [error, setError] = useState(null);
  const userEdit = useSelector((state) => state.userEdit);
  const userUpdate = useSelector((state) => state.userUpdate);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    context.loading = true;
    setError(null);
    dispatch(userEditAction(navigate, id));
  };

  useEffect(() => {
    if (userEdit.result) {
      context.loading = false;
      setName(userEdit.result["user"]["name"]);
      setEmail(userEdit.result["user"]["email"]);
      if (userEdit.result["user"]["is_active"]) setIsActive(true);
      else setIsActive(false);
    setRoles(userEdit.result["roles"]);
    var ur = [];
    userEdit.result["user"]["roles"].map((item) => {
      ur.push(item["id"].toString());
    });
    setSelectRoles(ur);
    var cur = [];
    userEdit.result["roles"].map((item) => {
      if (ur.includes(item.id.toString())) cur.push(true);
      else cur.push(false);
    });
    setDefCheck(cur);
  }
  }, [userEdit])


  useEffect(() => {
    var crp = [];
    roles.map((item) => {
      if (selectRoles.includes(item.id.toString())) crp.push(true);
      else crp.push(false);
    });
    setDefCheck(crp);
  }, [selectRoles])


const handleChangeRoles = (e) => {
  const arr = selectRoles;
  const newId = e.target.value;

  if (!arr.includes(newId)) {
    arr.push(newId);
  } else {
    arr.splice(arr.indexOf(newId), 1);
  }
  setSelectRoles(arr);
};

const changeUser = () => {
  context.loading = true;
  setError(null);
  const data = {
    id: id,
    name: name,
    email: email,
    password: password,
    confirm_password: confirm_password,
    roles: selectRoles,
    is_active: is_active,
  };
  dispatch(userUpdateAction(navigate, data));
};

useEffect(() => {
  if (userUpdate.result) {
    context.loading = false;
    if (userUpdate.result.status !== 200)
      setError(userUpdate.result.data.message);
  }
}, [userUpdate])

const handleChangeIsActive = () => {
  setIsActive(!is_active);
};

const handleSubmit = (event) => {
  event.preventDefault();
  if (name === "") setError("Enter FullName is required!");
  else if (email === "") setError("Enter Email is required!");
  else if (password !== "" && confirm_password === "")
    setError("Enter Confirm Password is required!");
  else if (password !== "" && password !== confirm_password)
    setError("Password and Confirm Password does not match!");
  else {
    changeUser();
  }
};

return (
  <Layout>
    <div>
      <Card>
        <div className="input-group p-3">
          <h4>Edit User: </h4>
          &nbsp;
          &nbsp;
          <h4 className="text-warning">{name}</h4>
          &nbsp;
          <Link className="btn btn-info" to="/users">
            Back
          </Link>
        </div>
        {error ? (
          <div onClick={() => setError(null)}>
            <div className="alert alert-danger m-3">{error}</div>
          </div>
        ) : (
          ""
        )}
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Full Name..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password..."
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Enter Confirm Password..."
              />
            </Form.Group>

            <div className="input-group justify-content-center">
              <Form.Group className="m-3">
                <Form.Check
                  className="form-control"
                  type="checkbox"
                  onChange={handleChangeIsActive}
                  label="Active"
                  checked={is_active}
                />
              </Form.Group>
              <div className=" m-3">
                <div className="form-control">
                  <Form.Label>Roles: </Form.Label>
                  <Form.Group className="mb-3">
                    {roles.map((item, i) => (
                      <Form.Check
                        type="checkbox"
                        onChange={handleChangeRoles}
                        key={item.id}
                        value={item.id}
                        label={item.name}
                        defaultChecked={defCheck[i]}
                      />
                    ))}
                  </Form.Group>
                </div>
              </div>
            </div>
            <Button
              onClick={handleSubmit}
              size="lg"
              variant="warning"
              type="submit"
            >
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  </Layout>
);
};

export default UserEdit;
