import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { categoriesAction } from '../../actions/categoryActions';
import '../../Style/tree.css';
import SubCategories from "./SubCategories";

const Categories = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [langs, setLangs] = useState([]);
  const [collapse, setCollapse] = useState([]);
  const [trShow, setTrShow] = useState([]);
  const [error, setError] = useState(null);
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(categoriesAction(navigate));
  };

  useEffect(() => {
    if (categories.result) {
      context.loading = false;
      if (categories.result.categories) {
        setData(categories.result.categories);
        setLangs(categories.result.langs);
        setCollapse([]);
        setTrShow([]);
        categories.result.categories.forEach(element => {
          setCollapse(oldArray => [...oldArray, false]);
          setTrShow(oldArray => [...oldArray, false]);
        });
      }
    }
  }, [categories])

  const toggle = (index) => {
    let cItems = [...collapse];
    let cItem = { ...cItems[index] };
    cItem = !cItems[index];
    cItems[index] = cItem;
    setCollapse(cItems)

    let tItems = [...trShow];
    let tItem = { ...tItems[index] };
    tItem = !tItems[index];
    tItems[index] = tItem;
    setTrShow(tItems);
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Categories</h4> &nbsp;
            {(PermissionService.hasPermission('category-create', context.permissions)) ? (
              <Link className="btn btn-info" to={`/category-create/${null}/${null}`}>
                New Top Level Category
              </Link>
            ) : ('')}
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th />
                  <th>Title</th>
                  {langs.map((item) => (
                    <th key={item.id}>{`Title (${item.title})`}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <>
                    <tr key={item.id}>
                      {item.children.length > 0 ? (<th scope="row" onClick={() => toggle(index)}>
                        <i className={trShow[index] ? "arrow down" : "arrow right"} />
                      </th>) : (<th />)}

                      <td>{item.title}</td>
                      {item.translations.map((t, i) => (
                        <td key={i} > {t.title}</td>
                      ))}

                      <td>
                        {(PermissionService.hasPermission('category-create', context.permissions)) ? (
                          <Link
                            className="btn btn-info"
                            to={`/category-create/${item.id}/${item.title}`}
                          >
                            +
                          </Link>
                        ) : ('')}
                        &nbsp;
                        {(PermissionService.hasPermission('category-edit', context.permissions)) ? (
                          <Link
                            className="btn btn-warning"
                            to={`/category-edit/${item.id}`}
                          >
                            Edit
                          </Link>
                        ) : ('')}
                        &nbsp;
                        {(PermissionService.hasPermission('category-delete', context.permissions)) ? (
                          <Link
                            className="btn btn-danger"
                            to={`/category-delete/${item.id}`}
                          >
                            Delete
                          </Link>
                        ) : ('')}
                        &nbsp;
                        {(PermissionService.hasPermission('categoryFilter-list', context.permissions)) ? (
                          <Link
                            className="btn btn-success"
                            to={`/categoryFilters/${item.id}`}
                          >
                            Filters
                          </Link>
                        ) : ('')}
                      </td>
                    </tr>

                    {item.children.length > 0 && trShow[index] && (
                      <SubCategories data={item.children} context={context} level={20} />
                    )}

                  </>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </Layout >
  );
};

export default Categories;
