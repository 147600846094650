import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getTermAction, setTermAction } from '../../actions/termActions'

const Term = () => {
    const context = useContext(SimpleContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [langs, setLangs] = useState([]);
    const [data, setData] = useState("");
    const [content, setContent] = useState("");
    const [otherContent, setOtherContent] = useState([]);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const getTerm = useSelector((state) => state.getTerm);
    const setTerm = useSelector((state) => state.setTerm);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        context.loading = true;
        setError(null);
        setResult(null);
        dispatch(getTermAction(navigate));
    };

    useEffect(() => {
        if (getTerm.result) {
            context.loading = false;
            if (getTerm.result.term) {
                setData(getTerm.result.term);
                setContent(getTerm.result.term.content);
                setLangs(getTerm.result.langs);
            }
        }
    }, [getTerm])

    useEffect(() => {
        if (langs && data) {
            langs.map((item) => {
                const l = data['translations[' + item.code + ']'];
                if (l.content) {
                    const ot = { code: item.code, value: l.content }
                    var lastOtherContent = otherContent.find((i) => i.code === item.code);
                    if (!lastOtherContent)
                        setOtherContent((prev) => [...prev, ot]);
                }
            })
        }
    }, [langs])

    const changeOtherContent = (code, value) => {
        var lastOtherContent = otherContent.find((i) => i.code === code);
        if (lastOtherContent) {
            let copyOtherContent = otherContent.filter((i) => i.code !== code);
            if (value)
                copyOtherContent.push({ code: code, value: value });
            setOtherContent(copyOtherContent);
        }
        else {
            setOtherContent((prev) => ([...prev, { code: code, value: value }]));
        }
    }

    const updateContent = () => {
        context.loading = true;
        setError(null);
        const data = new FormData();
        data.append('content', content);
        otherContent.map((item) => {
            data.append('other_content[' + item.code + ']', item.value);
        });
        dispatch(setTermAction(navigate, data));
    }

    useEffect(() => {
        if (setTerm.result) {
            context.loading = false;
            if (setTerm.result.message !== 'ok')
                setError(setTerm.result.message);
            else {
                setResult('Term Updated Successfully!');
                dispatch(setTermAction(navigate));
            }
        }
    }, [setTerm])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (content === '') setError("Content is required!");
        else
            updateContent();
    };

    return (
        <Layout>
            <div>
                <Card>
                    <div className="input-group p-3">
                        <h4>Term of Application</h4> &nbsp;
                    </div>
                    {error ? (
                        <div onClick={() => setError(null)}>
                            <div className="alert alert-danger m-3">{error}</div>
                        </div>
                    ) : (
                        ""
                    )}
                    {result ? (
                        <div onClick={() => setResult(null)}>
                            <div className="alert alert-success m-3">{result}</div>
                        </div>
                    ) : (
                        ""
                    )}
                    <Card.Body>
                        <Form>

                            <Form.Group className="mb-3">
                                <Form.Label>Content</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={content}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setContent(data);
                                    }}
                                />
                            </Form.Group>

                            {langs.map((item, index) => (
                                <Form.Group className="mb-3" key={item.id}>
                                    <Form.Label>Content ({item.title})</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={otherContent.find((i) => i.code === item.code) ?
                                            otherContent.find((i) => i.code === item.code)['value'] : ''
                                        }
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            changeOtherContent(item.code, data)
                                        }}
                                    />
                                </Form.Group>
                            ))}

                            <Button
                                onClick={handleSubmit}
                                size="lg"
                                variant="warning"
                                type="submit"
                            >
                                Update
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    );
};

export default Term;
