import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { categoryFilterCreateAction, categoryFilterStoreAction } from '../../actions/categoryFilterActions'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

const CategoryFilterCreate = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cId } = useParams();
  const [langs, setLangs] = useState([]);
  const [types, setTypes] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedHeader, setSelectedHeader] = useState('');
  const [options, setOptions] = useState(null);
  const [otherTitle, setOtherTitle] = useState([]);
  const [error, setError] = useState(null);
  const categoryFilterCreate = useSelector((state) => state.categoryFilterCreate);
  const categoryFilterStore = useSelector((state) => state.categoryFilterStore);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(categoryFilterCreateAction(navigate));
  }

  useEffect(() => {
    if (categoryFilterCreate.result) {
      setHeaders(categoryFilterCreate.result.headers);
      setTypes(categoryFilterCreate.result.types);
      setLangs(categoryFilterCreate.result.langs);
      var translations = [];
      categoryFilterCreate.result.langs.map((item) => {
        translations.push({ 'lang': item, 'title': '' });
      });
      setOptions([{ 'title': '', translations }])
      context.loading = false;
    }
  }, [categoryFilterCreate])

  const addFilter = () => {
    context.loading = true;
    setError(null);
    var data = new FormData();
    data.append('title', title);
    data.append('category_id', cId);
    data.append('filter_type_id', selectedType[0].id)
    if (selectedType[0].title === 'bool' && selectedHeader !== '' && selectedHeader.length > 0)
      data.append('category_filter_header_id', selectedHeader[0].id)
    if (selectedType[0].title === 'select') {
      options.map((item, index) => {
        data.append('options[' + index + ']', JSON.stringify(item));
      })
    }
    otherTitle.map((item) => {
      data.append('other_title[' + item.code + ']', item.value);
    });
    dispatch(categoryFilterStoreAction(navigate, data, cId));
  };

  useEffect(() => {
    if (categoryFilterStore.result) {
      context.loading = false;
      if (categoryFilterStore.result.status !== 200)
        setError(categoryFilterStore.result.data.message);
    }
  }, [categoryFilterStore])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title is required!");
    else if (selectedType === "" || selectedType.length === 0) setError("Choose Filter Type is required!");
    else if (selectedType[0].title === "select" && !options) setError("In select Type Options is required!");
    else {
      addFilter();
    }
  };

  const changeOtherTitle = (code, value) => {
    var lastOtherTitle = otherTitle.find((i) => i.code === code);
    if (lastOtherTitle) {
      let copyOtherTitle = otherTitle.filter((i) => i.code !== code);
      if (value)
        copyOtherTitle.push({ code: code, value: value });
      setOtherTitle(copyOtherTitle);
    }
    else {
      setOtherTitle((prev) => ([...prev, { code: code, value: value }]));
    }
  }

  const changeOptionTitle = (value, index) => {
    var items = [...options];
    items[index].title = value;
    setOptions(items);
  }

  const changeOtherOptionTitle = (value, index, lIndex) => {
    var items = [...options];
    items[index].translations[lIndex].title = value;
    setOptions(items);
  }

  const addRow = () => {
    var items = [...options];
    var translations = [];
    langs.map((item) => {
      translations.push({ 'lang': item, 'title': '' });
    });
    items.push({ 'title': '', translations })
    setOptions(items);
  }
  const removeRow = (index) => {
    if (index !== 0) {
      var items = [...options];
      items.splice(index, index !== -1 ? 1 : 0);
      setOptions(items);
    }
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Create New Filter</h4> &nbsp;
            <Link className="btn btn-info" to={`/categoryFilters/${cId}`}>
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              <Form.Group>
                <Form.Label>Filter Type</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="title"
                  onChange={setSelectedType}
                  options={types}
                  placeholder="Choose a Type..."
                  selected={selectedType}
                />
              </Form.Group>
              <br />
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title..."
                />
              </Form.Group>

              {langs.map((item) => (
                <Form.Group className="mb-3" key={item.id}>
                  <Form.Label>Title ({item.title})</Form.Label>
                  <Form.Control
                    type="text"
                    value={otherTitle.find((i) => i.code === item.code) ?
                      otherTitle.find((i) => i.code === item.code)['value'] : ''
                    }
                    onChange={(e) => changeOtherTitle(item.code, e.target.value)}
                    placeholder={`Enter Title in ${item.title} ...`}
                  />
                </Form.Group>
              ))}

              {selectedType && selectedType.length > 0 && selectedType[0].title === 'bool' ? (
                <Form.Group>
                  <Form.Label>Header (Group)</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="title"
                    onChange={setSelectedHeader}
                    options={headers}
                    placeholder="Choose Header (Group)..."
                    selected={selectedHeader}
                  />
                </Form.Group>
              ) : ('')}


              {selectedType && selectedType.length > 0 && selectedType[0].title === 'select' ? (
                <>
                  <Form.Label>Select Options</Form.Label>
                  <Table className="text-center" striped bordered hover>
                    <thead>
                      <th>Title</th>
                      {langs.map((item) => (
                        <th key={item.id}>{`Title (${item.title})`}</th>
                      ))}
                      <th>Actions</th>
                    </thead>
                    <tbody>
                      {options.map((item, index) => (
                        <tr>
                          <td>
                            <Form.Group className="mb-3">
                              <Form.Control
                                type="text"
                                value={item.title}
                                onChange={(e) => changeOptionTitle(e.target.value, index)}
                                placeholder="Enter Title..."
                              />
                            </Form.Group>
                          </td>
                          {item.translations.map((l, i) => (
                            <td>
                              <Form.Group className="mb-3">
                                <Form.Control
                                  type="text"
                                  value={l.title}
                                  onChange={(e) => changeOtherOptionTitle(e.target.value, index, i)}
                                  placeholder={`Enter Title (${l.lang.title})...`}
                                />
                              </Form.Group>
                            </td>
                          ))}
                          <td>
                            <a className="btn btn-sm btn-success" onClick={addRow}>+</a>
                            &nbsp;
                            {index !== 0 ? (
                              <a className="btn btn-sm btn-danger" onClick={() => removeRow(index)}>-</a>
                            ) : ('')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : ('')}

              <br />
              <Button
                onClick={handleSubmit}
                size="lg"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CategoryFilterCreate;
