import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const getLanguages = async (navigate, page) => {
    try {
        const result = await http.axios.get(UrlServices.getLanguagesApi() + "?page=" + page, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const languageStore = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getLanguagesApi() + "/store", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/languages");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const languageEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getLanguagesApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const languageUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getLanguagesApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/languages");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const languageDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getLanguagesApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/languages");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}