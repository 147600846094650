import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const appUsers = async (navigate, page,search) => {
    try {
        var result = null;
        if (search != '') {
            result = await http.axios.get(UrlServices.getAppUsersApi() + '/get/' + search + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else {
            result = await http.axios.get(UrlServices.getAppUsersApi() + "/get?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const appUserCreate = async (navigate) => {
    try {
        const result = await http.axios.get(UrlServices.getAppUsersApi() + "/create", {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const appUserEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getAppUsersApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const appUserUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAppUsersApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/appUsers");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const appUserDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getAppUsersApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/appUsers");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const appUserChangePlan = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAppUsersApi() + "/changePlan", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/appUsers");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const appUserBlock = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAppUsersApi() + "/block", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/appUsers");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const appUserNotification = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAppUsersApi() + "/notification", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/appUsers");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const appUserNotificationAll = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAppUsersApi() + "/notificationAll", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}