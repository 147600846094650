import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { eventEditAction, eventDeleteAction } from '../../actions/eventActions'
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { format } from "date-fns";
import http from '../../api'

const EventDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [link, setLink] = useState("");
  const [expiredAt, setExpiredAt] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [banner, setBanner] = useState(null);
  const [error, setError] = useState(null);
  const eventEdit = useSelector((state) => state.eventEdit);
  const eventDelete = useSelector((state) => state.eventDelete);

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = () => {
    context.loading = true;
    setError(null);
    dispatch(eventEditAction(navigate, id));
  };


  useEffect(() => {
    if (eventEdit.result) {
      context.loading = false;
      const event = eventEdit.result;
      setBanner(event.banner_path);
      setLink(event.link);
      setExpiredAt(new Date(event.expired_at));
      setIsActive(event.is_active);
    }
  }, [eventEdit])

  const deleteEvent = () => {
    context.loading = true;
    setError(null);
    dispatch(eventDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (eventDelete.result) {
      context.loading = false;
      if (eventDelete.result.status !== 200)
        setError(eventDelete.result.data.message);
    }
  }, [eventDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    deleteEvent();
  };

  const handleChangeIsActive = () => {
    setIsActive(!isActive);
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Event </h4>
            &nbsp;
            <Link className="btn btn-info" to="/events">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              {banner ? (
                <>
                  <td> Last Banner:</td>
                  <Image style={{ width: 350 }} src={http.assetUrl + '/' + banner} />
                </>
              ) : ('')}

              <br />
              <br />
              <br />
              <Form.Group className="mb-3">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  placeholder="Enter Link..."
                />
              </Form.Group>

              <Form.Label>Expired At</Form.Label>
              <DateTimePicker
                className='form-control'
                minDate={new Date()}
                maxDetail="second"
                disabled
                format="y-MM-dd hh:mm:ss a"
                onChange={setExpiredAt}
                value={expiredAt}
              />

              <Form.Group className="m-3">
                <Form.Check
                  style={{ border: 'none' }}
                  className="form-control"
                  type="checkbox"
                  disabled
                  onClick={handleChangeIsActive}
                  checked={isActive}
                  label="Is Active"
                />
              </Form.Group>

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default EventDelete;
