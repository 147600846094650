import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { appUserNotificationAllAction } from '../../actions/appUserActions'

const NotificationAll = () => {
    const context = useContext(SimpleContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const appUserNotificationAll = useSelector((state) => state.appUserNotificationAll);

    useEffect(() => {
        setTitle('');
        setText('');
    }, [])

    const sendNotification = () => {
        context.loading = true;
        setError(null);
        const data = new FormData();
        data.append('title', title);
        data.append('text', text);
        dispatch(appUserNotificationAllAction(navigate, data));
    };

    useEffect(() => {
        if (appUserNotificationAll.result) {
            context.loading = false;
            if (appUserNotificationAll.result.message !== 'ok')
                setError(appUserNotificationAll.result.message);
            else {
                setTitle('');
                setText('');
                setResult('Notification sent Successfully!');
            }
        }
    }, [appUserNotificationAll])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (title === '') setError("Title is required!");
        else if (text === '') setError("Text is required!");
        else
            sendNotification();
    };

    return (
        <Layout>
            <div>
                <Card>
                    <div className="input-group p-3">
                        <h4>Send Notification To All Users</h4> &nbsp;
                    </div>
                    {error ? (
                        <div onClick={() => setError(null)}>
                            <div className="alert alert-danger m-3">{error}</div>
                        </div>
                    ) : (
                        ""
                    )}
                    {result ? (
                        <div onClick={() => setResult(null)}>
                            <div className="alert alert-success m-3">{result}</div>
                        </div>
                    ) : (
                        ""
                    )}
                    <Card.Body>
                        <Form>

                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter Title..."
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    placeholder="Enter Text..."
                                />
                            </Form.Group>

                            <Button
                                onClick={handleSubmit}
                                size="lg"
                                variant="success"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    );
};

export default NotificationAll;
