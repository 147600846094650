import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { vipAdsAction } from '../../actions/vipAdActions';
import { Pagination } from "@mui/material";

const VipAds = () => {
  const context = useContext(SimpleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const ads = useSelector((state) => state.vipAds);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(vipAdsAction(navigate, page));
  };

  useEffect(() => {
    if (ads.result) {
      context.loading = false;
      setPageCount(ads.result["last_page"]);
      if (ads.result["data"]) setData(ads.result["data"]);
    }
  }, [ads])


  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Layout>
      <div>
        <Card>
          <Row className="p-3">
            <Col md={12}>
              <div className="input-group">
                <h4>Vip Ads</h4> &nbsp;
              </div>
            </Col>
          </Row>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table className="text-center" striped bordered hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Full Name</th>
                  <th>User Name</th>
                  <th>Is Active</th>
                  <th>Expired At</th>
                  <th>Ad Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td>{item.ad ? item.ad.title : ''}</td>
                    <td>{item.ad ? item.ad.description : ''}</td>
                    <td>{item.ad ? item.ad.fullName : ''}</td>
                    <td>{item.ad ? item.ad.userName : ''}</td>
                    {item.is_active ? (
                      <td className="text-success">Active</td>
                    ) : (
                      <td className="text-danger">Not Active</td>
                    )}
                    <td>{item.expired_at}</td>
                    {item.ad && item.ad.status === 'accept' ? (
                      <td className="text-success">Accept</td>
                    ) : ('')}
                    {item.ad && item.ad.status === 'reject' ? (
                      <td className="text-danger">Reject</td>
                    ) : ('')}
                    {item.ad && item.ad.status === 'waiting' ? (
                      <td className="text-warning">Waiting</td>
                    ) : ('')}
                    {item.ad && item.ad.status === 'remove' ? (
                      <td className="text-info">Remove</td>
                    ) : ('')}
                    <td>
                      {(PermissionService.hasPermission('vipAd-edit', context.permissions)) ? (
                        <Link
                          className="btn btn-warning"
                          to={`/vipAd-edit/${item.id}`}
                        >
                          Edit
                        </Link>
                      ) : ('')}
                      &nbsp;
                      {(PermissionService.hasPermission('vipAd-delete', context.permissions)) ? (
                        <Link
                          className="btn btn-danger"
                          to={`/vipAd-delete/${item.id}`}
                        >
                          Delete
                        </Link>
                      ) : ('')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination count={pageCount} page={page} onChange={handleChange} />
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default VipAds;
