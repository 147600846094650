import { PlanOptions, PlanOptionCreate, PlanOptionDelete, PlanOptionEdit, PlanOptionStore, PlanOptionUpdate } from "./actionTypes";
import { getPlanOptions, planOptionCreate, planOptionDelete, planOptionEdit, planOptionStore, planOptionUpdate } from '../api/planOptions';

export const planOptionsAction = (navigate, page, data) => {
    return async (dispatch) => {
        await dispatch({ type: PlanOptions, payload: null });
        getPlanOptions(navigate, page, data).then(async (result) => {
            await dispatch({ type: PlanOptions, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanOptions, payload: error });
        });
    };
};

export const planOptionCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: PlanOptionCreate, payload: null });
        planOptionCreate(navigate).then(async (result) => {
            await dispatch({ type: PlanOptionCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanOptionCreate, payload: error });
        });
    };
};

export const planOptionStoreAction = (navigate, data, pId) => {
    return async (dispatch) => {
        await dispatch({ type: PlanOptionStore, payload: null });
        planOptionStore(navigate, data, pId).then(async (result) => {
            await dispatch({ type: PlanOptionStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanOptionStore, payload: error });
        });
    };
};

export const planOptionEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: PlanOptionEdit, payload: null });
        planOptionEdit(navigate, id).then(async (result) => {
            await dispatch({ type: PlanOptionEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanOptionEdit, payload: error });
        });
    };
};

export const planOptionUpdateAction = (navigate, data, pId) => {
    return async (dispatch) => {
        await dispatch({ type: PlanOptionUpdate, payload: null });
        planOptionUpdate(navigate, data, pId).then(async (result) => {
            await dispatch({ type: PlanOptionUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanOptionUpdate, payload: error });
        });
    };
};

export const planOptionDeleteAction = (navigate, id, pId) => {
    return async (dispatch) => {
        await dispatch({ type: PlanOptionDelete, payload: null });
        planOptionDelete(navigate, id, pId).then(async (result) => {
            await dispatch({ type: PlanOptionDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: PlanOptionDelete, payload: error });
        });
    };
};