import { Dashboard, DashboardGetByDate } from "./actionTypes";
import { dashboards, dashboardByDate } from '../api/dashboards';

export const dashboardsAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: Dashboard, payload: null });
        dashboards(navigate).then(async (result) => {
            await dispatch({ type: Dashboard, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Dashboard, payload: error });
        });
    };
};

export const dashboardByDateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: DashboardGetByDate, payload: null });
        dashboardByDate(navigate, data).then(async (result) => {
            await dispatch({ type: DashboardGetByDate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: DashboardGetByDate, payload: error });
        });
    };
};