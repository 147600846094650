import React, { useContext, useEffect, useState } from "react";
import Layout from "../Shared/Layout";
import SimpleContext from "../../Contexts/SimpleContext";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import PermissionService from "../../Services/PermissionService";
import { useDispatch, useSelector } from "react-redux";
import { categoryFiltersAction } from '../../actions/categoryFilterActions';
import '../../Style/tree.css';

const CategoryFilters = () => {
  const context = useContext(SimpleContext);
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [category, setCategory] = useState('');
  const [langs, setLangs] = useState([]);
  const [collapse, setCollapse] = useState([]);
  const [trShow, setTrShow] = useState([]);
  const [error, setError] = useState(null);
  const categoryFilters = useSelector((state) => state.categoryFilters);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    context.loading = true;
    setError(null);
    dispatch(categoryFiltersAction(navigate, categoryId));
  };

  useEffect(() => {
    if (categoryFilters.result) {
      setCategory(categoryFilters.result.category);
      setData(categoryFilters.result.filters);
      setLangs(categoryFilters.result.langs);
      categoryFilters.result.filters.forEach(element => {
        setCollapse(oldArray => [...oldArray, false]);
        setTrShow(oldArray => [...oldArray, false]);
      });
      context.loading = false;
    }
  }, [categoryFilters])

  const toggle = (index) => {
    let cItems = [...collapse];
    let cItem = { ...cItems[index] };
    cItem = !cItems[index];
    cItems[index] = cItem;
    setCollapse(cItems)

    let tItems = [...trShow];
    let tItem = { ...tItems[index] };
    tItem = !tItems[index];
    tItems[index] = tItem;
    setTrShow(tItems);
  }

  return (
    <Layout>
      <div>
        <Card>
          <Row className="p-3">
            <Col md={8}>
              <div className="input-group">
                <h4>Filters of </h4> &nbsp;&nbsp;&nbsp; <h4 className="text-warning">{category ? category.title : ''}</h4>
                &nbsp;&nbsp;&nbsp;
                {(PermissionService.hasPermission('categoryFilter-create', context.permissions)) ? (
                  <Link className="btn btn-info" to={`/categoryFilter-create/${categoryId}`}>
                    New Filter
                  </Link>
                ) : ('')}
                &nbsp;&nbsp;&nbsp;
                <Link className="btn btn-primary" to="/categories">
                  Back to Categories
                </Link>
              </div>
            </Col>
            <Col md={4}>
            </Col>
          </Row>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th />
                  <th>Title</th>
                  {langs.map((item) => (
                    <th key={item.id}>{`Title (${item.title})`}</th>
                  ))}
                  <th>Type</th>
                  <th>Header (Group)</th>
                  {langs.map((item) => (
                    <th key={item.id}>{`Header (Group) (${item.title})`}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <>

                    <tr key={item.id}>

                      {item.type.title === 'select' ? (<th scope="row" onClick={() => toggle(index)}>
                        <i className={trShow[index] ? "arrow down" : "arrow right"} />
                      </th>) : (<th />)}

                      <td>{item.title}</td>
                      {item.translations.map((t, i) => (
                        <td key={i} > {t.title}</td>
                      ))}
                      <td>{item.type.title}</td>
                      <td>{item.header ? item.header.title : ''}</td>

                      {item.header ?
                        (item.header.translations.map((t, i) => (
                          <td key={i}>{t.title}</td>
                        ))) : (<td></td>)
                      }

                      <td>
                        {(PermissionService.hasPermission('categoryFilter-edit', context.permissions)) ? (
                          <Link
                            className="btn btn-warning"
                            to={`/categoryFilter-edit/${item.id}/${categoryId}`}
                          >
                            Edit
                          </Link>
                        ) : ('')}
                        &nbsp;
                        {(PermissionService.hasPermission('categoryFilter-delete', context.permissions)) ? (
                          <Link
                            className="btn btn-danger"
                            to={`/categoryFilter-delete/${item.id}/${categoryId}`}
                          >
                            Delete
                          </Link>
                        ) : ('')}
                      </td>
                    </tr>

                    {item.type.title === 'select' && trShow[index] && (
                      <React.Fragment>
                        <tr>
                          <th />
                          <th style={{ paddingLeft: 20 }}>Option</th>
                          {langs.map((item) => (
                            <th style={{ paddingLeft: 20 }} key={item.id}>{`Option (${item.title})`}</th>
                          ))}
                        </tr>
                        {item.options.map((i) => (
                          <tr>
                            <th style={{ paddingLeft: 20 }} />
                            <td style={{ paddingLeft: 20 }}>{i.title}</td>
                            {i.translations.map((t, j) => (
                              <td style={{ paddingLeft: 20 }} key={j} > {t.title}</td>
                            ))}
                          </tr>
                        ))}
                      </React.Fragment>
                    )}

                  </>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CategoryFilters;
