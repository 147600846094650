import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const reportedUsers = async (navigate, page) => {
    try {
        var result = await http.axios.get(UrlServices.getReportedUsersApi() + "?page=" + page, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const reportedUserDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getReportedUsersApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const reportedAds = async (navigate, page) => {
    try {
        var result = await http.axios.get(UrlServices.getReportedAdsApi() + "?page=" + page, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const reportedAdDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getReportedAdsApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const reportedPosts = async (navigate, page) => {
    try {
        var result = await http.axios.get(UrlServices.getReportedPostsApi() + "?page=" + page, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const reportedPostDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getReportedPostsApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}