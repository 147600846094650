import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { categoryEditAction, categoryDeleteAction } from '../../actions/categoryActions'

const CategoryDelete = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [langs, setLangs] = useState([]);
  const [title, setTitle] = useState("");
  const [otherTitle, setOtherTitle] = useState([]);
  const [error, setError] = useState(null);
  const categoryEdit = useSelector((state) => state.categoryEdit);
  const categoryDelete = useSelector((state) => state.categoryDelete);

  useEffect(() => {
    getLangs();
  }, []);

  const getLangs = () => {
    context.loading = true;
    setError(null);
    dispatch(categoryEditAction(navigate, id));
  };


  useEffect(() => {
    if (categoryEdit.result) {
      context.loading = false;
      setLangs(categoryEdit.result.langs);
      const category = categoryEdit.result.category;
      setTitle(category.title);

      langs.map((item) => {
        const l = category['translations[' + item.code + ']'];
        if (l.title) {
          const ot = { code: item.code, value: l.title }
          var lastOtherTitle = otherTitle.find((i) => i.code === item.code);
          if (!lastOtherTitle)
            setOtherTitle((prev) => [...prev, ot]);
        }
      })
    }
  }, [categoryEdit])

  const deleteCategory = () => {
    context.loading = true;
    setError(null);
    dispatch(categoryDeleteAction(navigate, id));
  };

  useEffect(() => {
    if (categoryDelete.result) {
      context.loading = false;
      if (categoryDelete.result.status !== 200)
        setError(categoryDelete.result.data.message);
    }
  }, [categoryDelete])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "") setError("Enter Title in English is required!");
    else {
      deleteCategory();
    }
  };

  const changeOtherTitle = (code, value) => {
    var lastOtherTitle = otherTitle.find((i) => i.code === code);
    if (lastOtherTitle) {
      let copyOtherTitle = otherTitle.filter((i) => i.code !== code);
      if (value)
        copyOtherTitle.push({ code: code, value: value });
      setOtherTitle(copyOtherTitle);
    }
    else {
      setOtherTitle((prev) => ([...prev, { code: code, value: value }]));
    }
  }

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Delete Category: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{title}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/categories">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>

              <Form.Group className="mb-3">
                <Form.Label>Title in English</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title in English..."
                />
              </Form.Group>

              {langs.map((item, index) => (
                <Form.Group className="mb-3" key={item.id}>
                  <Form.Label>Title ({item.title})</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={otherTitle.find((i) => i.code === item.code) ?
                      otherTitle.find((i) => i.code === item.code)['value'] : ''
                    }
                    onChange={(e) => changeOtherTitle(item.code, e.target.value)}
                    placeholder={`Enter Title in ${item.title} ...`}
                  />
                </Form.Group>
              ))}

              <Button
                onClick={handleSubmit}
                size="lg"
                variant="danger"
                type="submit"
              >
                Delete
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default CategoryDelete;
