import { CategoryCreate, CategoryDelete, CategoryEdit, Categories, CategoryStore, CategoryUpdate } from "./actionTypes";
import { categoryCreate, categoryDelete, categoryEdit, categories, categoryStore, categoryUpdate } from '../api/categories';

export const categoriesAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: Categories, payload: null });
        categories(navigate).then(async (result) => {
            await dispatch({ type: Categories, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: Categories, payload: error });
        });
    };
};

export const categoryCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryCreate, payload: null });
        categoryCreate(navigate).then(async (result) => {
            await dispatch({ type: CategoryCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryCreate, payload: error });
        });
    };
};

export const categoryStoreAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryStore, payload: null });
        categoryStore(navigate, data).then(async (result) => {
            await dispatch({ type: CategoryStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryStore, payload: error });
        });
    };
};

export const categoryEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryEdit, payload: null });
        categoryEdit(navigate, id).then(async (result) => {
            await dispatch({ type: CategoryEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryEdit, payload: error });
        });
    };
};

export const categoryUpdateAction = (navigate, data) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryUpdate, payload: null });
        categoryUpdate(navigate, data).then(async (result) => {
            await dispatch({ type: CategoryUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryUpdate, payload: error });
        });
    };
};

export const categoryDeleteAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryDelete, payload: null });
        categoryDelete(navigate, id).then(async (result) => {
            await dispatch({ type: CategoryDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryDelete, payload: error });
        });
    };
};