import http from '../api'
import AuthHeader from "../Services/AuthHeader";
import UrlServices from "../Services/UrlServices";
import { checkToken } from './checkToken'

export const ads = async (navigate, page, search, status) => {
    try {
        var result = null;
        if (search && !status) {
            result = await http.axios.get(UrlServices.getAdsApi() + '/filter/' + search + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else if (!search && status) {
            result = await http.axios.get(UrlServices.getAdsApi() + '/filter/null/' + status + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else if (search && status) {
            result = await http.axios.get(UrlServices.getAdsApi() + '/filter/' + search + '/' + status + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        else {
            result = await http.axios.get(UrlServices.getAdsApi() + "?page=" + page, {
                headers: AuthHeader.getHeader(),
            });
        }
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const adDetail = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getAdsApi() + "/detail/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        console.log(error);
    }
}

export const adDeleteMedia = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAdsApi() + "/deleteMedia", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data;
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const adEdit = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getAdsApi() + "/edit/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            return result.data
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const adUpdate = async (navigate, data) => {
    try {
        const result = await http.axios.post(UrlServices.getAdsApi() + "/update", data, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/ads");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}

export const adDelete = async (navigate, id) => {
    try {
        const result = await http.axios.get(UrlServices.getAdsApi() + "/destroy/" + id, {
            headers: AuthHeader.getHeader(),
        });
        if (checkToken(result, navigate))
            navigate("/ads");
    } catch (error) {
        if (error.response)
            return error.response;
    }
}