import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import '../Style/Login/login.css';
import '../Style/Login/login.js';

import AuthService from "../Services/AuthService";
import { useNavigate } from "react-router-dom";
import SimpleContext from "../Contexts/SimpleContext";


function Login() {
    let navigate = useNavigate();
    const context = useContext(SimpleContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            handleLoginAccepted();
        }, [context.status]);



    function handleLoginAccepted() {
        if (context.status)
            navigate("/");
    }

    const handleLogin = (e) => {
        setLoading(true);
        e.preventDefault();
        if (email.trim() === "" || password.trim() === "") {
            setError('Email and Password must input!')
            setLoading(false);
        }
        else {
            AuthService.login(email, password).then(
                (response) => {
                    if (response == null) {
                        setError('Authentication failed!');
                        setLoading(false);
                    }
                    else {
                        setLoading(false);
                        if (response['error'])
                            setError(response['error'])
                        else {
                            context.status = true;
                            handleLoginAccepted();
                        }
                    }
                });
        }
    };

    return (
        <div className="login-body">
            <div className="login-box">
                <img className="login-img" style={{ display: "none" }} src="./logo.png" alt="Can-Do" width="100" />
                <h4 className="login-h3" style={{ fontFamily: 'IranSans' }}>Can-Do Admin Panel</h4>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control email login-form-control" id="username" placeholder="Email..."></input>
                    </div>
                    <br />
                    <div className="form-group">
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control password login-form-control" id="password" placeholder="Password..."></input>
                    </div>
                    <br />
                    {error ? (
                        <div className="alert alert-danger" onClick={() => setError(null)} >{error}</div>
                    ) : null}
                    <button type="submit" className="btn btn-lg btn-primary login-btn" disabled={loading}>
                        {loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginLeft: "5px" }}
                            />
                        )}
                        {loading && <span>Please Wait</span>}
                        {!loading && <span>Log In</span>}
                    </button>

                </form>
            </div>
        </div>
    );
}

export default Login;