import { CategoryFilterCreate, CategoryFilterDelete, CategoryFilterEdit, CategoryFilters, CategoryFilterStore, CategoryFilterUpdate } from "./actionTypes";
import { categoryFilterCreate, categoryFilterDelete, categoryFilterEdit, categoryFilters, categoryFilterStore, categoryFilterUpdate } from '../api/categoryFilters';

export const categoryFiltersAction = (navigate, categoryId) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryFilters, payload: null });
        categoryFilters(navigate, categoryId).then(async (result) => {
            await dispatch({ type: CategoryFilters, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryFilters, payload: error });
        });
    };
};

export const categoryFilterCreateAction = (navigate) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryFilterCreate, payload: null });
        categoryFilterCreate(navigate).then(async (result) => {
            await dispatch({ type: CategoryFilterCreate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryFilterCreate, payload: error });
        });
    };
};

export const categoryFilterStoreAction = (navigate, data, cId) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryFilterStore, payload: null });
        categoryFilterStore(navigate, data, cId).then(async (result) => {
            await dispatch({ type: CategoryFilterStore, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryFilterStore, payload: error });
        });
    };
};

export const categoryFilterEditAction = (navigate, id) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryFilterEdit, payload: null });
        categoryFilterEdit(navigate, id).then(async (result) => {
            await dispatch({ type: CategoryFilterEdit, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryFilterEdit, payload: error });
        });
    };
};

export const categoryFilterUpdateAction = (navigate, data, cId) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryFilterUpdate, payload: null });
        categoryFilterUpdate(navigate, data, cId).then(async (result) => {
            await dispatch({ type: CategoryFilterUpdate, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryFilterUpdate, payload: error });
        });
    };
};

export const categoryFilterDeleteAction = (navigate, id, cId) => {
    return async (dispatch) => {
        await dispatch({ type: CategoryFilterDelete, payload: null });
        categoryFilterDelete(navigate, id, cId).then(async (result) => {
            await dispatch({ type: CategoryFilterDelete, payload: result });
        }).catch(async (error) => {
            await dispatch({ type: CategoryFilterDelete, payload: error });
        });
    };
};