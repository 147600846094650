import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleContext from "../../Contexts/SimpleContext";
import Layout from "../Shared/Layout";
import { roleEditAction, roleUpdateAction } from '../../actions/roleActions'

const RoleEdit = () => {
  const context = useContext(SimpleContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [permissions, setPermissions] = useState([]);
  const [selectPermissions, setSelectPermissions] = useState([]);
  const [defCheck, setDefCheck] = useState([]);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const roleEdit = useSelector((state) => state.roleEdit);
  const roleUpdate = useSelector((state) => state.roleUpdate);

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    context.loading = true;
    setError(null);
    dispatch(roleEditAction(navigate, id));
  };

  useEffect(() => {
    if (roleEdit.result) {
      context.loading = false;
      var rp = [];
      roleEdit.result["rolePermissions"].map((item) => {
        rp.push(item["permission_id"].toString());
      });
      setSelectPermissions(rp);
      setPermissions(roleEdit.result["permission"]);
      setName(roleEdit.result["role"]["name"]);
    }
  }, [roleEdit])

  useEffect(() => {
    var crp = [];
    permissions.map((item) => {
      if (selectPermissions.includes(item.id.toString())) crp.push(true);
      else crp.push(false);
    });
    setDefCheck(crp);
  }, [selectPermissions])

  const handleChangePermissions = (e) => {
    const arr = selectPermissions;
    const newId = e.target.value;

    if (!arr.includes(newId)) {
      arr.push(newId);
    } else {
      arr.splice(arr.indexOf(newId), 1);
    }
    setSelectPermissions(arr);
  };

  const changeRole = () => {
    context.loading = true;
    setError(null);
    const data = {
      id: id,
      name: name,
      permission: selectPermissions,
    };
    dispatch(roleUpdateAction(navigate, data));
  };

  useEffect(() => {
    if (roleUpdate.result) {
      context.loading = false;
      if (roleUpdate.result.status !== 200)
        setError(roleUpdate.result.data.message);
    }
  }, [roleUpdate])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === "") setError("Role Name is required!");
    else {
      changeRole();
    }
  };

  return (
    <Layout>
      <div>
        <Card>
          <div className="input-group p-3">
            <h4>Edit Role: </h4>
            &nbsp;
            &nbsp;
            <h4 className="text-warning">{name}</h4>
            &nbsp;
            <Link className="btn btn-info" to="/roles">
              Back
            </Link>
          </div>
          {error ? (
            <div onClick={() => setError(null)}>
              <div className="alert alert-danger m-3">{error}</div>
            </div>
          ) : (
            ""
          )}
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Role Name..."
                />
              </Form.Group>

              <div className="" style={{ textAlignLast: "left" }}>
                <Form.Group className="mb-3">
                  {permissions.map((item, i) => (
                    <Form.Check
                      type="checkbox"
                      onChange={handleChangePermissions}
                      value={item.id}
                      label={item.name}
                      defaultChecked={defCheck[i]}
                    />
                  ))}
                </Form.Group>
              </div>
              <Button
                onClick={handleSubmit}
                size="lg"
                variant="warning"
                type="submit"
              >
                Update
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  );
};

export default RoleEdit;
